import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  cardContainer: {
    maxWidth: "270px",
    width: "100%",
    height: "351px",
    borderRadius: 16,
    border: "1px solid #E9EAEB",
    backgroundColor: '#F5F5F5',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    overflow: "hidden",
    cursor: "pointer",
    userSelect: 'none'
  },
  cardTop: {
    padding: '12px 12px 0',
    height: '225px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  cardPreview: {
    height: '186px',
    margin: '0 auto',
    position: 'relative',
    borderRadius: '12px 12px 0 0',
  },
  cardBottom: {
    position: 'relative',
    height: "126px",
    padding: '16px 12px',
    backgroundColor: '#FFF',
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardBottomTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardBottomTopGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cartTitle: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#262C37',
  },
  cartCount: {
    fontSize: '14px',
    color: '#6E737D',
  },
  cartDescription: {
    fontSize: '14px',
    color: '#50555E',
  },
  cardComingSoon: {
    width: '113px',
    height: '24px',
    backgroundColor: '#F5F5F5',
    padding: '6px 8px',
    borderRadius: '8px',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#50555E'
  },
  cartDate: {
    fontSize: '12px',
    color: '#6E737D',
  }
}));