import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentSubscription, getSubscriptions } from '../redux/services/subscriptions';
import { notify } from "../providers/notification";
import { AccountSubscriptionResponse, SubscriptionTypeResponse } from '../interfaces/subscriptions';
import { StorageService } from '../providers/storage';

const useGetSubscription = (forceFetch = false): { subscription: (AccountSubscriptionResponse & { metadata: SubscriptionTypeResponse }), refetch: (forceFetch?: boolean) => void } => {
  const [subscription, setSubscription] = useState<(AccountSubscriptionResponse & { metadata: SubscriptionTypeResponse }) | null>(null);

  const history = useHistory();

  useEffect(() => {
    handleGetSubscription(forceFetch);
  }, []);

  const handleGetSubscription = async (forceFetch = false) => {
    const token = await StorageService.getItem("user-token");

    if (token) {
      const { data: subscriptions } = await getSubscriptions();
      const { data, error } = await getCurrentSubscription(undefined, forceFetch);
  
      if (data && !error) {
        const targetSubscription = subscriptions?.find(item => item.id === data.subscriptionId);
  
        setSubscription({
          ...data,
          metadata: targetSubscription
        });
      } else {
        setSubscription(null);
      }
    } else {
      setSubscription(null);
    }
  };

  return { subscription, refetch: handleGetSubscription };
};

export default useGetSubscription;
