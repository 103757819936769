import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100vh",
    background: "#FAFAFA",
    overflowY: "auto",
  },
  container: {
    width: "100%",
    minHeight: 'calc(100vh + 10px)',
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: "40px 30px",
    background: "#FAFAFA",
    gap: 24,
    maxWidth: "1200px",
    minWidth: '1200px',
    margin: "0 auto"
  },
  breadcrumbs: {
    display: "flex",
    alignItems: "center",
    height: "24px"
  },
  breadcrumbsRoot: {
    fontSize: "14px",
    color: "#262C37",
    cursor: "pointer"
  },
  breadcrumbsPage: {
    fontSize: "14px",
    color: "#6E737D",
    textTransform: "capitalize"
  },
  header: {
    width: "100%",
    minWidth: "880px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    textTransform: "capitalize"
  },
  labels: {
    display: "flex",
    userSelect: 'none'
  },
  labelsElement: {
    height: "24px",
    padding: "4px 24px 4px 4px",
    display: "flex",
    alignItems: "center"
  },
  labelsElementCircle: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    marginRight: "4px"
  },
  labelsElementName: {
    fontSize: "14px",
    lineHeight: "14px",
    color: "#50555E"
  },
  filterWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "16px"
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  filtersList: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px"
  },
  filtersSearch: {
    width: "100%",
    maxWidth: "416px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px"
  },
  filtersLeftBlock: {
    width: "48%",
    display: "flex",
    alignItems: "center"
  },
  resetButton: {
    width: "96px",
    height: "44px",
    "&:hover": {
      background: "#F5F5F5"
    }
  },

  subtitle: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14px",
    color: "#262C37",
    textTransform: "uppercase",
    marginBottom: "16px"
  },
  folders: {},
  recentFiles: {
    // marginTop: '24px',
  },
  foldersContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "16px"
  }
}));
