import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  Divider,
  Box, Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow_down_v2.svg';
import CheckBoxIcon from '../../../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';

import { useStyles } from './styles';
import Status from "../Status";

interface Props {
  value: any[],
  onChange: (value: any) => void;
}

const StatusDropdown: React.FC<Props> = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (status) => {
    const newValue = value.map((s) =>
      s.value === status.value ? { ...s, checked: !s.checked } : s
    );
    onChange(newValue);
  };

  const handleSelectAll = () => {
    const newValue = value.map((s) => {
      return {
        ...s,
        checked: true,
      }
    })
    onChange(newValue);
  };

  const clearSelection = () => {
    const newValue = value.map((s) => {
      return {
        ...s,
        checked: false,
      }
    })
    onChange(newValue);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDown width={17} height={17} />}
        style={{
          textTransform: "none",
          borderRadius: "8px",
          borderColor: "#E9EAEB",
          color: "#262C37",
          backgroundColor: "#fff",
          fontSize: 16,
        }}
      >
        Status
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: 'max-content',
            maxWidth: '340px',
            width: '100%',
            marginTop: '10px',
            borderRadius: '8px',
            border: '1px solid #E9EAEB',
            paddingTop: '8px',
          },
        }}
      >
        {value.map((status) => (
          <MenuItem
            key={status.value}
            onClick={() => handleToggle(status)}
            className={classes.menuItem}
          >
            <Checkbox
              icon={<img width={20} height={20} src={CheckBoxIcon} alt={'checkbox icon'} />}
              checkedIcon={<img width={20} height={20} src={CheckBoxCheckedIcon} alt={'checkbox checked icon'} />}
              checked={!!status.checked}
            />
            <Status value={status.value}></Status>
          </MenuItem>
        ))}

        <Divider style={{ marginTop: "6px" }} />
        <MenuItem
          onClick={value.filter(s => s.checked)?.length > 0 ? clearSelection : handleSelectAll}
          className={classes.menuSelectAll}
        >
          {
            value.filter(s => s.checked).length > 0
              ? <Box className={classes.clearSelection}>
                <Typography>Clear selection</Typography>
                <Typography>{value.filter(s => s.checked)?.length}</Typography>
              </Box>
              : <ListItemText primary={'Select all'} />
          }
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default StatusDropdown;
