import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";

import { Grid, Box } from "@material-ui/core";
import PrimaryQuestionController, {
  Props,
  configJSON,
} from "./PrimaryQuestionController";
import CustomButton from "../../../components/main/CustomButton.web";

export default class PrimaryQuestion extends PrimaryQuestionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      handleChange,
      inputList,
      serviceArray,
      title,
      btnDisplay = false,
      buttonName,
      tooltipData,
      requiredIndex
    } = this.props;

    return (
      <>
        <Grid container style={webStyle.servicesMainContainer}>
          <Grid item xs={12} style={webStyle.servicesContainer}>
            <CustomList
              modalContent={tooltipData || configJSON.serviceModal}
              servicesList={serviceArray}
              title={title}
            />
            <Box style={webStyle.offeringBox}>
              {inputList.map((data: any, index: any) => {
                return (
                  <Box key={index}>
                    <CustomInput
                      width="100%"
                      textLabel={data.label}
                      labelCount={index}
                      placeholder={data.placeholder}
                      rowsCount={1}
                      data-testid={"offering-field-" + index}
                      value={data.value}
                      error={data.error}
                      name={data.name}
                      onChange={(e: any) =>
                        handleChange && handleChange(e, index)
                      }
                      maxCharacterLenght={data.charLength}
                      maxWordsLength={data.wordsLength}
                      required={requiredIndex === index}
                    />
                  </Box>
                );
              })}
            </Box>
            {btnDisplay && (
              <CustomButton
                onClick={this.props.addItem}
                buttonName={buttonName}
              />
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  servicesMainContainer: {
    // background: '#EEE7D9',
    padding: "2% 0 0 0",
  },
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content",
    border: '1px solid #E9EAEB'
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    worGap: '16px'
  } as any,
};
