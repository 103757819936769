import React from 'react';
import { Box, Chip, FormHelperText, IconButton } from '@material-ui/core';
import { ReactComponent as InfoHintIcon } from '../../../../../../assets/icons/info_hint_mini.svg';
import { TemplateAttributeTypes } from '../../../../../../interfaces/campaign';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, newValidationSchema?: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setTooltipMessage: (message: string) => void;
}

const RenderMultiSelector: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
  setTooltipMessage,
}) => {
  const classes = useStyles();

  const handleSelect = (elem) => {
    const newValue = values[name] && Array.isArray(values[name]) ? [...values[name]] : [];

    if (newValue.includes(elem)) {
      // If the element is already in the array, remove it
      const filteredValue = newValue.filter(item => item !== elem);
      setFieldValue(name, filteredValue);
    } else {
      // If the element is not in the array, add it
      if (elem === 'Bullet points') {
        // Remove 'Tabular structure' if it exists
        const filteredValue = newValue.filter(item => item !== 'Tabular structure');
        setFieldValue(name, [...filteredValue, elem]);
      } else if (elem === 'Tabular structure') {
        // Remove 'Bullet points' if it exists
        const filteredValue = newValue.filter(item => item !== 'Bullet points');
        setFieldValue(name, [...filteredValue, elem]);
      } else {
        setFieldValue(name, [...newValue, elem]);
      }
    }
  }

  const handleShowTooltip = (event, elem) => {
    event.stopPropagation();

    const tooltipAttribute: any = Object.values(item.attributes).find((item: any) => item.type === TemplateAttributeTypes.ON_OPTION_TOOLTIP_ATTRIBUTE);
    const message = tooltipAttribute.optionTooltipMap[elem];

    setTooltipMessage(message);
  }

  return (
    <>
      <Box className={classes.multiSelectorWrapper}>
        {item.options.map((elem, index) => (
          <Chip
            // name={name}
            key={index}
            label={elem}
            variant="outlined"
            className={`${classes.chip} ${values[name] && values[name].includes(elem) ? classes.chipSelected : ''}`}
            data-test-id={elem}
            onClick={() => handleSelect(elem)}
            onDelete={(event) => handleShowTooltip(event, elem)}
            deleteIcon={
              <IconButton size='small'>
                <InfoHintIcon />
              </IconButton>
            }
          />
        ))}
      </Box>

      {touched[name] && errors[name] && (
        <FormHelperText error>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default RenderMultiSelector;
