import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import PaymentMethod from '../../../SettingsModal/components/PlanAndBilling/components/PaymentMethod';
import { formatCurrency, getCurrencySybmol } from '../../../../../helpers/curency';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  order: any;
  redirect?: boolean;
  setOrder?: (order: any) => void;
  onRenewSubscription?: () => void;
  setHideParent?: (hide: boolean) => void;
  onClose: (closeParentModal: boolean) => void;
}

const PaymentModal: React.FC<Props> = (props) => {
  const {
    open,
    order,
    setOrder,
    onRenewSubscription,
    redirect = true,
    onClose,
  } = props;

  const [hideParent, setHideParent] = useState<boolean>(false);

  const classes = useStyles();

  return (
    <>
      <Backdrop
        style={{
          color: '#fff', zIndex: 99999
        }}
        open={hideParent}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Modal
        open={open}
        onClose={() => onClose(false)}
        className='MuiDialog-root'
        style={{
          visibility: hideParent ? 'hidden' : 'visible'
        }}
      >
        <Box className={classes.modalBox}>
          <IconButton onClick={() => onClose(false)} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
          <Typography style={{ width: '100%', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'left' }}>
            {order?.name?.replace('@amount', `${getCurrencySybmol(order.currency)} ${formatCurrency(order.amount)}`)}
            {order?.amount !== 0 && (
              <Typography style={{ display: 'flex', alignItems: 'flex-end', gap: '7px', fontSize: '24px', fontWeight: '500', lineHeight: '24px', color: '#64748B', marginTop: '10px' }}>
                {getCurrencySybmol(order?.currency)} {formatCurrency(order?.amount)}
                <Typography style={{ fontSize: '0.75rem', fontWeight: '400', lineHeight: '1.55' }}>
                  (including VAT)
                </Typography>
              </Typography>
            )}
          </Typography>
          <PaymentMethod
            order={order}
            redirect={redirect}
            setOrder={setOrder}
            onHideParentModal={(hide) => {
              setHideParent(hide);

              if (typeof props.setHideParent === 'function') {
                props.setHideParent(hide);
              }
            }}
            onRenewSubscription={onRenewSubscription}
            onClose={onClose}
          />
        </Box>
      </Modal>
    </>
  );
};

export default PaymentModal;
