import { api, apiCall, createApiCall } from "../api";
import {
  Campaign,
  CampaignCommentRequest,
  CampaignCommentResponse, CampaignWithOwners, CampaignWithSingleUser,
  CommentActionRequest,
  CreateCampaignRequest, FolderMetadata, LibraryCampaignResponse, LibraryCopilotResponse, LibraryParams,
  RecentFiles,
  SimplifiedCampaignResponse
} from "../../interfaces/campaign";
import { ChatIntelligenceDto } from "../../interfaces/history";

const myLibraryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getLibraryCampaigns: builder.query<Campaign[], void>({
      query: () => ({
        url: `/v1/library/campaigns`,
        method: "GET"
      }),
    }),
    createLibraryCampaign: builder.mutation<void, { body: { name: string, description: string }, campaignId: string }>({
      query: ({ body, campaignId}) => ({
        url: `/v1/library/campaigns/${campaignId}`,
        method: "POST",
        body
      })
    }),
    duplicateLibraryCampaign: builder.mutation<Campaign | void, { campaignId: string, isStartUpTeams: boolean }>({
      query: ({ campaignId, isStartUpTeams }) => ({
        url: isStartUpTeams
          ? `/v1/library/campaign/history/${campaignId}/duplicate/multi-user`
          : `/v1/library/campaigns/${campaignId}/duplicate`,
        method: "PUT"
      })
    }),
    deleteLibraryCampaign: builder.mutation<void, string>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/${campaignId}`,
        method: "DELETE"
      })
    }),
    duplicateLibraryEmail: builder.mutation<void, { campaignId: string, emailId: string }>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}/duplicate`,
        method: "PUT",
      })
    }),
    deleteLibraryEmail: builder.mutation<void, { campaignId: string, emailId: string }>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}`,
        method: "DELETE"
      })
    }),
    getReviewLogCampaigns: builder.query<SimplifiedCampaignResponse, { type: string, reviewStatuses: string[] }>({
      query: ({ type, reviewStatuses }) => ({
        url: `/v1/library/campaigns/${type}`,
        method: "GET",
        params: reviewStatuses?.length
          ? { reviewStatuses }
          : null
      }),
    }),
    getApprovedCampaigns: builder.query<SimplifiedCampaignResponse, void>({
      query: () => ({
        url: `/v1/library/campaigns/multi-user`,
        method: "GET"
      }),
    }),
    getCampaignComments: builder.query<CampaignCommentResponse, string>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/comments?campaignId=${campaignId}`,
        method: "GET"
      })
    }),
    sendCampaignComment: builder.mutation<CampaignCommentResponse, CampaignCommentRequest>({
      query: (body) => ({
        url: `/v1/library/campaigns/comments`,
        method: "POST",
        body
      })
    }),
    replyForComment: builder.mutation<void, { body: { text: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/campaigns/comments/${commentId}/reply`,
        method: "POST",
        body
      })
    }),
    changeCommentStatus: builder.mutation<void, { body: { status: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/campaigns/comments/${commentId}`,
        method: "PUT",
        body
      })
    }),
    changeCampaignStatus: builder.mutation<void, { body: { status: string }, campaignId: string }>({
      query: ({ body, campaignId }) => ({
        url: `/v1/library/campaigns/${campaignId}/status`,
        method: "PUT",
        body
      })
    }),
    getLibraryFolders: builder.query<FolderMetadata[], void>({
      query: () => ({
        url: `/v1/library/folders`,
        method: "GET",
      })
    }),
    getRecentFiles: builder.query<RecentFiles[], void>({
      query: () => ({
        url: `/v1/library/intelligence/recent-files`,
        method: "GET",
      })
    }),
    getLibraryCampaignsByLicenseType: builder.query<LibraryCampaignResponse, { params: LibraryParams, license: string }>({
      query: ({params, license}, ) => ({
        url: `/v1/library/campaign/${license}`,
        method: "GET",
        params
      }),
    }),
    getLibraryCopilotByLicenseType: builder.query<LibraryCopilotResponse, { params: LibraryParams, license: string }>({
      query: ({params, license}, ) => ({
        url: `/v1/library/chat-intelligence/${license}`,
        method: "GET",
        params
      }),
    }),
    renameLibraryCampaignByLicenseType: builder.mutation<void, { body: any, id: string, license: string }>({
      query: ({body, id, license}, ) => ({
        url: `/v1/library/campaign/history/rename/${id}/${license}`,
        method: "PUT",
        body
      }),
    }),
    renameLibraryCopilotByLicenseType: builder.mutation<void, { body: ChatIntelligenceDto, id: string, license: string }>({
      query: ({body, id, license}, ) => ({
        url: `/v1/library/chat-intelligence/rename/${id}/${license}`,
        method: "PUT",
        body
      }),
    }),
    duplicateLibraryCampaignByLicenseType: builder.mutation<void, { id: string, license: string }>({
      query: ({id, license}, ) => ({
        url: license === 'multi-user' ? `/v1/library/campaign/history/${id}/duplicate/${license}` : `/v1/library/campaigns/${id}/duplicate`,
        method: "PUT",
      }),
    }),
    duplicateLibraryCopilotByLicenseType: builder.mutation<void, { id: string, license: string }>({
      query: ({id, license}, ) => ({
        url: `/v1/library/chat-intelligence/${id}/duplicate/${license}`,
        method: "PUT",
      }),
    }),
    deleteLibraryCampaignByLicenseType: builder.mutation<void, { id: string, license: string }>({
      query: ({id, license}, ) => ({
        url: license === 'multi-user' ? `/v1/library/campaign/history/${id}/${license}` : `/v1/library/campaigns/${id}`,
        method: "DELETE",
      }),
    }),
    deleteLibraryCopilotByLicenseType: builder.mutation<void, { id: string, license: string }>({
      query: ({id, license}, ) => ({
        url: `/v1/library/chat-intelligence/${id}/${license}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getLibraryCampaigns = createApiCall<Campaign[], void>(myLibraryAPI.endpoints.getLibraryCampaigns);
export const createLibraryCampaign = createApiCall<void, CreateCampaignRequest>(myLibraryAPI.endpoints.createLibraryCampaign);
export const duplicateLibraryCampaign = createApiCall<Campaign | void, { campaignId: string, isStartUpTeams: boolean }>(myLibraryAPI.endpoints.duplicateLibraryCampaign);
export const deleteLibraryCampaign = createApiCall<void, string>(myLibraryAPI.endpoints.deleteLibraryCampaign);
export const duplicateLibraryEmail = createApiCall<{ id: string, campaignId: string }, { campaignId: string, emailId: string }>(myLibraryAPI.endpoints.duplicateLibraryEmail);
export const deleteLibraryEmail = createApiCall<void, { campaignId: string, emailId: string }>(myLibraryAPI.endpoints.deleteLibraryEmail);
export const getReviewLogCampaigns = createApiCall<SimplifiedCampaignResponse[], { type: string, reviewStatuses: string[] }>(myLibraryAPI.endpoints.getReviewLogCampaigns);
export const getApprovedCampaigns = createApiCall<SimplifiedCampaignResponse, void>(myLibraryAPI.endpoints.getApprovedCampaigns);
export const getCampaignComments = createApiCall<CampaignCommentResponse, string>(myLibraryAPI.endpoints.getCampaignComments);
export const sendCampaignComment = createApiCall<CampaignCommentResponse, CampaignCommentRequest>(myLibraryAPI.endpoints.sendCampaignComment);
export const replyForComment = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.replyForComment);
export const changeCommentStatus = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.changeCommentStatus);
export const changeCampaignStatus = createApiCall<void, CommentActionRequest>(myLibraryAPI.endpoints.changeCampaignStatus);
export const getLibraryFolders = createApiCall<FolderMetadata[], void>(myLibraryAPI.endpoints.getLibraryFolders);
export const getRecentFiles = createApiCall<RecentFiles[], void>(myLibraryAPI.endpoints.getRecentFiles);
export const getLibraryCampaignsByLicenseType = createApiCall<LibraryCampaignResponse, { params: LibraryParams, license: string }>(myLibraryAPI.endpoints.getLibraryCampaignsByLicenseType);
export const getLibraryCopilotByLicenseType = createApiCall<LibraryCopilotResponse, { params: LibraryParams, license: string }>(myLibraryAPI.endpoints.getLibraryCopilotByLicenseType);
export const renameLibraryCampaignByLicenseType = createApiCall<void, { body: any, id: string, license: string }>(myLibraryAPI.endpoints.renameLibraryCampaignByLicenseType);
export const renameLibraryCopilotByLicenseType = createApiCall<void, { body: ChatIntelligenceDto, id: string, license: string }>(myLibraryAPI.endpoints.renameLibraryCopilotByLicenseType);
export const duplicateLibraryCampaignByLicenseType = createApiCall<void, { id: string, license: string }>(myLibraryAPI.endpoints.duplicateLibraryCampaignByLicenseType);
export const duplicateLibraryCopilotByLicenseType = createApiCall<void, { id: string, license: string }>(myLibraryAPI.endpoints.duplicateLibraryCopilotByLicenseType);
export const deleteLibraryCampaignByLicenseType = createApiCall<void, { id: string, license: string }>(myLibraryAPI.endpoints.deleteLibraryCampaignByLicenseType);
export const deleteLibraryCopilotByLicenseType = createApiCall<void, { id: string, license: string }>(myLibraryAPI.endpoints.deleteLibraryCopilotByLicenseType);