import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  statusContainer: {
    height: '28px',
    padding: '8px 12px',
    borderRadius: 8,
    fontSize: '16px',
    lineHeight: '15px',
    display: 'inline-flex',
  },
  menuContainer: {
    background: 'blue'
  },
  clearSelection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    height: '40px',
    padding: '10px 12px 10px 4px',
    margin: '0 8px 2px',
    transition: "background 0.2s ease-in-out",
    '&:hover': {
      backgroundColor: '#FAFAFA',
    }
  },
  menuItemActive: {
    backgroundColor: '#F4F7F7'
  },
  menuSelectAll: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold",
    padding: "0 18px",
    height: "44px",
  }
}));