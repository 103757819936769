import React from "react";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { Box, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./styles";

interface FilterItem {
    label: string;
    checked: boolean;
}

interface Props {
    title: string;
    value: string | FilterItem[];
    handleClose: () => void;
    isSingleValue: boolean;
}

const FilterChips: React.FC<Props> = ({ title, value, handleClose, isSingleValue }) => {
    const classes = useStyles();

    if (isSingleValue && typeof value !== "string") {
        console.error("FilterChips expects a string value when isSingleValue is true.");
        return null;
    }

    const getCheckedItems = (items: FilterItem[]) => items.filter((item) => item.checked);
    const checkedItems = !isSingleValue && Array.isArray(value) ? getCheckedItems(value) : [];

    const displayText = isSingleValue
        ? value
        : checkedItems.length === value.length
            ? "All"
            : checkedItems[0]?.label?.toLowerCase() || "None";

    return (
        <Box className={classes.chipsBox}>
            <Typography className={classes.chipsTitle}>{title}</Typography>
            <Typography className={classes.chipsValue}>{displayText}</Typography>
            <Typography className={classes.chipsValue}>
                {!isSingleValue && checkedItems.length > 1 && checkedItems.length !== value.length ? `+${checkedItems.length - 1}` : ""}
            </Typography>
            <IconButton className={classes.chipsClose} onClick={handleClose}>
                <CloseIcon width={10} height={10} />
            </IconButton>
        </Box>
    );
};

export default FilterChips;
