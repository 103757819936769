import { DateTime } from "luxon";

export const lastActivityDateFormat = (date) => {
  if (date) {
    const luxonDate = DateTime.fromISO(date, { zone: "utc" }).setZone();
    const today = DateTime.now().startOf("day");
    const yesterday = today.minus({ days: 1 });

    if (luxonDate.hasSame(today, "day")) {
      return `${luxonDate.toFormat("HH:mm")}, today`;
    } else if (luxonDate.hasSame(yesterday, "day")) {
      return `${luxonDate.toFormat("HH:mm")}, yesterday`;
    } else {
      const daysAgo = today.diff(luxonDate, "days").days;
      return `${luxonDate.toFormat("HH:mm")}, ${Math.trunc(daysAgo)} days ago`;
    }
  } else {
    return "never";
  }
};

export const isDateInPast = (date) => {
  const incomingDate = new Date(date);
  const now = new Date();

  // Compare the input date with the current date
  return incomingDate < now;
};
