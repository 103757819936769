import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";


import {
  Grid,
  Box,
  Typography
} from "@material-ui/core";

import SuccessStoriesController, {
  Props,
  configJSON,
} from "./SuccessStoriesController.web";

export default class SuccessStories extends SuccessStoriesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { handleChange, tooltipData, inputList, serviceArray, title } = this.props
    return (
      <>
        <Grid container style={webStyle.servicesMainContainer}>
          <Grid item xs={12} style={webStyle.servicesContainer}>
            <CustomList
              title={title}
              servicesList={serviceArray}
              modalContent={tooltipData || configJSON.serviceModal}
            />
            <Box style={webStyle.offeringBox}>
              {inputList.map((data: any, index: any) => {
                return (
                  <Box key={index}>
                    <CustomInput
                      width="100%"
                      textLabel=""
                      customeLabel={data.label}
                      labelCount={index}
                      placeholder={data.placeholder}
                      rowsCount={2}
                      data-testid={"field-" + index}
                      value={data.value}
                      error={data.error}
                      name={data.name}
                      onChange={(e: any) => handleChange && handleChange(e, index)}
                      maxCharacterLenght={data.charLength}
                    />
                  </Box>
                )
              }
              )}
            </Box>
            <Typography style={webStyle.subTitle}>{this.props.secondTitle}</Typography>
            <Box style={webStyle.solutionBox}>
              {this.props.secondList?.map((data: any, index: any) => {
                return (
                  <Box key={index} style={webStyle.rowWidth}>
                    <CustomInput
                      width="100%"
                      textLabel={data.label}
                      labelCount={index}
                      placeholder={data.placeholder}
                      rowsCount={5}
                      data-testid={"solution-field-" + index}
                      value={data.value}
                      error={data.error}
                      name={data.name}
                      onChange={(e: any) => this.props.onChangeTitle && this.props.onChangeTitle(e, index)}
                      maxCharacterLenght={data.charLength}
                    />
                  </Box>
                )
              }
              )}
            </Box>
            <Typography style={webStyle.subTitle}>{this.props.thirdTitle}</Typography>
            <Box style={webStyle.solutionBox}>
              {this.props.thirdList?.map((data: any, index: any) => {
                return (
                  <Box key={index} style={webStyle.rowWidth}>
                    <CustomInput
                      width="100%"
                      textLabel={data.label}
                      labelCount={index}
                      placeholder={data.placeholder}
                      rowsCount={5}
                      data-testid={"result-field-" + index}
                      value={data.value}
                      error={data.error}
                      name={data.name}
                      onChange={(e: any) => this.props.onChangeDescription && this.props.onChangeDescription(e, index)}
                      maxCharacterLenght={data.charLength}
                    />
                  </Box>
                )
              }
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  servicesMainContainer: {
    margin: '0.8rem 0px',
  },
  servicesContainer: {
    background: '#fff',
    borderRadius: '28px',
    padding: '32px 40px',
    height: 'max-content',
    border: '1px solid #E9EAEB'
  },
  offeringBox: {
    flexDirection: 'column' as 'column',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap',
    marginBottom: '2%'
  },
  solutionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap',
    marginBottom: '2%'
  },
  rowWidth: {
    width: '32%'
  },
  subTitle: {
    fontWeight: 600,
    marginTop: '18px',
    marginBottom: '16px'
  }
}
