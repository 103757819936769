import React from 'react';
import { FormControl, FormControlLabel, FormHelperText, Typography, Slider as SliderField } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  name?: string;
  value: number;
  min?: number;
  max?: number;
  defaultValue?: number;
  label?: string;
  step?: number;
  marks?: { value: number, label: string }[];
  valueLabelDisplay?: "auto" | "on" | "off";
  required?: boolean;
  error?: string;
  testId?: string;
  onChange: (event: any, value: any) => void;
}

const Slider: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <FormControl 
      error={!!props.error} 
      variant="standard"
      className={classes.wrapper}
    >
      {props.label && (
        <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#000000', marginBottom: '4px' }}>
          {props.label} &nbsp;
          {props.required && (
            <Typography style={{ color: 'red' }}>
              *
            </Typography>
          )}
        </Typography>
      )}
      <FormControlLabel
        control={
          <SliderField
            className={classes.slider}
            min={props.min || 0}
            max={props.max || 100}
            marks={props.marks || []}
            step={props.step || 1}
            defaultValue={props.defaultValue || 0}
            value={props.value}
            valueLabelDisplay={props.valueLabelDisplay || 'off'}
          />
        }
        data-test-id={props.testId}
        label={''}
        className={classes.controll}
        name={props.name}
        onChange={props.onChange}
        onDoubleClick={(event) => {
          const target = event.target as HTMLElement;

          if (target?.className?.includes('thumb')) {
            props.onChange(
              {
                event: { 
                  target: { 
                    value: props.defaultValue
                  } 
                } 
              },
              props.defaultValue
            )
          }
        }}
        {...props}
      />
      {props.error && (
        <FormHelperText error>
          {props.error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Slider;
