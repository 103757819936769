import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  multiSelectorWrapper: {
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "7px"
  },
  chip: {
    display: "flex",
    alignItems: "center",
    padding: "6px 12px",
    columnGap: "6px",
    cursor: "pointer",
    "& .MuiChip-deleteIcon": {
      margin: "0px"
    },
    "& .MuiChip-label": {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      color: "#000000"
    },
    "& svg": {
      width: "18px",
      height: "18px",
      margin: "0px !important"
    }
  },
  chipSelected: {
    background: "#000000",
    borderColor: "#000000",
    "& .MuiChip-label": {
      color: "white"
    },
    "& svg": {
      "& rect": {
        fill: "#22507B"
      },
      "& path": {
        fill: "#DADADA"
      }
    },
    "&:hover": {
      background: "#000000 !important",
      borderColor: "#000000 !important"
    },
    "&:focus": {
      background: "#000000 !important",
      borderColor: "#000000 !important"
    }
  }
}));
