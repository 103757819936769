import { useState, useEffect } from 'react';

interface Props {
  onMessageReceived?: (data: any, currentChannel: any) => void;
  onConnectionClosed?: (currentChannel: any) => void;
  onConnectionSet?: () => void;
  onError?: (status: any) => void;
  dependencies?: any[];
}

const usePusherChannel = ({
  onMessageReceived = () => {},
  onConnectionClosed = () => {},
  onConnectionSet = () => {},
  onError = () => {},
  dependencies = []
}: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [currentChannel, setCurrentChannel] = useState<any>(null);

  useEffect(() => {
    if (currentChannel) {
      currentChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      currentChannel.bind('pusher:subscription_error', handleSubscriptionError);
      currentChannel.bind('error_occurred', handleErrorOccurred);
      currentChannel.bind('new_message', handleNewMessage);
      currentChannel.bind('close_connection', handleCloseConnection);
    }
  
    return () => {
      if (currentChannel) {
        // currentChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        // currentChannel.unbind('pusher:subscription_error', handleSubscriptionError);
        // currentChannel.unbind('error_occurred', handleErrorOccurred);
        // currentChannel.unbind('new_message', handleNewMessage);
        // currentChannel.unbind('close_connection', handleCloseConnection);
        currentChannel.unbind_all();
        // currentChannel.unsubscribe();
      }
    };
  }, [currentChannel, ...dependencies]);

  const handleSubscriptionSucceeded = () => {
    console.log(`✅ Successfully subscribed to ${currentChannel.name}`);

    setIsConnected(true);
    onConnectionSet();
  };

  const handleSubscriptionError = (status) => {
    console.error('❌ Subscription error:', status);

    // clearPusher();
    onError(status);
  };

  const handleErrorOccurred = (status) => {
    console.error('❌ Error occurs:', status);

    // clearPusher();
    onError(status);
  };

  const handleNewMessage = (data) => {
    setInProgress(true);
    onMessageReceived(data, currentChannel);
  };

  const handleCloseConnection = () => {
    if (currentChannel) {
      console.log(`🚫 Unsubscribing from: ${currentChannel.name}`);

      clearPusher();
      onConnectionClosed(currentChannel);
    }
  };

  const clearPusher = () => {
    if (currentChannel) {
      currentChannel.unbind_all();
      currentChannel.unsubscribe();
    }

    setIsConnected(false);
    setInProgress(false);
    setCurrentChannel(null);
  }

  const setPusher = (channel) => {
    setIsConnected(false);
    setInProgress(false);
    setCurrentChannel(channel);
  }

  return { setPusher, clearPusher, inProgress, isConnected };
};

export default usePusherChannel;