import React, { useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/icons/close.svg';
import { ReactComponent as SuccessPaymentIcon } from '../../../../../../../assets/icons/success_payment.svg';
import { getCurrentSubscriptionStatus } from '../../../../../../../helpers/subscription';
import { notify } from '../../../../../../../providers/notification';
import useGetSubscription from '../../../../../../../hooks/useGetSubscription';

import { useStyles } from './styles';
import { getCurrentSubscription } from '../../../../../../../redux/services/subscriptions';

interface Props {
  open: boolean;
  order: any;
  title: string;
  onClose: () => void;
}

const SuccessPayment: React.FC<Props> = ({
  open,
  order,
  title,
  onClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  // const { subscription } = useGetSubscription(true);

  const handleCheckPaymentSuccess = () => {
    setLoading(true);

    let attempt = 0;

    const makeAttempt = async () => {
      const { data } = await getCurrentSubscription(undefined, true);

      if (data) {
        if (order.amount === 0) {
          onClose();
        } else if (getCurrentSubscriptionStatus(data) === 'ABSENT') {
          setTimeout(makeAttempt, 3000);
        } else {
          notify.error(
            order.amount === 0
              ? 'Card change failed'
              : 'Payment failed'
          );
          
          onClose();
        }
      } else {
        attempt++;

        if (attempt < 6) {
          setTimeout(makeAttempt, 3000);
        } else {
          notify.error(
            order.amount === 0
              ? 'Card change failed'
              : 'Payment failed'
          );

          onClose();
        }
      }
    };

    makeAttempt();
  }

  return (
    <Modal 
      open={open} 
      onClose={() => loading ? () => {} : handleCheckPaymentSuccess()} 
      className='MuiDialog-root'
    >
      <Box className={classes.modalBox}>
        {loading
          ? <CircularProgress 
              color="inherit"
              style={{
                margin: '50px auto'
              }}
            />
          : <>
              {/* <IconButton onClick={handleCheckPaymentSuccess} className={classes.closeButton}>
                <CloseIcon />
              </IconButton> */}
              <SuccessPaymentIcon />
              <Typography className={classes.title}>
                {title}
              </Typography>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                onClick={handleCheckPaymentSuccess}
                style={{ marginTop: '12px' }}
              >
                Continue
              </Button>
            </>
        }
      </Box>
    </Modal>
  );
};

export default SuccessPayment;
