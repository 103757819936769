import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "space-between",
    background: "#FFFFFF"
  },
  container: {
    width: "100%",
    maxWidth: "100%",
    height: "100vh",
    background: "#FAFAFA",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  header: {
    width: "100%",
    minHeight: "70px",
    padding: "15px 24px",
    display: "flex",
    justifyContent: "space-between",
    background: "#F5F5F5",
    // "@media (min-width: 768px)": {
    //   borderRadius: "0px 0px 20px 20px"
    // }
  },
  copilot: {
    width: '100%',
    minWidth: "740px",
    maxWidth: "740px",
    height: "100%",
    padding: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
    margin: "0 auto",
    transition: 'all .3s',
    "@media (min-width: 1550px)": {
      maxWidth: "980px",
    },
    "@media (min-width: 1850px)": {
      maxWidth: "1280px",
    }
  },
  messages: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    rowGap: "15px",
    padding: "25px 20px 0 0",
    overflowY: "auto",
    overflowX: "hidden"
  },
  followUpWrapper: {
    maxWidth: "780px",
    minWidth: "780px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    transition: 'all .3s',
    margin: '24px auto 0 auto',
    "@media (min-width: 1550px)": {
      gap: '18px',
    },
    "@media (min-width: 1850px)": {
      gap: '28px',
    }
  },
  followUpItem: {
    minWidth: '230px',
    maxWidth: '230px',
    height: '100%',
    border: '1px solid #CBF1D4',
    boxShadow: '0px 0px 0px 0px #9CDAAB',
    borderRadius: '12px',
    padding: '12px',
    background: '#FFFFFF',
    cursor: 'pointer',
    transition: 'all .3s',
    "&:hover": {
      border: '1px solid #9CDAAB',
      boxShadow: '0px 0px 5px 0px #9CDAAB'
    }
  },
  footer: {
    width: "100%",
    maxWidth: "780px",
    minWidth: "780px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "16px",
    margin: "12px auto 20px auto",
    transition: 'all .3s',
    "@media (min-width: 1550px)": {
      maxWidth: "1020px",
    },
    "@media (min-width: 1850px)": {
      maxWidth: "1320px",
    }
  },
  headerLeftBox: {
    display: "flex",
    alignItems: "center",
    gap: "7px"
  },
  headerCenterBox: {
    display: "flex",
    alignItems: "center",
    gap: "12px"
  },
  headerRightBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    gap: "30px"
  },
  newChatButton: {
    width: '32px',
    height: '32px',
    border: '1px solid #E9EAEB',
    background: '#FFFFFF',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  tabs: {
    width: "fit-content",
    padding: "1px",
    border: "1px solid #CBD5E1",
    borderRadius: "80px",
    overflow: "hidden",
    "& .MuiButtonBase-root": {
      height: "30px",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "22px",
      borderRadius: "80px",
      borderColor: "transparent !important",
      padding: "0px 12px"
    }
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  footerSuggestions: {
    display: "flex",
    alignItems: "center",
    gap: "16px"
  },
  footerInput: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: "7px",
    background: '#FFFFFF',
    border: "1px solid #94A3B8",
    padding: "12px",
    borderRadius: "8px",
    "& .MuiInput-root": {
      minHeight: "44px",
      border: "none",
      padding: "0px",
      "& textarea": {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "21.6px"
        // fontStyle: 'italic'
      }
    },
    "& ::before": {
      display: "none"
    },
    "& ::after": {
      display: "none"
    }
  },
  suggestion: {
    width: "100%",
    minWidth: "130px",
    maxWidth: "280px",
    minHeight: "82px",
    maxHeight: "82px",
    background: "white",
    padding: "20px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#F1F5F9"
    }
  },
  messagesItem: {
    width: "100%",
    display: "flex",
    gap: "10px"
  },
  comingSoon: {
    height: "45px !important",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column"
    }
  },
  welcomeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "28px",
    gap: "10px",
    margin: "0 auto 24px auto"
  },
  welcomeBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    gap: "8px"
  },
  welcomeText: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "28.8px",
    color: "#50555E",
    marginTop: '4px'
  },
  chatTitle: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.6px",
    color: '#6E737D'
  },
  divider: {
    width: "1px",
    height: "100%",
    background: "#94A3B8"
  },
  leverageBox: {
    minWidth: "300px",
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  leverageText: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#475569"
  },
  list: {
    paddingInlineStart: "17px",
    margin: "0px"
  },
  listItem: {
    marginBottom: "4px"
  },
  listItemText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#475569"
  },
  listItemTextSecondary: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#94A3B8"
  },
  soonBox: {
    width: "1px",
    height: "21px",
    background: "#94A3B8"
  },
  comingSoonText: {
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px"
  },
  // sideBarOpenCloseButton: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   width: '44px',
  //   height: '44px',
  //   background: '#FFFFFF',
  //   border: '1px solid #E9EAEB',
  //   borderRadius: '12px'
  // },
}));
