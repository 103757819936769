import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Paper, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as Robot_5 } from "../../../../assets/robots/robot_5.svg";
import { ReactComponent as Robot_6 } from "../../../../assets/robots/robot_6.svg";

import { useStyles } from './styles';

const CopilotRef = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.group}>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Paper
          className={classes.pebble}
          onClick={() => history.push('/copilot/general')}
        >
          <ArrowForwardIcon
            fontSize="small"
            className={classes.arrowIcon}
          />
          <Box className={classes.pebbleContent}>
            <Typography className={classes.pebbleTitle}>
              Marketing Copilot
            </Typography>

            <Typography className={classes.pebbleDescription}>
              For your daily marketing and sales needs.
            </Typography>
          </Box>
          <Robot_5 className={classes.pebbleImage} />
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Paper
          className={classes.pebble}
          onClick={() => history.push('/copilot/intelligence')}
        >
          <ArrowForwardIcon
            fontSize="small"
            className={classes.arrowIcon}
          />
          <Box className={classes.pebbleContent}>
            <Typography className={classes.pebbleTitle}>
              Strategy Copilot
            </Typography>

            <Typography className={classes.pebbleDescription}>
              For your actionable GTM intelligence needs.
            </Typography>
          </Box>
          <Robot_6 className={classes.pebbleImage} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CopilotRef;
