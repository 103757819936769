import { width } from "./../../../../../node_modules/@material-ui/system/index.d";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  messageTextWrapper: {
    position: 'relative',
    '&::after': {
      content: '""',
      width: '13px',
      height: '8px',
      background: '#E2ECEC',
      display: 'block',
      borderRadius: '50%',
      transform: 'rotate(15deg)',
      position: 'absolute',
      top: '-10px',
      right: '-10px'
    }
  },
  message: {
    width: "100%",
    padding: "2px 0px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    overflowX: "hidden",
    overflowY: "auto",
    whiteSpace: "break-spaces",
    border: "none",
    transition: 'all .3s',
  },
  markdown: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#262C37',
    transition: 'all .3s',
    "& *": {
      fontFamily: 'Objectivity',
      margin: 0,
    },
    "& p": {
      whiteSpace: "normal"
    },
    "& h1": {
      fontWeight: "normal"
    },
    "& h2": {
      fontWeight: "normal"
    },
    "& h3": {
      fontWeight: "normal"
    },
    "& h4": {
      fontWeight: "normal"
    },
    "& h5": {
      fontWeight: "normal"
    },
    "& h6": {
      fontWeight: "normal"
    },
    "& ul": {
      whiteSpace: "initial"
    },
    "& ol": {
      whiteSpace: "initial"
    },
    "& code": {
      textWrap: 'auto',
      fontFamily: 'Objectivity',
    }
  },
  markdownMinHeight: {
    minHeight: '150px'
  },
  errorMeesage: {
    width: "fit-content",
    color: "#D93855",
    padding: "8px 14px 6px 14px",
    background: "transparent",
    borderRadius: "6px",
    border: "1px solid #D93855",
    lineHeight: '21px'
  },
  progressBar: {
    width: "100%",
    height: "14px",
    borderRadius: "22px",
    overflow: "hidden",
    backgroundColor: "transparent",
    position: "relative",
    margin: "8px 0px",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "linear-gradient(to right, #E9FEEE, #9CDAAB, #E9FEEE)",
      animation: "$progressAnimation 2s linear infinite"
    }
  },
  actionsBox: {
    width: 'fit-content',
    display: "flex",
    alignItems: "center",
    gap: "7px",
    marginTop: "8px",
    background: '#F5F5F5',
    borderRadius: '12px',
    padding: '8px'
  },
  verticalSeparator: {
    width: "1px",
    height: "25px",
    background: "#CBD5E1"
  },
  "@keyframes progressAnimation": {
    "0%": {
      transform: "translateX(-100%)"
    },
    "100%": {
      transform: "translateX(100%)"
    }
  }
}));
