import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  FormHelperText
} from "@material-ui/core";

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import ProblemStatementController, {
  Props,
  configJSON,
} from "./ProblemStatementController.web";
import { styles } from "../utils/styles";

export class Services extends ProblemStatementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { 
      title, 
      inputList, 
      serviceArray, 
      handleChange1, 
      tooltipData, 
      handleChange, 
      showListHeader, 
      processText, 
      name1, 
      name2, 
      goodToHave = false, 
      handleChange2, 
      handleChange3, 
      classes, 
      offeringText = false,
      requiredIndex
    } = this.props
    const containerStyle = { ...webStyle.mainContainer, ...(this.styleCondition(processText, webStyle.servicesMainContainer, {})) }

    return (
      <>
        <Grid container style={this.styleCondition(!!showListHeader, {}, containerStyle)}>
          <Grid item xs={12} style={offeringText ? webStyle.servicesContainer2 : { ...webStyle.container, ...(this.styleCondition(processText, {}, webStyle.servicesContainer)) }}>
            {!showListHeader && (
              <CustomList 
                title={title} 
                servicesList={serviceArray} 
                modalContent={tooltipData || configJSON.serviceModal} 
              />
            )}
            <Box style={{ ...webStyle.offeringBox, ...(this.styleCondition(processText, webStyle.processStyle, {})) }}>
              {inputList.length > 0 && inputList.map((data: any, index: any) => {
                return (
                  <Box key={index} style={this.styleCondition(processText, { width: '100%' }, { display: 'flex', flexDirection: 'column', width: "32%", rowGap: '16px' })}>
                    <CustomInput
                      customeLabel={this.styleCondition(!processText, "", data.offeringTitle.replace("N", index + 1))}
                      data-testid={"offering-field-" + index}
                      placeholder={data.offeringPlaceholder}
                      textLabel={this.styleCondition(processText, "", data.offeringTitle)}
                      labelCount={index}
                      rowsCount={1}
                      value={data.offering}
                      name={name1}
                      error={data.offeringError}
                      width="100%"
                      onChange={(e: any) => handleChange?.(e, index)}
                      maxCharacterLenght={data.charLength}
                      maxWordsLength={data.wordsLength}
                      required={requiredIndex === index}
                    />
                    {goodToHave && (
                      <Box style={webStyle.boxWrapper}>
                        <Typography style={webStyle.offering} >{data.priceTitle}</Typography>
                        <div>
                          <TextField
                            data-testid={"price-field-" + index}
                            multiline
                            variant="outlined"
                            id="outlined-multiline-static"
                            minRows={1}
                            maxRows={12}
                            value={data.price}
                            placeholder={data.pricePlaceholder}
                            InputProps={{
                              endAdornment: (
                                <div style={{ width: '100%' }} className={classes.priceCurrencyInput}>
                                  <InputAdornment position="end">
                                    <FormControl variant="outlined" style={{ ...webStyle.inputProps1, width: "100%", }}>
                                      <Select
                                        id="currency"
                                        data-testid={"currency-field-" + index}
                                        labelId="currency-label"
                                        value={data.currency}
                                        label="Currency"
                                        onChange={(e: any) => handleChange3?.(e, index)}
                                        style={{
                                          ...webStyle.inputProps1, width: "100%",
                                          color: this.styleCondition(data.price, '#000', "#94A3B8"),
                                          fontStyle: this.styleCondition(data.price, "normal", "normal"),
                                        }}
                                      >
                                        <MenuItem value="null">Choose</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="GBP">GBP</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </InputAdornment>
                                </div>
                              ),
                              style: {
                                ...webStyle.inputProps, width: "100%",
                                color: this.styleCondition(data.price, '#000', "#94A3B8"),
                                fontStyle: this.styleCondition(data.price, "normal", "normal"),
                              }
                            }}
                            style={{ ...webStyle.inputProps1, width: "100%" }}
                            name={"price"}
                            error={!!data.priceError}
                            type="number"
                            onChange={(e: any) => handleChange2?.(e, index)}
                          />

                        </div>
                        {!!data.priceError && (
                          <FormHelperText error>
                            {data.priceError}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                    <CustomInput
                      textLabel={data.descriptionTitle}
                      labelCount={index}
                      placeholder={data.descriptionPlaceholder}
                      rowsCount={this.rowCount()}
                      value={data.description}
                      error={data.descriptionError}
                      name={name2}
                      data-testid={"description-field-" + index}
                      onChange={(e: any) => handleChange1?.(e, index)}
                      maxCharacterLenght={data.charLengthDesc}
                      maxWordsLength={data.wordsLengthDesc}
                      width="100%"
                      required={requiredIndex === index}
                    />
                  </Box>
                )
              }
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  mainContainer: {
    // marginTop: "10px"
  },
  servicesMainContainer: {
    margin: '0.8rem 0px',
  },
  container: {
    background: '#fff',
    borderRadius: '28px',
    height: 'max-content',
    // border: '1px solid #E9EAEB'
  },
  servicesContainer: {
    padding: '32px 40px',
  },
  servicesContainer2: {
    background: '#fff',
    borderRadius: '28px',
    height: 'max-content',
    padding: '0px 0px',
    border: '1px solid #E9EAEB'
  },
  offeringBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '16px'
  },
  processStyle: {
    flexDirection: 'column'
  },
  inputProps: {
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    lineHeight: '21px',
    padding: '10px',
  },
  inputProps1: {
    // padding: '10px',
  },
  offering: {
    minHeight: '24px',
    fontSize: '0.87rem',
    fontWeight: 500,
    paddingLeft: "12px"
  },
  boxWrapper: {
    margin: "10px 0px"
  },
}

export default withStyles(styles)(Services);