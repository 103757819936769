import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  grouppedRadioButtonWrapper: {
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '0px',
    // maxHeight: '550px',
    display: 'flex',
    // flexDirection: 'column',
    flexWrap: 'wrap',
    overflow: 'hidden',
    gap: '0px',
    padding: '0px',
    boxSizing: 'content-box',
    // [theme.breakpoints.down(1398)]: {
    //   maxHeight: '600px',
    // },
    // [theme.breakpoints.down(1360)]: {
    //   maxHeight: '550px',
    // },
    // [theme.breakpoints.down(1310)]: {
    //   maxHeight: '550px',
    // },
    // [theme.breakpoints.down(1198)]: {
    //   maxHeight: '600px',
    // },
    // [theme.breakpoints.down(1108)]: {
    //   maxHeight: '600px',
    // },
    // [theme.breakpoints.down(1094)]: {
    //   maxHeight: '650px',
    // },
    // [theme.breakpoints.down(1086)]: {
    //   maxHeight: '650px',
    // },
  },
  grouppedRadioButton: {
    minWidth: '48%',
    maxWidth: '48%',
    display: 'flex',
    flexDirection: 'column',
  },
  paddings: {
    padding: '0px 0px 8px 30px',
  },
  paddings2: {
    padding: '0px 0px 0px 30px',
  },
  gaps: {
    // gap: '24px',
    gap: '10px',
  },
  nestedSeparator: {
    width: '2px', 
    height: '75%', 
    background: '#0F7F75', 
    borderRadius: '2px',
    position: 'absolute',
    top: '25px',
    left: '17px',
  }
}));