import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  statusContainer: {
    height: '28px',
    padding: '8px 12px',
    borderRadius: 8,
    fontSize: '16px',
    lineHeight: '15px',
    display: 'inline-flex',
  },
  menuContainer: {
    background: 'blue'
  },
  clearSelection: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    borderRadius: "6px",
    height: '40px',
    padding: '10px 12px 10px 4px',
    margin: '0 8px 2px',
    transition: "background 0.2s ease-in-out",
    '&:hover': {
      backgroundColor: '#FAFAFA',
    }
  },
  menuItemActive: {
    backgroundColor: '#F4F7F7'
  },
  menuSelectAll: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: "bold",
    padding: "0 18px",
    height: "44px",
  },
  userWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  userAvatar: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    color: '#50555E',
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '36px',
    fontWeight: 500,
  },
  userName: {
    fontSize: '16px',
    color: '#262C37',
  },
  listWrapper: {
    maxHeight: '380px',
    overflow: 'auto'
  }
}));