import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",

    "@media (max-width: 767.98px)": {
      justifyContent: "center",
      marginTop: 40
    },

    "@media (min-width: 768px)": {
      height: "100%"
    }
  },
  text: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "28.8px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "#94A3B8",
    userSelect: 'none',
    "@media (min-width: 768px)": {
      marginLeft: "auto",
      marginRight: 78
    }
  },
  outerPaper: {
    padding: 16,
    borderRadius: "28px",
    backgroundColor: "#F1F5F9",

    "@media (min-width: 768px)": {
      padding: 20
    }
  },
  leftBlock: {
    display: "flex"
  },
  leftBlockTitle: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "21.6px",
    letterSpacing: "-0.005em",
    color: "#475569",

    "@media (max-width: 767.98px)": {
      textAlign: "center"
    }
  },
  leftBlockDescription: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14.4px",
    textAlign: "left" as "left",
    color: "#475569",
    marginTop: 34,

    "@media (max-width: 767.98px)": {
      marginTop: 12
    }
  },
  leftBlockContent: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between"
  },
  rightBlock: {}
}));
