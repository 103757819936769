import React from "react";
import { Box, Button } from "@material-ui/core";
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow_down_v2.svg';
import { DateTime } from 'luxon';
import { RangeType } from "rsuite/esm/DateRangePicker/types";
import { useStyles } from "./styles";
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';

interface Props {
  buttonName: string,
  value: [Date, Date],
  onChange: (value: [Date, Date]) => void;
}

const CustomDateRangePicker: React.FC<Props> = ({ buttonName, value, onChange }) => {

  const classes = useStyles();

  const predefinedRanges: RangeType[] = [
    {
      label: 'Today',
      value: [DateTime.now().startOf('day'), DateTime.now().endOf('day')],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [DateTime.now().minus({ days: 1 }).startOf('day'), DateTime.now().minus({ days: 1 }).endOf('day')],
      placement: 'left'
    },
    {
      label: "Last Week",
      value: [
        DateTime.now().minus({ weeks: 1 }).startOf('week'),
        DateTime.now().minus({ weeks: 1 }).endOf('week')
      ],
      placement: "left"
    },
    {
      label: "Last Month",
      value: [
        DateTime.now().minus({ months: 1 }).startOf('month'),
        DateTime.now().minus({ months: 1 }).endOf('month')
      ],
      placement: "left"
    }
  ];

  return (
    <Box className={classes.datePickerContainer}>
      <Button
        variant="outlined"
        endIcon={<ArrowDown width={17} height={17} />}
        style={{
          textTransform: "none",
          borderRadius: "8px",
          borderColor: "#E9EAEB",
          color: "#262C37",
          backgroundColor: "#fff",
          fontSize: 16,
        }}
        disableRipple={true}
      >
        {buttonName}
        <DateRangePicker onChange={(val) => onChange(val)}
          placement="bottomEnd"
          editable={false}
          placeholder={'Date updated'}
          ranges={predefinedRanges}
          showHeader={false}
          showOneCalendar
          value={value}
          weekStart={1}
          className={classes.datePickerInput}
        />
      </Button>
    </Box>
  );
};

export default CustomDateRangePicker;
