import 'react-app-polyfill/ie11'; // Optional: Only if you need IE11 support
import 'react-app-polyfill/stable'; // Optional: For stable browser support

import ReactDOM from "react-dom/client"; // Use the modern API
import { BrowserRouter as RouterProvider } from 'react-router-dom';
import App from "./App";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <RouterProvider>
    <App />
  </RouterProvider>
);
