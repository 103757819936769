import React, { useEffect, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as RegenerateChatMessageIcon } from '../../../../assets/icons/regenerate_copilot_message.svg';
import { ReactComponent as CopyChatMessageIcon } from '../../../../assets/icons/copy_chat_message_icon.svg';
import { ReactComponent as LikeChatMessageIcon } from '../../../../assets/icons/like_copilot_message.svg';
import { ReactComponent as DislikeChatMessageIcon } from '../../../../assets/icons/dislike_copilot_message.svg';
import { ReactComponent as MyLibraryIcon } from '../../../../assets/icons/my_library_grey.svg';
import { likeMessage, dislikeMessage } from "../../../../redux/services/copilot";
import Markdown from "react-markdown";
import { marked } from "marked";
import { notify } from "../../../../providers/notification";
import { AnswerStatus, Chat, CopilotTypes, MessageAuthorTypes } from "../../../../interfaces/copilot";

import { useStyles } from "./styles";

interface Props {
  id: string;
  tab: CopilotTypes;
  chat: Chat;
  text: string;
  // speed: number;
  author: MessageAuthorTypes;
  answerStatus: AnswerStatus;
  status: string;
  // allowRefetch={item.message === 'error' && index === chat.messages.length - 1}
  inProgress: boolean;
  allowRefetch: boolean;
  allowAnimate: boolean;
  scrollToBottom: () => void;
  setChat: (chat: Chat) => void;
  setSaveAnswerConfig: (newState: { active: boolean, message: any }) => void;
}

const TypingText: React.FC<Props> = ({ 
  id,
  tab,
  chat,
  text, 
  // speed,
  author,
  answerStatus,
  status,
  inProgress,
  allowRefetch,
  allowAnimate, 
  scrollToBottom,
  setChat,
  setSaveAnswerConfig,
}) => {
  const classes = useStyles();
  const isValidMessage = (
    !!text.length && 
    author === 'copilot' && 
    text !== 'error' && 
    status !== 'FAILED'
  );
  const isNotLastMessage = chat.messages.findLastIndex(item => item.id === id) !== chat.messages.length - 1;
  const showActionButtons = inProgress ? isNotLastMessage && isValidMessage : isValidMessage;

  const handleCopyMesssage = async () => {
    const htmlContent = await marked(text);
    const textContent = text; // Plain text version

    const htmlBlob = new Blob([htmlContent], { type: "text/html" });
    const textBlob = new Blob([textContent], { type: "text/plain" });

    const clipboardItem = new ClipboardItem({
      "text/html": htmlBlob,
      "text/plain": textBlob,
    });

    navigator.clipboard.write([clipboardItem])
      .then(() => {
        notify.info("Copied to the clipboard");
      })
      .catch(() => {
        notify.error("Failed to copy");
      });
  }

  const handleLikeMessage = async () => {
    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: AnswerStatus.LIKED
            }
          : item
      ))
    })

    await likeMessage({ 
      sessionId: chat.sessionId, 
      messageId: id, 
      tab: tab.toLowerCase()
    });
  }

  const handleDislikeMessage = async () => {
    setChat({
      ...chat,
      messages: chat.messages.map(item => (
        item.id === id
          ? {
              ...item,
              answerStatus: AnswerStatus.DISLIKED
            }
          : item
      ))
    })

    await dislikeMessage({ 
      sessionId: chat.sessionId, 
      messageId: id, 
      tab: tab.toLowerCase()
    });
  }

  return (
    <Box
      className={author === 'user' ? classes.messageTextWrapper : ''}
      style={{ 
        width: author === 'user' ? 'fit-content' : '100%',
        maxWidth: author === 'user' ? '60%' : '100%',
        // height: (!chat || chat.id === 'none') ? 'fit-content' : '100%',
        // textAlign: author === 'user' ? 'right' : 'left',
        margin: author === 'user' ? '0 0 0 auto' : '0 auto 0 0',
        background: author === 'user' ? '#EBF3F3' : 'transparent',
        padding: author === 'user' ? '8px 12px' : '0',
        borderRadius: author === 'user' ? '16px 2px 16px 16px' : 'none',
        marginRight: author === 'user' ? '5px' : '0px'
      }}
    >
      {author === 'copilot' && (text === 'error' || status === 'FAILED')
        ? <Box className={classes.errorMeesage}>
            Hmmm... Something seems to have gone wrong.
          </Box>
        : <Typography className={classes.message}>
            {!!text.length
              ? <Markdown
                  className={`${classes.markdown} ${inProgress && author === 'copilot' ? classes.markdownMinHeight : ''}`}
                >
                  {text}
                </Markdown>
              : <Box style={{ width: "100%", minHeight: '200px' }}>
                  <Box className={classes.progressBar} style={{ opacity: '70%' }} />
                  <Box className={classes.progressBar} style={{ opacity: '30%' }} />
                </Box>
            }
          </Typography>
      }
      {showActionButtons && (
        <Box className={classes.actionsBox}>
          <IconButton
            size="small"
            disableRipple
            disabled={inProgress}
            style={{
              borderRadius: '8px',
            }}
            onClick={() => {
              setSaveAnswerConfig({
                active: true,
                message: {
                  id,
                  message: text,
                  type: tab.toUpperCase()
                }
              })
            }}
          >
            <Tooltip
              arrow
              enterDelay={1000}
              title={'Save to Library'}
              placement="bottom"
            >
              <MyLibraryIcon />
            </Tooltip>
          </IconButton>
          <Box className={classes.verticalSeparator} />
          <IconButton 
            size="small"
            disableRipple
            disabled={inProgress}
            style={{
              borderRadius: '8px',
            }}
            onClick={() => handleCopyMesssage()}
          >
            <Tooltip
              arrow
              enterDelay={1000}
              title={'Copy'}
              placement="bottom"
            >
              <CopyChatMessageIcon />
            </Tooltip>
          </IconButton>

          {answerStatus && (
            <>
              <Box className={classes.verticalSeparator} />
              {(answerStatus === 'LIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  disableRipple
                  disabled={inProgress}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleLikeMessage()
                    }
                  }}
                  style={{
                    borderRadius: '8px',
                    opacity: answerStatus === 'LIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'LIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'LIKED'
                        ? 'You have liked this response'
                        : 'Good response'
                    }
                    placement="bottom"
                  >
                    <LikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
              {(answerStatus === 'DISLIKED' || answerStatus === 'UNEVALUATED') && (
                <IconButton 
                  size="small"
                  disableRipple
                  disabled={inProgress}
                  onClick={() => {
                    if (answerStatus === 'UNEVALUATED') {
                      handleDislikeMessage()
                    }
                  }}
                  style={{
                    borderRadius: '8px',
                    opacity: answerStatus === 'DISLIKED' ? '0.5' : '1',
                    // pointerEvents: answerStatus === 'DISLIKED' ? 'none' : 'all',
                  }}
                >
                  <Tooltip
                    arrow
                    enterDelay={1000}
                    title={
                      answerStatus === 'DISLIKED'
                        ? 'You have disliked this response'
                        : 'Bad response'
                    }
                    placement="bottom"
                  >
                    <DislikeChatMessageIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TypingText;
