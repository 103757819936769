import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plus.svg';
import { ReactComponent as SideBarCloseIcon } from '../../../../assets/icons/copilot_sidebar_close.svg';
import { ReactComponent as SideBarOpenIcon } from '../../../../assets/icons/copilot_sidebar_open.svg';
import { ReactComponent as ChatContextMenuIcon } from '../../../../assets/icons/three_vertical_dots.svg';
import ConfirmationModal from "../../../../components/main/ConfirmationModal";
import { changeChatName, deleteChat } from "../../../../redux/services/copilot";
import { notify } from "../../../../providers/notification";
import { Chat, CopilotTypes } from "../../../../interfaces/copilot";
import TextField from "../../../../components/main/CustomFields/TextField";
import { isStartUpTeamsSubscription } from "../../../../helpers/roles";
import { AccountSubscriptionResponse } from "../../../../interfaces/subscriptions";

import { useStyles } from "./styles";

interface Props {
  open: boolean;
  tab: CopilotTypes;
  chats: Chat[];
  chat: Chat;
  subscription: AccountSubscriptionResponse;
  loading: boolean;
  leavingModal: { active: boolean; nextLocation: string | null };
  inProgress: boolean;
  setLoading: (loading: boolean) => void;
  isOnboardingCompleted: boolean;
  isSubscriptionActive: boolean;
  handleGetChats: (isAddNewChat: boolean) => void;
  setSideBarOpen: (open: boolean) => void;
  setChat: (chat: Chat) => void;
  setChats: (chats: Chat[]) => void;
  clearPusher: () => void;
}

const SideBar: React.FC<Props> = ({
  open,
  tab,
  chats,
  chat,
  subscription,
  loading,
  leavingModal,
  inProgress,
  setLoading,
  isOnboardingCompleted,
  isSubscriptionActive,
  handleGetChats,
  setSideBarOpen,
  setChat,
  setChats,
  clearPusher,
}) => {
  const [deleteConfig, setDeleteConfig] = useState<{ active: boolean, targetId: number | string | null }>({
    active: false,
    targetId: null
  });
  const [renameConfig, setRenameConfig] = useState<{ active: boolean, targetId: number | string | null }>({
    active: false,
    targetId: null
  });
  const [anchor, setAnchor] = useState<{ element: HTMLElement | null, targetId: number | string | null }>({
    element: null,
    targetId: null
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setRenameConfig({
      active: false,
      targetId: null
    });
  }, [tab]);

  const handleDeleteChat = async (id) => {
    setLoading(true);
    setDeleteConfig({
      active: false,
      targetId: null
    });

    const { error } = await deleteChat({ id, tab: tab.toLowerCase() });

    if (!error) {
      if (chat?.id === id) {
        history.push(`/copilot/${tab}`);
      } else {
        handleGetChats(false);
      }

      notify.success('Chat deleted successfully');
    } else {
      notify.error('Failed to delete the chat');
    }

    setLoading(false);
  }

  const handleGroupChats = () => {
    const defaultGroups = { Today: [], ['Last Week']: [], Older: [] };

    if (chats?.length) {
      const isNewChat = chats.find(item => item.id === 'none');
      const today = new Date();
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(today.getDate() - 7);

      const groupedChats = chats.filter(item => item.id !== 'none').reduce((acc, item) => {
        const itemDate = new Date(item.createdAt);

        if (itemDate.toDateString() === today.toDateString()) {
          acc.Today.push(item);
        } else if (itemDate >= oneWeekAgo) {
          acc['Last Week'].push(item);
        } else {
          acc.Older.push(item);
        }

        return acc;
      }, defaultGroups);

      if (isNewChat) {
        groupedChats.Today.unshift(isNewChat);
      }

      return groupedChats;
    } else {
      return defaultGroups;
    }
  }

  const onRenameChat = async (id, name) => {
    const { error } = await changeChatName({
      chatId: id,
      modificator: tab,
      body: {
        title: name
      }
    });

    if (!error) {
      notify.success('Changes saved');

      setChat({
        ...chat,
        title: name
      })
    } else {
      notify.error('Failed to rename');
    }
  }

  const beforeRenameCheck = (event) => {
    if (!event.target?.value?.trim()?.length) {
      setChats(chats.map(item => (
        renameConfig.targetId === item.id
          ? {
            ...item,
            title: event.target._wrapperState.initialValue
          }
          : item
      )))
    } else if (event.target?.value?.trim().length <= 60) {
      setRenameConfig({
        active: false,
        targetId: null
      })

      onRenameChat(chat.id, event.target?.value?.trim());
    } else {
      notify.error('Max 60 characters');
    }
  }

  return (
    <>
      <Box className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}>
        {/* <Box
          className={classes.sideBarOpenCloseButton}
          onClick={() => setSideBarOpen(!open)}
        >
          {open
            ? <SideBarOpenIcon />
            : <SideBarCloseIcon />
          }
        </Box> */}
        {open && (
          <Box className={classes.sideBarHeader}>
            <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '18.9px', color: '#262C37' }}>
              &nbsp;&nbsp;&nbsp;
              CHATS
            </Typography>
            {/* <IconButton 
              size="small"
              onClick={() => {
                if (chat && chat?.id !== 'none' && isOnboardingCompleted && isSubscriptionActive) {
                  setChat(null);
                  history.push(`/copilot/${tab}`);
                }
              }}
            >
              <PlusIcon />
            </IconButton> */}
          </Box>
        )}
        <Box
          className={classes.sideBarContent}
        // style={{
        //   opacity: (isOnboardingCompleted && isSubscriptionActive) ? '1' : '0.5',
        //   pointerEvents: (isOnboardingCompleted && isSubscriptionActive) ? 'all' : 'none',
        // }}
        >
          {open && (
            loading
              ? <CircularProgress
                color="inherit"
                style={{ width: '30px', height: '30px', margin: '60px auto' }}
              />
              : Object.entries(handleGroupChats()).map(([key, value]) => (
                !!value?.length && (
                  <>
                    <Typography style={{ userSelect: 'none', fontSize: '12px', fontWeight: '500', lineHeight: '15.6px', color: '#6E737D', padding: '16px 5px 5px 12px' }}>
                      {key}
                    </Typography>
                    {value?.map((item, index) => (
                      renameConfig.active && renameConfig.targetId === item.id
                        ? <TextField
                            value={item.title}
                            // autoFocus
                            // type={'textarea'}
                            // minRows={2}
                            // maxRows={4}
                            onChange={(event) => {
                              setChats(chats.map(item => (
                                renameConfig.targetId === item.id
                                  ? {
                                    ...item,
                                    title: event.target.value
                                  }
                                  : item
                              )))
                            }}
                            onBlur={(event) => {
                              beforeRenameCheck(event);
                            }}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                              
                                beforeRenameCheck(event);
                              }
                            }}
                          />
                        : <Box
                            key={index}
                            className={`${classes.sideBarItem} ${(chat?.id === item.id || item.id === 'none') ? classes.sideBarItemActive : ''}`}
                            style={{
                              opacity: !item.id || !isOnboardingCompleted || !isSubscriptionActive
                                ? '0.5'
                                : '1',
                              pointerEvents: !item.id || !isOnboardingCompleted || !isSubscriptionActive
                                ? 'none'
                                : 'all'
                            }}
                            onClick={() => {
                              if (!inProgress && item.id && item.id !== 'none' && item.id !== chat?.id) {
                                history.push(`/copilot/${tab}/${item.id}`);
                              
                                setRenameConfig({
                                  active: false,
                                  targetId: null
                                });
                              }
                            }}
                          >
                            <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
                              {item.title || 'N/A'}
                            </Typography>
                            {!inProgress && chat?.id === item.id && (
                              <IconButton
                                size="small"
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  if (!inProgress) {
                                    event.stopPropagation();
                                
                                    setAnchor({
                                      element: event.currentTarget,
                                      targetId: item.id
                                    });
                                  }
                                }}
                              >
                                <ChatContextMenuIcon
                                  width={'17px'}
                                  height={'17px'}
                                />
                              </IconButton>
                            )}
                          </Box>
                    ))}
                  </>
                )
              ))
          )}
        </Box>
      </Box>

      <Menu
        disablePortal
        open={!!anchor.element}
        anchorEl={anchor.element}
        PopoverClasses={{
          paper: classes.itemContextMenu
        }}
        onClose={() => {
          setAnchor({
            element: null,
            targetId: null
          })
        }}
      >
        <MenuItem
          onClick={() => {
            setRenameConfig({
              active: true,
              targetId: anchor.targetId
            });

            setAnchor({
              element: null,
              targetId: null
            });
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          style={{
            color: '#CF292C'
          }}
          onClick={() => {
            setDeleteConfig({
              active: true,
              targetId: anchor.targetId
            });

            setAnchor({
              element: null,
              targetId: null
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <ConfirmationModal
        open={deleteConfig.active}
        title={'Are you sure you want to delete the chat?'}
        description={'Please be aware that the entire conversation with Pebbles will be permanently deleted and cannot be recovered.'}
        confirmBtnText={'Delete'}
        rejectBtnText={'Cancel'}
        onClose={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
        onConfirm={() => handleDeleteChat(deleteConfig.targetId)}
        onReject={() => setDeleteConfig({
          active: false,
          targetId: null
        })}
      />
    </>
  );
};

export default SideBar;
