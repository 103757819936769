import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#FAFAFA',
    height: 'calc(100svh - 2px)',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    padding: '40px 30px',
    background: '#FAFAFA',
    maxWidth: '1300px',
    minWidth: '1050px',
    margin: '0 auto',
  },
  header: {
    width: '100%',
    minWidth: '880px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '28px'
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  filtersSearch: {
    width: '100%',
    maxWidth: '416px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px'
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#262C37',
    textTransform: 'uppercase',
    marginBottom: '16px',
    userSelect: 'none'
  },
  folders: {

  },
  recentFiles: {
    marginTop: '24px',
  },
  foldersContainer: {
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap',
  }
}));