import React, { createContext, useContext } from "react";
import localforage from "localforage";

// Configure localforage
localforage.config({
  name: "PebblesAi",
  storeName: "keyvaluepairs" // Table name in IndexedDB
});

// Create Context
const StorageContext = createContext();

// Storage service class
class StorageService {
  async setItem(key, value) {
    try {
      await localforage.setItem(key, value);
    } catch (error) {
      console.error("Error setting item in storage:", error);
    }
  }

  async getItem(key) {
    try {
      const value = await localforage.getItem(key);
      return value !== null ? value : null;
    } catch (error) {
      console.error("Error getting item from storage:", error);
      return null;
    }
  }

  async removeItem(key) {
    try {
      await localforage.removeItem(key);
    } catch (error) {
      console.error("Error removing item from storage:", error);
    }
  }

  async clear() {
    try {
      await localforage.clear();
    } catch (error) {
      console.error("Error clearing storage:", error);
    }
  }
}

const storageService = new StorageService();

// Provider Component
const StorageProvider = ({ children }) => {
  return (
    <StorageContext.Provider value={storageService}>
      {children}
    </StorageContext.Provider>
  );
};

// Custom Hook to Use Storage
const useStorage = () => {
  const context = useContext(StorageContext);
  if (!context) {
    throw new Error("useStorage must be used within a StorageProvider");
  }
  return context;
};

export {
  StorageProvider,
  storageService as StorageService,
  useStorage
};
