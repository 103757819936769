import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import { DateTime } from 'luxon';
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { ReactComponent as CalendarIcon } from '../../../../../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../../../../../assets/icons/clock_icon.svg';
import { convertHTMLToText, convertMarkdownToText, isHTML } from "../../../../helpers";
import { ReactComponent as ReloadIcon } from '../../../../../../assets/icons/reload_icon.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/edit_dark.svg';
import { CampaignStatuses, CampaignSubCategory } from "../../../../../../interfaces/campaign";

import { useStyles } from "./styles";
import { deleteLibraryEmail } from "../../../../../../redux/services/my-library";

const EmailsList = ({
  campaign,
  email,
  setEmail,
  setCampaign,
  setLoading,
  handleGetCampaign,
  isSaveDisabled,
  inProgress,
  setIsAuthomationActive,
  generateInitEmail
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      {campaign.emails.map((item, index) => (
        <Box
          key={item.order + index}
          className={`${classes.sideBarItem} ${email.id === item.id ? classes.sideBarItemActive : ''}`}
          onClick={() => {
            if (item.status === 'COMPLETED') {
              setIsAuthomationActive(false);

              setEmail(item);
              setCampaign({
                ...campaign,
                emails: campaign.emails.filter(item => item.status !== 'FAKE')
              })
            }
          }}
          style={{
            position: 'relative',
            // opacity: item.status === 'PENDING' ? '0.7' : '1',
            boxShadow: item.status === 'FAILED'
              ? '-2px 0 0 0 #DC2626'
              : item.status === 'PENDING'
                ? '-2px 0 0 0 #EFF8FF'
                : 'none',
            pointerEvents: (item.status === 'COMPLETED' || item.status === 'FAILED' || item.status === 'PENDING')
              ? 'all'
              : 'none',
            cursor: item.status === 'PENDING'
              ? 'wait'
              : item.status === 'COMPLETED'
                ? 'pointer'
                : item.status === 'FAILED'
                  ? 'default'
                  : 'not-allowed'
          }}
        >
          {item.status === 'PENDING' && (
            <CircularProgress
              color="inherit"
              style={{
                width: '20px',
                height: '20px',
                position: 'absolute',
                top: '35%',
                left: '45%',
              }}
            />
          )}
          {item.status === 'FAILED' && (
            <Button
              color="secondary"
              variant="contained"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                outline: '300px solid rgba(0, 0, 0, 0.2)',
                zIndex: 1
              }}
              startIcon={<ReloadIcon style={{ color: 'black' }} />}
              onClick={async () => {
                setIsAuthomationActive(true);
                setLoading(true);

                const actualCampaign = await handleGetCampaign(campaign.id, email.id);
                const lastFailedEmail = actualCampaign.emails.find(item => !item.content?.trim()?.length);
                const channel_id = uuidv4();

                if (lastFailedEmail) {
                  await deleteLibraryEmail({
                    campaignId: actualCampaign.id as string,
                    emailId: lastFailedEmail.id as string
                  });
                  generateInitEmail(campaign.id, null, channel_id);
                } else {
                  generateInitEmail(campaign.id, email.id, channel_id);
                }
              }}
            >
              Retry
            </Button>
          )}
          <Box style={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
            <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
              {item.status === 'COMPLETED' && item.subject?.trim()?.length ? item.subject : '—'}
            </Typography>
            {/* {campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS && campaign.status === CampaignStatuses.COMPLETED && item.status === 'COMPLETED' && (
              <IconButton
                size="small"
                onClick={() => {
                  history.push(`/edit/campaign/builder?campaign_id=${campaign.id}&target_email_id=${email.id}`)
                }}
              >
                <EditIcon />
              </IconButton>
            )} */}
          </Box>
          <Typography className={classes.sideBarItemContent}>
            {item.status === 'COMPLETED'
              ? isHTML(item.content)
                ? convertHTMLToText(item.content) || '—'
                : convertMarkdownToText(item.content) || '—'
              : '—'
            }
          </Typography>
          <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', }}>
            <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', marginRight: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
              <CalendarIcon />
              {item.updatedAt?.trim()?.length ? DateTime.fromISO(item.updatedAt, { zone: 'utc' }).setZone().toFormat('dd-MMM-yyyy') : '—'}
            </Typography>
            <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
              <ClockIcon style={{ color: '#475569' }} />
              {item.updatedAt?.trim()?.length ? DateTime.fromISO(item.updatedAt, { zone: 'utc' }).setZone().toFormat('HH:mm') : '—'}
            </Typography>
          </Box>
        </Box>
      ))}
      {campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS && campaign.status === CampaignStatuses.INCOMPLETE && campaign.emails.filter(item => item.status === 'COMPLETED').length < campaign.metaData.totalEmails && (
        <Typography style={{ marginTop: '10px', textAlign: 'center', fontSize: '14px', fontWeight: '400', lineHeight: '19px', color: '#50555E' }}>
          {campaign.emails.filter(item => item.status === 'COMPLETED').length}/{campaign.metaData.totalEmails} generated
        </Typography>
      )}
    </>
  );
};

export default EmailsList;
