import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

interface Props {
  tabs: {
    key: string,
    title: string,
    icon: React.ReactNode
  }[];
  currentTab: string;
  onTabChange: (newTab: string) => void;
}

const Tabs: React.FC<Props> = ({
  tabs,
  currentTab,
  onTabChange
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.tabsWrapper}>
      {tabs.map((item, index) => (
        <Box
          key={index}
          className={`${classes.tab} ${currentTab === item.key ? classes.tabActive : ''}`}
          onClick={() => onTabChange(item.key)}
        >
          {item.icon}
          <Typography style={{ fontSize: '18px', fontWeight: '400', lineHeight: '18px', color: '#50555E' }}>
            {item.title}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Tabs;
