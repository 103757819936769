import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button } from "@material-ui/core";
import { ReactComponent as EditWhiteIcon } from '../../../../assets/icons/edit_white.svg';

import { useStyles } from './styles';

const Header = ({
  showActivateLicense,
  showRenewLicense,
  showCompleteOnboardingBaner
}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        {(showActivateLicense || showRenewLicense)
          ? <Box
              className={classes.inner}
              style={{ padding: '60px 20px' }}
            >
              <Typography
                className={classes.heading}
                style={{ margin: '0px', fontSize: '32px', fontWeight: '600', lineHeight: '35px', color: '#FFFFFF' }}
              >
                {/* {showRenewLicense
                  ? 'Renew your license'
                  : 'Activate your license'
                } */}
                Activate your license
              </Typography>
              <Typography
                className={classes.description}
                style={{ fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#E2E8F0' }}
              >
                Unlock the full potential of Pebbles AI platform.
              </Typography>
              <br />
              <Button
                color={'primary'}
                variant={'outlined'}
                className={classes.fillOnboarding}
                style={{ padding: '7px 16px' }}
                onClick={() => history.push('/subscriptions')}
              >
                Go to subscriptions
              </Button>
            </Box>
          : showCompleteOnboardingBaner
            ? <Box className={classes.inner}>
                <Typography
                  className={classes.heading}
                // style={{ margin: '0px', fontSize: '24px', fontWeight: '600', lineHeight: '35px', color: '#FFFFFF' }}
                >
                  Fill in onboarding to start <br />
                  using Pebbles Ai
                </Typography>
                <Typography
                  className={classes.description}
                  style={{ marginTop: '20px' }}
                // style={{ fontSize: '10px', fontWeight: '500', lineHeight: '15px', color: '#E2E8F0' }}
                >
                  Without onboarding, you won't be able to use the platform, <br />
                  as we want to ensure the best results for you.
                </Typography>
                <br />
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  className={classes.fillOnboarding}
                  startIcon={<EditWhiteIcon />}
                  onClick={() => history.push('/onboarding')}
                >
                  Fill in onboarding
                </Button>
              </Box>
            : <Box
                className={classes.inner}
                style={{ width: '235px' }}
              >
                <Typography className={classes.subHeading}>
                  HELLO!
                </Typography>

                <Typography className={classes.heading} component="h1">
                  Welcome to Pebbles Ai
                </Typography>

                <Typography className={classes.description}>
                  Congrats! You’re one of 1,000. Enjoy VIP priority access to new features.
                </Typography>
              </Box>
        }
      </Box>
    </>
  );
};

export default Header;
