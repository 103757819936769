import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  typesContainer: {
    height: '28px',
    backgroundColor: '#F5F5F5',
    padding: '8px 12px 8px 22px',
    borderRadius: 8,
    fontSize: '16px',
    lineHeight: '15px',
    color: '#50555E',
    display: 'inline-flex',
    position: 'relative',
    marginLeft: 8,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 11,
      left: 12,
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: '#C4C4C4',
    }
  },
  typeMarketing: {
    '&:before': {
      backgroundColor: '#58BE70',
    }
  },
  typeSales: {
    '&:before': {
      backgroundColor: '#d93855',
    }
  },
  typeProduct: {
    '&:before': {
      backgroundColor: '#3a84c9',
    }
  }
}));