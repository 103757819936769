import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF'
  },
  container: {
    width: '100%',
    // maxWidth: '1920px',
    height: '100vh',
    background: 'white',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflowY: 'auto'
  },
  header: {
    width: '100%',
    minWidth: '880px',
    padding: '12px 32px 12px 20px',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    background: '#FAFAFA',
    position: 'sticky',
    // gap: '10px',
    top: 0,
    left: 0,
    zIndex: 10,
  },
  innerHeader: {
    minWidth: '650px',
    minHeight: '66px',
    maxWidth: '1920px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    margin: '0 auto'
  },
  nameBlock: {
    width: '30%',
    minWidth: '30%',
    maxWidth: '100%',
    // minWidth: '20%',
    // maxWidth: '40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: 'fit-content',
    height: '44px',
    padding: '8px 12px',
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: 'fit-content',
    height: '44px',
    padding: '8px 12px',
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  tagDot: {
    width: '6px',
    height: '6px',
    borderRadius: '50%'
  },
  historyHeaderBottomRight: {
    width: '100%',
    minWidth: '880px',
    maxWidth: '1080px',
    display: 'flex',
    margin: '0 auto 40px auto',
    padding: '0 20px',
  },
  owner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px'
  },
  ownerAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    color: '#50555E',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '38px',
    fontWeight: 500
  },
  ownerInfo: {
    textAlign: 'left',
  },
  ownerTitle: {
    fontSize: '12px',
  },
  ownerName: {
    fontSize: '14px',
    color: '#262C37',
  },
}));
