import React, { useEffect, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as DotMenu } from '../../../../assets/icons/dot_menu.svg';
import { ReactComponent as MarketingHeaderIcon } from '../../../../assets/icons/marketing-type.svg';
import { ReactComponent as SalesHeaderIcon } from '../../../../assets/icons/sales-type.svg';
import { ReactComponent as ProductHeaderIcon } from '../../../../assets/icons/product-type.svg';
import { ReactComponent as GeneralHeaderIcon } from '../../../../assets/icons/general-type.svg';
import { ReactComponent as IntelligenceHeaderIcon } from '../../../../assets/icons/intelligence-type.svg';
import { useHistory, useParams } from "react-router-dom";
import { CAMPAIGN_TYPES } from "../../../../utils/enums";
import { isStartUpTeamsSubscription } from '../../../../helpers/roles';
import { AccountSubscriptionResponse } from '../../../../interfaces/subscriptions';
import { CampaignStatuses } from '../../../../interfaces/campaign';
import { DateTime } from "luxon";
import { notify } from '../../../../providers/notification';
import { ProfileResponse } from '../../../../interfaces/profile';

import { useStyles } from "./styles";

const PARAMS_TYPE = {
  EMAIL_CAMPAIGNS: 'email-outreach',
  INTELLIGENCE: 'copilots'
}

interface Props {
  element: any,
  subscription: AccountSubscriptionResponse,
  profile: ProfileResponse,
  actions: { key: string, label: string, isDelete?: boolean }[],
  onAction: (body: { element: any, type: string }) => void;
}

const menuStyles = {
  marginTop: '36px',
  marginLeft: '3px',
  borderRadius: '8px',
  border: '1px solid #E9EAEB',
  padding: '8px',
}

const menuItemStyles = {
  width: '184px',
  height: '40px',
  borderRadius: 8,
  padding: '4px 12px',
  fontSize: '16px',
  lineHeight: '16px',
}

const LibraryCard: React.FC<Props> = ({
  element,
  subscription,
  profile,
  actions,
  onAction
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [headerIcon, setHeaderIcon] = useState<any>(null);

  const params = useParams<{ tab: string }>();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    findHeaderIcon()
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const findHeaderIcon = () => {
    if (CAMPAIGN_TYPES.some(c => c.value === element.campaignType)) {
      const campaign = CAMPAIGN_TYPES.find(c => c.value === element.campaignType);
      if (campaign.category === 'MARKETING') {
        setHeaderIcon(<MarketingHeaderIcon />);
      } else if (campaign.category === 'SALES') {
        setHeaderIcon(<SalesHeaderIcon />);
      } else {
        setHeaderIcon(<ProductHeaderIcon />);
      }
    } else if (element.type === 'CHAT_INTELLIGENCE' || element.type === 'GENERAL' || element.type === 'INTELLIGENCE') {
      if (element.chatType === 'INTELLIGENCE' || element.type === 'INTELLIGENCE') {
        setHeaderIcon(<IntelligenceHeaderIcon />);
      } else {
        setHeaderIcon(<GeneralHeaderIcon />);
      }
    } else {
      setHeaderIcon(<MarketingHeaderIcon />);
    }
  }

  const handleRedirect = () => {
    if (params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS || element.type === 'CAMPAIGN') {
      if (element.emails.length) {
        if (element.status === CampaignStatuses.INCOMPLETE) {
          history.push(`/edit/campaign/builder?campaign_id=${element.id}`)
        } else {
          history.push(`${getMode()}/tactical-outreach/email?campaign_id=${element.id}`)
        }
      } else {
        if (element.createdBy === profile.user.uuid) {
          history.push(`/edit/campaign/builder?campaign_id=${element.id}`)
        } else {
          notify.info(`This campaign isn't completed yet and can't be opened`)
        }
      }
    } else if (params.tab === PARAMS_TYPE.INTELLIGENCE || element.type === 'CHAT_INTELLIGENCE') {
      const url = window.location.pathname.includes('/my-history')
        ? `${getMode()}/snippet/${element.id}`
        : window.location.pathname.includes('/library')
          ? `${getMode()}/snippet/${element.id}`
          : `${getMode()}/snippet/${element.id}`

      history.push(url);
    }
  }

  const getMode = () => {
    return isStartUpTeamsSubscription(subscription)
      ? '/view'
      : '/edit'
  }

  const getLastUpdatedText = (date) => {
    const now = DateTime.now();
    const updatedDate = DateTime.fromISO(date, { zone: 'utc' }).setZone();

    const isToday = now.hasSame(updatedDate, "day");
    const formattedTime = updatedDate.toFormat("H:mm");

    return `Last updated ${isToday ? "today" : updatedDate.toFormat("MMMM d")} at ${formattedTime}`;
  };

  return (
    <Box
      className={classes.cardContainer}
      onClick={handleRedirect}
    >
      <Box className={classes.cardTop}>
        <Box className={classes.cardTopAction}>
          <Typography className={classes.cardDate}>
            {getLastUpdatedText(element.updatedAt)}
          </Typography>
          {!!actions.length && (
            <>
              <IconButton id="basic-button"
                aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                className={`${classes.cardMenuButton} ${Boolean(anchorEl) ? classes.cardMenuButtonOpen : null}`}
                onClick={handleClick}
              >
                <DotMenu width={24} height={24} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-menu' }}
                PaperProps={{ style: menuStyles }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                onClick={e => e.stopPropagation()}
              >
                {actions.map((item, index) => (
                  <MenuItem
                    key={index}
                    style={{
                      ...menuItemStyles,
                      backgroundColor: item.isDelete ? '#FEF2F2' : '',
                      color: item.isDelete ? '#E94A4D' : '#262C37'
                    }}
                    onClick={(e) => {
                      e.stopPropagation();

                      handleClose();
                      onAction({ element, type: item.key });
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
        <Box className={classes.cardPreview}>
          <Box className={classes.cardPreviewHeader}>
            {headerIcon}
          </Box>
          <Box className={classes.cardPreviewContent}>
            <Typography className={classes.cardPreviewTitle}>
              {element?.name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.cardBottom}>
        <Box className={classes.cardBottomDescription}>
          {element?.description ? element.description : ' - '}
        </Box>
        <Box className={classes.cardBottomInfo}>
          {
            (element.type === 'CHAT_INTELLIGENCE' || element.type === 'GENERAL' || element.type === 'INTELLIGENCE') && (
              <Typography className={classes.cardBottomEmails}>
                Copilot: {(element.type === 'GENERAL' || element.chatType === 'GENERAL') ? 'Marketing' : 'Strategy'}
              </Typography>
            )
          }
          {'emails' in element && !!element?.emails?.length && (
            <Typography className={classes.cardBottomEmails}>
              {element.emails.length} {element.emails.length === 1 ? 'email' : 'emails'}
            </Typography>
          )}
          {
            element?.owner && (
              <Tooltip
                arrow
                title={element?.owner?.name}
                placement="top-end"
              >
                <Box className={classes.cardBottomCreator}>
                  {element?.owner?.name?.slice(0, 1)}
                </Box>
              </Tooltip>
            )
          }
        </Box>
      </Box>
    </Box>
  );
};

export default LibraryCard;
