import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: "750px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "16px",
    background: "#FFFFFF",
    boxSizing: "border-box"
  },
  closeButton: {
    position: "absolute",
    right: "20px",
    top: "15px"
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    borderBottom: "1px solid #E9EAEB",
    padding: "20px 70px 20px 32px"
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
    gap: "16px"
  },
  tabs: {
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "4px",
    background: "#F5F5F5",
    borderRadius: "8px",
    gap: "2px",
    userSelect: 'none'
  },
  tabItem: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    background: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#262C37",
    cursor: "pointer",
    transition: "all .3s",
    "&:hover": {
      background: "#FFFFFF80"
    }
  },
  tabItemActive: {
    transition: "all .3s",
    background: "#FFFFFF !important"
  },
  footer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTop: "1px solid #E9EAEB",
    padding: "20px 32px",
    gap: "12px"
  },
  editor: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: "Objectivity",
    position: "relative",
    width: "100%",
    height: "90%",
    overflow: "hidden",
    padding: "12px",
    border: "1px solid #E9EAEB",
    borderRadius: "8px",
    gap: "10px",
    background: "#FAFAFA",
    "& .MuiFormControl-root": {
      height: "100% !important"
    },
    "& .MuiFormControlLabel-root": {
      height: "100% !important"
    },
    "& .MuiTextField-root": {
      height: "100% !important"
    },
    "& .MuiInputBase-multiline": {
      height: "fit-content !important"
    }
  },
  editorWrapper: {
    height: "88%",
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  editorToolbar: {
    width: "96%",
    margin: "0px !important",
    padding: "8px !important",
    border: "none !important",
    background: "#F5F5F5 !important",
    borderRadius: "8px !important",
    flexWrap: "nowrap",
    overflow: "visible",
    "& .rdw-block-wrapper": {
      borderRight: "1px solid #CBD5E1",
      paddingRight: "4px",
      margin: "0px",
      flexWrap: "nowrap",
      "& .rdw-dropdown-selectedtext": {
        color: "#475569",
        fontWeight: "bold",
        "& .rdw-dropdown-carettoopen": {
          borderTopColor: "#475569",
          transform: "translate(0, 50%)"
        }
      }
    },
    "& .rdw-inline-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-list-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-block-dropdown": {
      width: "130px",
      flexWrap: "nowrap"
    },
    "& .rdw-text-align-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-link-wrapper": {
      margin: "0px",
      flexWrap: "nowrap",
      "& .rdw-link-modal": {
        transform: "translate(-85%, 0px)",
        "@media (max-width: 1347px)": {
          transform: "translate(0%, 0px)"
        }
      }
    },
    "& .rdw-fontsize-wrapper": {
      borderRight: "1px solid #CBD5E1",
      padding: "0px 4px",
      flexWrap: "nowrap"
    },
    "& .rdw-remove-wrapper": {
      padding: "0px 4px",
      margin: "0px",
      flexWrap: "nowrap"
    }
  },
  editorTextarea: {
    // height: 'calc(100% - 100px) !important',
    height: "100%",
    minHeight: "300px",
    maxHeight: "300px",
    paddingRight: "10px",
    cursor: "text",
    "& .DraftEditor-root": {
      height: "100%",
      "& .DraftEditor-editorContainer": {
        height: "100%",
        "& .public-DraftStyleDefault-pre": {
          margin: "0px !important"
        },
        "& .public-DraftStyleDefault-block": {
          margin: "0px !important"
        },
        "& .rdw-link-decorator-wrapper": {
          "& .rdw-link-decorator-icon": {
            // display: 'none'
            top: "-15px"
          }
        },
        '& .rdw-left-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'left !important'
          }
        },
        '& .rdw-center-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'center !important'
          }
        },
        '& .rdw-right-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'right !important'
          }
        },
        "& h1": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h2": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h3": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h4": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h5": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h6": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& ul": {
          margin: "0px !important"
        },
        "& ol": {
          margin: "0px !important"
        }
      }
    }
  },
  toolbarGroup: {
    borderRight: "1px solid #CBD5E1",
    padding: "0px 4px"
  },
  toolbarButton: {
    background: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    borderRadius: "6px !important",
    margin: "0 1px !important",
    "&:hover": {
      background: "#CBD5E14D !important"
    },
    "&.rdw-option-active": {
      background: "#CBD5E1 !important"
    },
    "&.rdw-option-disabled": {
      background: "transparent !important"
    }
  }
}));
