import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../../components/main/CustomFields/TextField";
import { sendResetPasswordLink } from "../../../../redux/services/authentication";
import { notify } from "../../../../providers/notification";

import { useStyles } from "./styles";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [targetEmail, setTargetEmail] = useState<string>('');
  const [timeLeft, setTimeLeft] = useState(0);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (timeLeft && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId); // Clean up the interval on component unmount
    }
  }, [targetEmail, timeLeft]);

  const handleSendRcoveryLink = async (email: string) => {
    setLoading(true);

    const { error } = await sendResetPasswordLink({ email });

    if (!error) {
      setTimeLeft(120);
      setTargetEmail(email);

      notify.success('Recovery link has been sent on your email address');
    } else {
      notify.error(error?.data?.message || 'Account not found or invalid email');
    }

    setLoading(false);
  }

  const handleLogin = () => {
    history.push('/login');
  }

  const formatTimer = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.FormContainerStyle}>
        <Box className={classes.forgotPwdForm}>
          <Typography
            align="center"
            className={classes.forgotPawdTitle}
            variant="h6"
          >
            {targetEmail
              ? 'Password recovery'
              : 'Forgot password?'
            }
          </Typography>
          {!targetEmail && (
            <Typography
              align="center"
              className={classes.forgotPwdSubtitle}
              variant="h6"
            >
              No worries, we'll send a recovery link to your email.
            </Typography>
          )}

          {targetEmail && (
            <>
              <Typography style={{ marginTop: '29px', marginBottom: '8px', textAlign: 'center', fontSize: '16px', fontWeight: '700', lineHeight: '19px' }}>
                Check your email
              </Typography>

              <Typography style={{ marginBottom: '32px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: '#475569' }}>
                We have sent a password reset link to {targetEmail}
              </Typography>

              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={timeLeft !== 0}
                onClick={() => handleSendRcoveryLink(targetEmail)}
              >
                Resend
              </Button>

              {timeLeft !== 0 && (
                <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: '#475569' }}>
                  Will be active in &nbsp;
                  <Typography style={{ fontSize: '20px', fontWeight: '500', lineHeight: '30px', color: '#475569' }}>
                    {formatTimer(timeLeft)}
                  </Typography>
                </Typography>
              )}
            </>
          )}

          {!targetEmail && (
            <>
              <Formik
                data-testid="EmailFormik"
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object({
                  email: Yup.string()
                    .email("Please enter a valid email.")
                    .required("Email should not be blank."),
                })}
                onSubmit={(values) => handleSendRcoveryLink(values.email)}
                validateOnChange={false}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange
                }) => (
                  <Form
                    translate={undefined}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '32px',
                      marginTop: '32px'
                    }}
                  >
                    <TextField
                      name={'email'}
                      value={values['email']}
                      label={'Email'}
                      placeholder={'Provide an email address'}
                      error={touched['email'] && errors['email']}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                    />
                    <Box>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        type="submit"
                      >
                        Send a recovery link
                      </Button>
                      <Typography
                        align="center"
                        className={classes.forgotPwdSubtitle}
                        variant="h6"
                      >
                        <br />
                        Already have an account?{" "}
                        <Link
                          className={classes.link}
                          onClick={() => handleLogin()}
                        >
                          Log in
                        </Link>
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
