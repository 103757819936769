import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from "lodash";
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as EmailOutlinedIcon } from '../../../assets/icons/email_outlined.svg';
import { ReactComponent as LinkedinOutlinedIcon } from '../../../assets/icons/linkedin_outlined.svg';
import { ReactComponent as ExecutionCopilotOutlinedIcon } from '../../../assets/icons/execution_copilot_outlined.svg';
import { ReactComponent as StrategyCopilotOutlinedIcon } from '../../../assets/icons/strategy_copilot_outlined.svg';
import { DateTime } from 'luxon';
import ElementRow from '../components/ElementRow';
import EmptyState from '../components/EmptyState';
import { createFilteredRequestParams } from "../../../helpers/requests";
import { notify } from "../../../providers/notification";
import { HistoryParams } from "../../../interfaces/history";
import Tabs from '../components/Tabs';
import ConfirmationModal from '../components/ConfirmationModal';
import RenameModal from "../components/RenameModal";
import TextField from "../../../components/main/CustomFields/TextField";
import TypesDropdown from "../components/TypesDropdown";
import StatusDropdown from "../components/StatusDropdown";
import CustomDateRangePicker from "../components/DateRangePicker";
import FilterChips from "../components/FilterChips";
import { CAMPAIGN_TYPES, REVIEW_STATUS } from "../../../utils/enums";
import { ReactComponent as Restore } from '../../../assets/icons/restore.svg';
import { deleteReviewLogCampaign, deleteReviewLogSnippet, duplicateReviewLogCampaign, duplicateReviewLogSnippet, getReviewLogCampaigns, getReviewLogSnippets, renameReviewLogCampaign, renameReviewLogSnippet } from '../../../redux/services/review-log';
import { isStartUpTeamsSubscription } from '../../../helpers/roles';
import CreateByDropdown from "../components/CreateByDropdown";
import { getUsers } from "../../../redux/services/my-history";
import useGetSubscription from '../../../hooks/useGetSubscription';
import useGetProfile from '../../../hooks/useGetProfile';

import { useStyles } from './styles';
import { getUrlAndParams, removeAllUrlParams } from '../../../helpers/other';

const TABS = [
  {
    key: 'EMAIL_CAMPAIGNS',
    title: 'Email Campaigns',
    icon: <EmailOutlinedIcon />,
  },
  {
    key: 'LINKEDIN_CAMPAIGNS',
    title: 'LinkedIn Campaigns',
    icon: <LinkedinOutlinedIcon />
  },
  {
    key: 'COPILOT_EXECUTION',
    title: 'Copilot: Marketing',
    icon: <ExecutionCopilotOutlinedIcon />
  },
  {
    key: 'COPILOT_STRATEGY',
    title: 'Copilot: Strategy',
    icon: <StrategyCopilotOutlinedIcon />
  },
];

export const STATUSES = [
  { value: REVIEW_STATUS.PENDING_REVIEW, label: "Pending review", color: "#e49b42", bgColor: "#fdf3e7", checked: false },
  { value: REVIEW_STATUS.CHANGE_REQUIRED, label: "Changes required", color: "#d63384", bgColor: "#fce8f2", checked: false },
];

const COPILOT_TYPE = {
  COPILOT_EXECUTION: 'GENERAL',
  COPILOT_STRATEGY: 'INTELLIGENCE'
}

const DEFAULT_FILTERS = {
  pageNumber: 0,
  pageSize: 10,
  createdBy: [],
  searchQuery: '',
  types: '',
  reviewStatuses: [],
  updatedAtStart: '',
  updatedAtEnd: '',
  createdAtStart: '',
  createdAtEnd: ''
}

const ReviewLog = () => {
  const [tab, setTab] = useState<string>(TABS[0].key);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRequestEnd, setIsRequestEnd] = useState<boolean>(false);
  const [paramsConfig, setParamsConfig] = useState(DEFAULT_FILTERS);
  const [groupedData, setGroupedData] = useState({ thisWeek: [], lastWeek: [], older: [] });
  const [elementToDelete, setElementToDelete] = useState<boolean | null>(null);
  const [elementToRename, setElementToRename] = useState<boolean | null>(null);
  const [currentActionElement, setCurrentActionElement] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);

  // filters
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dateUpdated, setDateUpdated] = useState<[Date, Date]>([null, null]);
  const [dateCreated, setDateCreated] = useState<[Date, Date]>([null, null]);
  const [filterStatus, setFilterStatus] = useState<any[]>(STATUSES);
  const [filterTypes, setFilterTypes] = useState<any[]>(CAMPAIGN_TYPES);
  const [isResetFilters, setIsResetFilters] = useState<boolean>(false);
  const [filterUsers, setFilterUsers] = useState<any[]>([]);

  const classes = useStyles();
  const hasMore = useRef(true);
  const isFirstRender = useRef(true);
  const historyRef = useRef(null);

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    const { params } = getUrlAndParams();
    const tab = params.get('tab');

    if (TABS.find(item => item.key === tab)) {
      setTab(tab);

      setTimeout(() => {
        removeAllUrlParams(true);
      }, 500);
    } else {
      setTab(TABS[0].key);
    }
  }, []);

  useEffect(() => {
    if (profile && subscription) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      hasMore.current = true;
      if (tab !== 'LINKEDIN_CAMPAIGNS') {
        handleGetHistory();
      } else {
        setGroupedData({ thisWeek: [], lastWeek: [], older: [] })
      }
    }
  }, [paramsConfig, profile, subscription]);

  useEffect(() => {
    setParamsConfig(setFiltersParams());
    checkIsShowIsResetFilter();
  }, [dateUpdated, dateCreated, filterStatus, filterTypes, filterUsers]);

  useEffect(() => {
    setGroupedData({ thisWeek: [], lastWeek: [], older: [] })
    setParamsConfig(prevState => {
      return {
        ...prevState,
        pageNumber: 0,
        types: tab !== 'EMAIL_CAMPAIGNS' ? COPILOT_TYPE[tab] : ''
      }
    });
    clearAllFilters();
  }, [tab]);

  useEffect(() => {
    handleGetUsers();
  }, []);

  const handleGetUsers = async () => {
    const { data, error } = await getUsers();
    if (data && !error) {
      const users = data.map(user => {
        return {
          ...user,
          label: user.name,
          checked: false
        }
      })
      setFilterUsers(users)
    }
  }

  const handleGetHistory = async () => {
    if (!hasMore.current) { return }
    hasMore.current = false;

    setLoading(true);
    setIsRequestEnd(false);

    const filteredParams = createFilteredRequestParams({
      pageSize: 10,
      ...paramsConfig,
      types: tab === 'EMAIL_CAMPAIGNS' ? filterTypes.filter(s => s.checked).map(s => s.value) : COPILOT_TYPE[tab],
    });
    const fetchHistory = tab === 'EMAIL_CAMPAIGNS' ? getReviewLogCampaigns : getReviewLogSnippets;

    const { data, error } = await fetchHistory(filteredParams as HistoryParams);

    if (data && !error) {
      const newHistory = data.content;
      setTotalPages(data.totalPages);
      if (newHistory.length === 0) {
        hasMore.current = false;
        if (paramsConfig.pageNumber === 0) {
          setGroupedData({ thisWeek: [], lastWeek: [], older: [] });
        }
      } else {
        hasMore.current = true;
        const updatedData = groupByDate(newHistory);
        if (!paramsConfig.pageNumber) {
          setGroupedData(updatedData);
        }
        else {
          setGroupedData((prevGroupedData) => {
            return {
              thisWeek: filterByUniqueId([...prevGroupedData.thisWeek, ...updatedData.thisWeek]),
              lastWeek: filterByUniqueId([...prevGroupedData.lastWeek, ...updatedData.lastWeek]),
              older: filterByUniqueId([...prevGroupedData.older, ...updatedData.older]),
            };
          });
        }
      }
    }

    setLoading(false);
    setIsRequestEnd(true);
  };

  const filterByUniqueId = (data: any[]) => {
    return Array.from(new Map(data.map(item => [item.id, item])).values())
  }

  const handleAction = (event: { element, type: string }) => {
    if (event.type === 'DELETE') {
      setElementToDelete(true);
      setCurrentActionElement(event.element);
    } else if (event.type === 'DUPLICATE') {
      handleDuplicateElementHistory(event.element.id);
    } else if (event.type === 'RENAME') {
      setElementToRename(true);
      setCurrentActionElement(event.element);
    }
  }

  const handleDeleteElement = async () => {
    setLoading(true);

    const fetchDeleteHistory = tab === 'EMAIL_CAMPAIGNS' ? deleteReviewLogCampaign : deleteReviewLogSnippet;
    const { error } = await fetchDeleteHistory(currentActionElement.id);

    if (!error) {
      notify.success('Deleted successfully');
      scrollToTop();
    } else {
      notify.error('Failed to delete');
      setLoading(false);
    }

    setLoading(false);
  }

  const handleDuplicateElementHistory = async (id: string) => {
    setLoading(true);

    const fetchDuplicateHistory = tab === 'EMAIL_CAMPAIGNS' ? duplicateReviewLogCampaign : duplicateReviewLogSnippet;
    const { error } = await fetchDuplicateHistory(id);

    if (!error) {
      notify.success('Duplicated successfully');
      scrollToTop();
    } else {
      notify.error('Failed to duplicate');
      setLoading(false);
    }

    setLoading(false);
  };

  const handleRenameElementHistory = async (value) => {
    setLoading(true);

    const fetchDuplicateHistory = tab === 'EMAIL_CAMPAIGNS' ? renameReviewLogCampaign : renameReviewLogSnippet;
    const { error } = await fetchDuplicateHistory({
      body: {
        name: value.name,
        description: value.description
      },
      id: currentActionElement.id
    } as any);

    if (!error) {
      notify.success('Saved successfully');
      scrollToTop();
    } else {
      setLoading(false);

      notify.error('Failed to rename');
    }

    setLoading(false);
  };

  const groupByDate = (items) => {
    const now = DateTime.now();
    const startOfThisWeek = now.startOf('week').plus({ days: 1 }); // Monday start
    const startOfLastWeek = startOfThisWeek.minus({ weeks: 1 });

    return items.reduce(
      (acc, item) => {
        const updatedAt = DateTime.fromISO(item.updatedAt, { zone: 'utc' }).setZone();

        if (updatedAt > startOfThisWeek) {
          acc.thisWeek.push(item);
        } else if (updatedAt > startOfLastWeek && updatedAt < startOfThisWeek) {
          acc.lastWeek.push(item);
        } else {
          acc.older.push(item);
        }

        return acc;
      },
      { thisWeek: [], lastWeek: [], older: [] }
    );
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 50;

    if (isBottom && isRequestEnd && hasMore.current && paramsConfig.pageNumber + 1 < totalPages) {
      setLoading(true);
      setParamsConfig(prevState => {
        return {
          ...prevState,
          pageNumber: prevState.pageNumber + 1
        }
      })
    }
  }

  const scrollToTop = () => {
    if (historyRef?.current) {
      if (historyRef.current) {
        historyRef.current.scrollTo({
          top: 0,
        });
      }
      setTimeout(() => {
        setParamsConfig((prevState) => ({
          ...prevState,
          pageNumber: 0
        }));
      }, 100);
    }
  };

  // filters action
  const getStringDate = (date: [Date, Date]) => {
    const [start, end] = date;
    if (!start || !end) return '';

    const startDate = DateTime.fromJSDate(start);
    const endDate = DateTime.fromJSDate(end);

    return `${startDate.toFormat('dd MMM')} - ${endDate.toFormat('dd MMM')}`;
  };

  const clearFilterStatus = () => {
    const newFilterStatus = filterStatus.map(s => {
      return { ...s, checked: false }
    });
    setFilterStatus(newFilterStatus);
  }

  const clearFilterTypes = () => {
    const newFilterTypes = filterTypes.map(s => {
      return { ...s, checked: false }
    });
    setFilterTypes(newFilterTypes);
  }

  const clearFilterUsers = () => {
    const newFilterUsers = filterUsers.map(u => {
      return { ...u, checked: false }
    });
    setFilterUsers(newFilterUsers);
  }

  const clearAllFilters = () => {
    setSearchTerm('');
    setDateUpdated([null, null]);
    setDateCreated([null, null]);
    setFilterStatus([...STATUSES]);
    setFilterTypes([...CAMPAIGN_TYPES]);
    setFilterUsers(filterUsers.map(user => { return { ...user, checked: false } }));
    setIsResetFilters(false);
  }

  const checkIsShowIsResetFilter = () => {
    if (searchTerm.length ||
      dateUpdated.filter(v => v).length ||
      dateCreated.filter(v => v).length ||
      filterStatus.filter(s => s.checked).length ||
      filterUsers.filter(u => u.checked).length ||
      filterTypes.filter(s => s.checked).length) {
      setIsResetFilters(true);
    } else {
      setIsResetFilters(false);
    }
  }

  const setFiltersParams = () => {
    return {
      pageNumber: 0,
      pageSize: 10,
      createdBy: filterUsers.filter(u => u.checked).map(u => u.uuid),
      searchQuery: encodeURIComponent(searchTerm),
      types: tab === 'EMAIL_CAMPAIGNS' ? filterTypes.filter(s => s.checked).map(s => s.value) : COPILOT_TYPE[tab],
      reviewStatuses: filterStatus.filter(s => s.checked).map(s => s.value),
      updatedAtStart: setDateFormat(dateUpdated, 0),
      updatedAtEnd: setDateFormat(dateUpdated, 1),
      createdAtStart: setDateFormat(dateCreated, 0),
      createdAtEnd: setDateFormat(dateCreated, 1),
    }
  }

  const setDateFormat = (dates: any[], position: number) => {
    if (dates.filter(d => d).length) {
      const date = new Date(dates[position]).toISOString();

      return DateTime.fromISO(date, { zone: 'utc' }).toFormat('yyyy-MM-dd');
    } else {
      return '';
    }
  };

  const compareActions = (element) => {
    if (isStartUpTeamsSubscription(subscription)) {
      let actions = [];

      return actions;
    } else {
      return [
        { key: 'RENAME', label: 'Rename' },
        { key: 'DUPLICATE', label: 'Duplicate' },
        { key: 'DELETE', label: 'Delete', isDelete: true },
      ]
    }
  }

  const handleDebounceSearch = useCallback(
    debounce((searchTerm) => {
      setParamsConfig({
        ...setFiltersParams(),
        searchQuery: encodeURIComponent(searchTerm),
      });
      checkIsShowIsResetFilter();
    }, 500),
    []
  );

  return (
    <>
      <Backdrop
        style={loading ? { color: '#fff', zIndex: 99999 } : {}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Typography style={{ fontSize: '40px', fontWeight: '700', lineHeight: '48px', color: '#262C37' }}>
              Review Log
            </Typography>
            <Tabs
              tabs={TABS}
              currentTab={tab}
              onTabChange={setTab}
            />
            {tab !== 'LINKEDIN_CAMPAIGNS'
              ? <Box className={classes.filterWrapper}>
                <Box className={classes.filterContainer}>
                  <Box className={classes.filtersList}>
                    <Box className={classes.filtersSearch}>
                      <TextField
                        type={'search'}
                        placeholder={'Search by name or description'}
                        value={searchTerm}
                        showClear
                        onChange={(event) => {
                          const value = event.target.value;

                          setSearchTerm(value);
                          handleDebounceSearch(value);
                        }}
                        onSearchPress={() => { }}
                        onKeyPress={() => { }}
                      />
                    </Box>
                    {tab === 'EMAIL_CAMPAIGNS' && (
                      <TypesDropdown
                        value={filterTypes}
                        onChange={(value) => {
                          setFilterTypes(value)
                        }}
                      />
                    )}
                    <StatusDropdown
                      value={filterStatus}
                      onChange={(value) => {
                        setFilterStatus(value)
                      }}
                    />
                    {isStartUpTeamsSubscription(subscription) && filterUsers.length > 0 && (
                      <CreateByDropdown
                        value={filterUsers}
                        onChange={(value) => {
                          setFilterUsers(value)
                        }} />
                    )}
                    <CustomDateRangePicker buttonName={'Date updated'} value={dateUpdated} onChange={(v) => setDateUpdated(v)}></CustomDateRangePicker>
                    <CustomDateRangePicker buttonName={'Date created'} value={dateCreated} onChange={(v) => setDateCreated(v)}></CustomDateRangePicker>
                    {isResetFilters && (
                      <Button onClick={clearAllFilters}
                        className={classes.resetButton}
                        variant='text'
                        startIcon={<Restore width={17} height={17} />}>
                        Reset
                      </Button>
                    )}
                  </Box>
                  <Box className={classes.filtersList}>
                    {filterTypes.filter(s => s.checked).length > 0 && (
                      <FilterChips title={'Type'}
                        value={filterTypes}
                        handleClose={clearFilterTypes}
                        isSingleValue={false}
                      />
                    )}
                    {filterStatus.filter(s => s.checked).length > 0 && (
                      <FilterChips title={'Status'}
                        value={filterStatus}
                        handleClose={clearFilterStatus}
                        isSingleValue={false}
                      />
                    )}
                    {filterUsers.filter(s => s.checked).length > 0 && (
                      <FilterChips title={'Created By'}
                        value={filterUsers}
                        handleClose={clearFilterUsers}
                        isSingleValue={false}
                      />
                    )}
                    {dateUpdated.filter(v => !!v).length > 0 && (
                      <FilterChips title={'Date updated'}
                        value={getStringDate(dateUpdated)}
                        handleClose={() => setDateUpdated([null, null])}
                        isSingleValue={true}
                      />
                    )}
                    {dateCreated.filter(v => !!v).length > 0 && (
                      <FilterChips title={'Date created'}
                        value={getStringDate(dateCreated)}
                        handleClose={() => setDateCreated([null, null])}
                        isSingleValue={true}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              : null
            }
          </Box>
          {tab !== 'LINKEDIN_CAMPAIGNS'
            ? <Box className={classes.content} onScroll={handleScroll}>
              {Object.entries(groupedData).map(([key, items]) => (
                items && items.length > 0 && (
                  <Box key={key} className={classes.groupContainer}>
                    <Box className={classes.groupTitleWrapper}>
                      <Typography className={classes.groupTitle}>
                        {key === "thisWeek" ? "This Week" : key === "lastWeek" ? "Last Week" : "Older"}
                      </Typography>
                    </Box>
                    <Box className={classes.elements}>
                      {items.map((item) => (
                        <ElementRow
                          key={item.id}
                          element={item}
                          viewType={tab}
                          page={'REVIEW'}
                          actions={compareActions(item)}
                          onAction={handleAction}
                        />
                      ))}
                    </Box>
                  </Box>
                )
              ))}
              {isRequestEnd && isResetFilters && Object.values(groupedData).every(v => !v.length) && (
                <EmptyState type={'NO_FILTERS_DATA'} minHeight={'calc(100svh - 415px)'} />
              )}
              {isRequestEnd && !isResetFilters && Object.values(groupedData).every(v => !v.length) && (
                <EmptyState type={'NO_ITEMS'} minHeight={'calc(100svh - 415px)'} />
              )}
            </Box>
            : <EmptyState type={'COMING_SOON'} />
          }
        </Box>
      </Box>

      <ConfirmationModal
        open={!!elementToDelete}
        title={tab === 'EMAIL_CAMPAIGNS'
          ? 'Delete Campaign'
          : 'Delete Snippet'
        }
        description={currentActionElement?.name}
        rejectBtnText={'Close'}
        confirmBtnText={'Delete'}
        onClose={() => setElementToDelete(null)}
        onReject={() => setElementToDelete(null)}
        onConfirm={() => {
          setElementToDelete(null)
          handleDeleteElement()
        }}
      />

      <RenameModal
        open={!!elementToRename}
        title={tab === 'EMAIL_CAMPAIGNS'
          ? 'Rename Campaign'
          : 'Rename Snippet'
        }
        element={currentActionElement}
        rejectBtnText={'Close'}
        confirmBtnText={'Rename'}
        onClose={() => setElementToRename(null)}
        onConfirm={(value) => {
          setElementToRename(null);
          handleRenameElementHistory(value);
        }}
      />
    </>
  );
};

export default ReviewLog;
