import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  group: {
    display: "flex",
    height: "100%",
  },
  paper: {
    height: "100%",
    minHeight: '160px',
    display: "flex",
    flexDirection: "column",
    gap: '32px',
    cursor: 'pointer',
    position: "relative",
    border: '1px solid #E9EAEB',
    borderRadius: '24px',
    background: '#FFFFFFCC',
    padding: '20px',
    boxShadow: 'none'
  },
  paperHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  image: {
    width: "auto",
    height: 82,
    position: "absolute",
    top: 0,
    right: 0,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "19.2px",
    color: '#262C37',
    userSelect: 'none'
  },
  description: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "14.4px",
    color: '#50555E',
    userSelect: 'none'
  },
}));
