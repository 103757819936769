import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideBarItem: {
    width: '100%',
    // minWidth: '218px',
    // maxWidth: '218px',
    height: 'fit-content',
    minHeight: 'fit-content',
    maxHeight: 'fit-content',
    background: '#FFFFFFB2',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    borderRadius: '8px',
    padding: '12px 16px',
    cursor: 'pointer',
    overflow: 'hidden',
    userSelect: 'none'
  },
  sideBarItemActive: {
    background: '#FFFFFF',
    boxShadow: '-2px 0 0 0 #0F7F75 !important'
  },
  sideBarItemContent: {
    maxHeight: '43px',
    fontSize: '12px',
    fontWeight: 500, 
    lineHeight: '14px', 
    color: '#475569',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  }
}));
