import { api, createApiCall } from "../api";
import {
  CampaignHistoryResponse,
  ChatIntelligenceDto,
  CopilotHistoryResponse,
  HistoryParams
} from "../../interfaces/history";

const myHistoryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getCopilotHistory: builder.query<CopilotHistoryResponse, HistoryParams>({
      query: (params) => ({
        url: `/v1/library/chat-intelligence/history/multi-user`,
        method: "GET",
        params
      })
    }),
    deleteCopilotHistory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/library/chat-intelligence/${id}/multi-user`,
        method: "DELETE"
      })
    }),
    duplicateCopilotHistory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/library/chat-intelligence/${id}/duplicate/multi-user`,
        method: "PUT"
      })
    }),
    renameCopilotHistory: builder.mutation<
      void,
      { body: Partial<ChatIntelligenceDto>, id: string, modificator: 'multi-user' | 'single-user' }
    >({
      query: ({ body, id, modificator }) => ({
        url: `/v1/library/chat-intelligence/rename/${id}/${modificator}`,
        method: "PUT",
        body
      })
    }),
    getCampaignHistory: builder.query<CampaignHistoryResponse, HistoryParams>({
      query: (params) => ({
        url: `/v1/library/campaign/history/multi-user`,
        method: "GET",
        params
      })
    }),
    deleteCampaignHistory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/library/campaign/history/${id}/multi-user`,
        method: "DELETE"
      })
    }),
    duplicateCampaignHistory: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/library/campaign/history/${id}/duplicate/multi-user`,
        method: "PUT"
      })
    }),
    renameCampaignHistory: builder.mutation<
      void,
      { body: ChatIntelligenceDto, id: string, modificator: 'multi-user' | 'single-user' }
    >({
      query: ({ body, id, modificator }) => ({
        url: `/v1/library/campaign/history/rename/${id}/${modificator}`,
        method: "PUT",
        body
      })
    }),
    getUsers: builder.query<{uuid: string, name: string, profilePhoto: string}[], void>({
      query: () => ({
        url: `/users/short`,
        method: "GET",
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getCopilotHistory = createApiCall<
  CopilotHistoryResponse,
  HistoryParams
>(myHistoryAPI.endpoints.getCopilotHistory);
export const deleteCopilotHistory = createApiCall<void, string>(
  myHistoryAPI.endpoints.deleteCopilotHistory
);
export const duplicateCopilotHistory = createApiCall<void, string>(
  myHistoryAPI.endpoints.duplicateCopilotHistory
);
export const renameCopilotHistory = createApiCall<
  void,
  { body: Partial<ChatIntelligenceDto>, id: string, modificator: 'multi-user' | 'single-user' }
>(myHistoryAPI.endpoints.renameCopilotHistory);
export const getCampaignHistory = createApiCall<
  CampaignHistoryResponse,
  HistoryParams
>(myHistoryAPI.endpoints.getCampaignHistory);
export const deleteCampaignHistory = createApiCall<void, string>(
  myHistoryAPI.endpoints.deleteCampaignHistory
);
export const duplicateCampaignHistory = createApiCall<void, string>(
  myHistoryAPI.endpoints.duplicateCampaignHistory
);
export const renameCampaignHistory = createApiCall<
  void,
  { body: Partial<ChatIntelligenceDto>, id: string, modificator: 'multi-user' | 'single-user' }
>(myHistoryAPI.endpoints.renameCampaignHistory);
export const getUsers = createApiCall<
    {uuid: string, name: string, profilePhoto: string}[],
    void
>(myHistoryAPI.endpoints.getUsers);