import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from "lodash";
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import TextField from "../../../components/main/CustomFields/TextField";
import LibraryCard from "../MyLibrary/LibraryCard";
import TypesDropdown from "../components/TypesDropdown";
import CustomDateRangePicker from "../components/DateRangePicker";
import FilterChips from "../components/FilterChips";
import {
  CAMPAIGN_TYPES,
  CAMPAIGN_TYPES_LABELS,
  INTELLIGENCE_TYPES,
  INTELLIGENCE_TYPES_LABELS
} from '../../../utils/enums';
import { ReactComponent as Restore } from '../../../assets/icons/restore.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/chevron-right.svg';
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router-dom";
import { createFilteredRequestParams } from "../../../helpers/requests";
import {
  deleteLibraryCampaignByLicenseType, deleteLibraryCopilotByLicenseType,
  duplicateLibraryCampaignByLicenseType,
  duplicateLibraryCopilotByLicenseType,
  getLibraryCampaignsByLicenseType,
  getLibraryCopilotByLicenseType, renameLibraryCampaignByLicenseType, renameLibraryCopilotByLicenseType
} from "../../../redux/services/my-library";
import { LibraryParams } from "../../../interfaces/campaign";
import { isStartUpTeamsSubscription, showViewForAdmin } from "../../../helpers/roles";
import CopilotTypesDropdown from "../components/CopilotTypesDropdown";
import { getUsers } from "../../../redux/services/my-history";
import CreateByDropdown from "../components/CreateByDropdown";
import { notify } from "../../../providers/notification";
import ConfirmationModal from "../components/ConfirmationModal";
import RenameModal from "../components/RenameModal";
import EmptyState from "../components/EmptyState";
import useGetSubscription from '../../../hooks/useGetSubscription';
import useGetProfile from '../../../hooks/useGetProfile';

import { useStyles } from "./styles";

const DEFAULT_FILTERS = {
  pageNumber: 0,
  pageSize: 8,
  searchQuery: '',
  types: [],
  updatedAtStart: '',
  updatedAtEnd: '',
}

const PARAMS_TYPE = {
  EMAIL_CAMPAIGNS: 'email-outreach',
  INTELLIGENCE: 'copilots'
}

const MyLibraryFolder = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isRequestEnd, setIsRequestEnd] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterTypes, setFilterTypes] = useState<any[]>([]);
  const [dateUpdated, setDateUpdated] = useState<[Date, Date]>([null, null]);
  const [filterUsers, setFilterUsers] = useState<any[]>([]);
  const [isResetFilters, setIsResetFilters] = useState<boolean>(false);
  const [paramsConfig, setParamsConfig] = useState(DEFAULT_FILTERS);
  const [elementToDelete, setElementToDelete] = useState<boolean | null>(null);
  const [elementToRename, setElementToRename] = useState<boolean | null>(null);
  const [currentActionElement, setCurrentActionElement] = useState<any | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [libraryList, setLibraryList] = useState<any[]>([]);

  const classes = useStyles();
  const hasMore = useRef(true);
  const isFirstRender = useRef(true);
  const history = useHistory();
  const params = useParams<{ tab: string }>();
  const libraryRef = useRef(null);

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    if (profile && subscription) {
      if (isStartUpTeamsSubscription(subscription)) {
        handleGetUsers();
      }
    }
  }, [profile, subscription]);

  useEffect(() => {
    if (params.tab && (params.tab !== PARAMS_TYPE.EMAIL_CAMPAIGNS && params.tab !== PARAMS_TYPE.INTELLIGENCE)) {
      history.push('/library');
    }
    if (params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS) {
      setFilterTypes(CAMPAIGN_TYPES)
    } else if (params.tab === PARAMS_TYPE.INTELLIGENCE) {
      setFilterTypes(INTELLIGENCE_TYPES)
    }
  }, [params]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (profile && subscription) {
      handleGetLibraryData();
    }
  }, [paramsConfig, profile, subscription]);

  useEffect(() => {
    setParamsConfig(setFiltersParams());
    checkIsShowIsResetFilter();
    hasMore.current = true;
  }, [dateUpdated, filterTypes, filterUsers]);

  const handleGetUsers = async () => {
    const { data, error } = await getUsers();
    if (data && !error) {
      const users = data.map(user => {
        return {
          ...user,
          label: user.name,
          checked: false
        }
      })
      setFilterUsers(users)
    }
  }

  const handleGetLibraryData = async () => {
    if (!hasMore.current) { return }
    hasMore.current = false;

    setLoading(true);
    setIsRequestEnd(false);

    const filteredParams = createFilteredRequestParams({
      pageSize: 8,
      ...paramsConfig
    });

    const fetchLibraryData = params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS ? getLibraryCampaignsByLicenseType : getLibraryCopilotByLicenseType;

    const license = isStartUpTeamsSubscription(subscription) ? 'multi-user' : 'single-user';

    const { data, error } = await fetchLibraryData({ params: filteredParams as LibraryParams, license: license });

    if (data && !error) {
      let libraryList = data.content;
      if (params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS) {
        libraryList = libraryList.map((library) => {
          return {
            ...library,
            license: license,
            campaignType: library.type
          }
        })
      }
      hasMore.current = libraryList.length !== 0;
      setTotalPages(data.totalPages);
      if (paramsConfig.pageNumber === 0) {
        setLibraryList(libraryList);
      } else {
        setLibraryList(prevState => {
          return [...prevState, ...libraryList];
        });
      }
    }

    setLoading(false);
    setIsRequestEnd(true);
  }

  const handleAction = (event: { element, type: string }) => {
    if (event.type === 'DELETE') {
      setElementToDelete(true);
      setCurrentActionElement(event.element);
    } else if (event.type === 'DUPLICATE') {
      handleDuplicateLibraryElement(event.element.id);
    } else if (event.type === 'RENAME') {
      setElementToRename(true);
      setCurrentActionElement(event.element);
    }
  }

  const handleDeleteLibraryElement = async () => {
    setLoading(true);

    const fetchDeleteHistory = params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS ? deleteLibraryCampaignByLicenseType : deleteLibraryCopilotByLicenseType;

    const license = isStartUpTeamsSubscription(subscription) ? 'multi-user' : 'single-user';

    const { error } = await fetchDeleteHistory({ id: currentActionElement.id, license });

    if (!error) {
      notify.success('Deleted successfully');
      scrollToTop();
    } else {
      notify.error('Failed to delete');
      setLoading(false);
    }

    setLoading(false);
  }

  const handleDuplicateLibraryElement = async (id: string) => {
    setLoading(true);

    const fetchDuplicateHistory = params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS ? duplicateLibraryCampaignByLicenseType : duplicateLibraryCopilotByLicenseType;

    const license = isStartUpTeamsSubscription(subscription) ? 'multi-user' : 'single-user';

    const { error } = await fetchDuplicateHistory({ id, license });

    if (!error) {
      notify.success('Duplicated successfully');
      if (!isStartUpTeamsSubscription(subscription)) {
        scrollToTop();
      }
    } else {
      notify.error('Failed to duplicate');
      setLoading(false);
    }

    setLoading(false);
  };

  const handleRenameLibraryElement = async (value) => {
    setLoading(true);

    const fetchDuplicateHistory = params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS ? renameLibraryCampaignByLicenseType : renameLibraryCopilotByLicenseType;

    const license = isStartUpTeamsSubscription(subscription) ? 'multi-user' : 'single-user';

    const { error } = await fetchDuplicateHistory({
      body: {
        name: value.name,
        description: value.description,
      },
      id: currentActionElement.id,
      license: license,
    } as any);

    if (!error) {
      notify.success('Renamed successfully');
      scrollToTop();
    } else {
      notify.error('Failed to rename');
    }

    setLoading(false);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    const isBottom = scrollTop + clientHeight >= scrollHeight - 50;

    if (isBottom && isRequestEnd && hasMore.current && paramsConfig.pageNumber + 1 < totalPages) {
      setLoading(true);
      setParamsConfig(prevState => {
        return {
          ...prevState,
          pageNumber: prevState.pageNumber + 1
        }
      })
    }
  }

  const scrollToTop = () => {
    if (libraryRef?.current) {
      if (libraryRef.current) {
        libraryRef.current.scrollTo({
          top: 0,
        });
      }
      setTimeout(() => {
        setParamsConfig((prevState) => ({
          ...prevState,
          pageNumber: 0
        }));
      }, 100);
    }
  };

  const getStringDate = (date: [Date, Date]) => {
    const [start, end] = date;
    if (!start || !end) return '';

    const startDate = DateTime.fromJSDate(start);
    const endDate = DateTime.fromJSDate(end);

    return `${startDate.toFormat('dd MMM')} - ${endDate.toFormat('dd MMM')}`;
  };

  const clearFilterTypes = () => {
    const newFilterTypes = filterTypes.map(s => {
      return { ...s, checked: false }
    });
    setFilterTypes(newFilterTypes);
  }

  const clearFilterUsers = () => {
    const newFilterUsers = filterUsers.map(u => {
      return { ...u, checked: false }
    });
    setFilterUsers(newFilterUsers);
  }

  const clearAllFilters = () => {
    setSearchTerm('');
    setDateUpdated([null, null]);
    setFilterTypes(params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS ? [...CAMPAIGN_TYPES] : [...INTELLIGENCE_TYPES]);
    setFilterUsers(filterUsers.map(user => { return { ...user, checked: false } }));
    setIsResetFilters(false);
  }

  const checkIsShowIsResetFilter = () => {
    if (searchTerm.length ||
      dateUpdated.filter(v => v).length ||
      filterUsers.filter(u => u.checked).length ||
      filterTypes.filter(s => s.checked).length) {
      setIsResetFilters(true);
    } else {
      setIsResetFilters(false);
    }
  }

  const setFiltersParams = () => {
    return {
      pageNumber: 0,
      pageSize: 8,
      searchQuery: encodeURIComponent(searchTerm),
      createdBy: filterUsers.filter(u => u.checked).map(u => u.uuid),
      types: filterTypes.filter(s => s.checked).map(s => s.value),
      updatedAtStart: setDateFormat(dateUpdated, 0),
      updatedAtEnd: setDateFormat(dateUpdated, 1),
    }
  }

  const setDateFormat = (dates: any[], position: number) => {
    if (dates.filter(d => d).length) {
      const date = new Date(dates[position]).toISOString();

      return DateTime.fromISO(date, { zone: 'utc' }).toFormat('yyyy-MM-dd');
    } else {
      return '';
    }
  };

  const compareActions = () => {
    if (isStartUpTeamsSubscription(subscription)) {
      if (showViewForAdmin(subscription)) {
        return [
          { key: 'RENAME', label: 'Rename' },
          { key: 'DUPLICATE', label: 'Duplicate' },
          { key: 'DELETE', label: 'Delete', isDelete: true },
        ]
      } else {
        return [
          { key: 'DUPLICATE', label: 'Duplicate' },
        ]
      }
    } else {
      return [
        { key: 'RENAME', label: 'Rename' },
        { key: 'DUPLICATE', label: 'Duplicate' },
        { key: 'DELETE', label: 'Delete', isDelete: true },
      ]
    }
  }

  const handleDebounceSearch = useCallback(
    debounce((searchTerm) => {
      setParamsConfig({
        ...setFiltersParams(),
        searchQuery: encodeURIComponent(searchTerm),
      });
      checkIsShowIsResetFilter();
    }, 500),
    []
  );

  return (
    <>
      <Backdrop
        style={loading ? { color: '#fff', zIndex: 99999 } : {}}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={classes.root} ref={libraryRef} onScroll={handleScroll}>
        <Box className={classes.container}>
          <Box className={classes.breadcrumbs}>
            <Typography
              className={classes.breadcrumbsRoot}
              onClick={() => history.push(`/library`)}
            >
              Library
            </Typography>
            <ArrowRight width={16} height={16} style={{ margin: '0 2px' }} />
            <Typography className={classes.breadcrumbsPage}>{params?.tab?.replace('-', ' ')}</Typography>
          </Box>
          <Box className={classes.header}>
            <Typography style={{ fontSize: '40px', fontWeight: '700', lineHeight: '48px', color: '#262C37' }}>
              {params?.tab?.replace('-', ' ')}
            </Typography>
          </Box>
          <Box className={classes.filterWrapper}>
            <Box className={classes.filterContainer}>
              <Box className={classes.filtersList}>
                <Box className={classes.filtersSearch}>
                  <TextField
                    type={'search'}
                    placeholder={'Search by name or description'}
                    value={searchTerm}
                    showClear
                    onChange={(event) => {
                      const value = event.target.value;

                      setSearchTerm(value);
                      handleDebounceSearch(value);
                    }}
                    onSearchPress={() => {
                    }}
                    onKeyPress={() => {
                    }}
                  />
                </Box>
                {params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS && (
                  <TypesDropdown
                    value={filterTypes}
                    onChange={(value) => {
                      setFilterTypes(value)
                    }}
                  />
                )}
                {params.tab === PARAMS_TYPE.INTELLIGENCE && (
                  <CopilotTypesDropdown
                    value={filterTypes}
                    onChange={(value) => {
                      setFilterTypes(value)
                    }}
                  />
                )}
                {isStartUpTeamsSubscription(subscription) && filterUsers.length > 0 && (
                  <CreateByDropdown
                    value={filterUsers}
                    onChange={(value) => {
                      setFilterUsers(value)
                    }}
                  />
                )}
                <CustomDateRangePicker
                  buttonName={'Last updated date'}
                  value={dateUpdated}
                  onChange={(v) => setDateUpdated(v)}
                />
                {isResetFilters && (
                  <Button onClick={clearAllFilters}
                    className={classes.resetButton}
                    variant='text'
                    startIcon={<Restore width={17} height={17} />}>
                    Reset
                  </Button>
                )}
              </Box>
              <Box className={classes.filtersList}>
                {filterTypes.filter(s => s.checked).length > 0 && (
                  <FilterChips
                    title={'Type'}
                    value={filterTypes}
                    handleClose={clearFilterTypes}
                    isSingleValue={false}
                  />
                )}
                {filterUsers.filter(s => s.checked).length > 0 && (
                  <FilterChips
                    title={'Created By'}
                    value={filterUsers}
                    handleClose={clearFilterUsers}
                    isSingleValue={false}
                  />
                )}
                {dateUpdated.filter(v => !!v).length > 0 && (
                  <FilterChips
                    title={'Date updated'}
                    value={getStringDate(dateUpdated)}
                    handleClose={() => setDateUpdated([null, null])}
                    isSingleValue={true}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.labels}>
            {params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS && CAMPAIGN_TYPES_LABELS.map((label, index) => (
              <Box className={classes.labelsElement} key={index}>
                <Box className={classes.labelsElementCircle} style={{ backgroundColor: label.color }} />
                <Typography className={classes.labelsElementName}>
                  {label.name}
                </Typography>
              </Box>
            ))}
            {params.tab === PARAMS_TYPE.INTELLIGENCE && INTELLIGENCE_TYPES_LABELS.map((label, index) => (
              <Box className={classes.labelsElement} key={index}>
                <Box className={classes.labelsElementCircle} style={{ backgroundColor: label.color }} />
                <Typography className={classes.labelsElementName}>
                  {label.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box className={classes.folders}>
            <Box className={classes.foldersContainer}>
              {libraryList.length
                ? libraryList.map((library) => (
                  <LibraryCard
                    key={library.id}
                    element={library}
                    subscription={subscription}
                    profile={profile}
                    actions={compareActions()}
                    onAction={handleAction}
                  />
                ))
                : null
              }
              {!libraryList.length && isResetFilters && isRequestEnd
                ? <EmptyState type={'NO_FILTERS_DATA'} minHeight={'calc(100svh - 445px)'} />
                : null
              }
              {!libraryList.length && !isResetFilters && isRequestEnd
                ? <EmptyState type={'NO_ITEMS'} minHeight={'calc(100svh - 365px)'} />
                : null
              }
            </Box>
          </Box>
        </Box>
      </div>

      <ConfirmationModal
        open={!!elementToDelete}
        title={params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS
          ? 'Delete Campaign'
          : 'Delete Snippet'
        }
        description={currentActionElement?.name}
        rejectBtnText={'Close'}
        confirmBtnText={'Delete'}
        onClose={() => setElementToDelete(null)}
        onReject={() => setElementToDelete(null)}
        onConfirm={() => {
          setElementToDelete(null)
          handleDeleteLibraryElement()
        }}
      />

      <RenameModal
        open={!!elementToRename}
        title={params.tab === PARAMS_TYPE.EMAIL_CAMPAIGNS
          ? 'Rename Campaign'
          : 'Rename Snippet'
        }
        element={currentActionElement}
        rejectBtnText={'Close'}
        confirmBtnText={'Rename'}
        onClose={() => setElementToRename(null)}
        onConfirm={(value) => {
          setElementToRename(null);
          handleRenameLibraryElement(value);
        }}
      />
    </>
  );
};

export default MyLibraryFolder;
