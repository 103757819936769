export const PERMISSIONS = {
  EDITOR: "Editor",
  REVIEWER: "Reviewer",
  ADMIN: "Admin"
  // SUPER_ADMIN: "Super Admin"
};

export const LAYOUTS = {
  CAROUSEL_LAYOUT: "CarouselLayout",
  ONBOARDING_LAYOUT: "OnboardingLayout",
  PLATFORM_LAYOUT: "PlatformLayout"
};

export const REVIEW_STATUS = {
  DRAFT: "DRAFT",
  PENDING_REVIEW: "PENDING_REVIEW",
  APPROVED: "APPROVED",
  CHANGE_REQUIRED: "CHANGE_REQUIRED",
  REJECTED: "REJECTED"
};

export const REVIEW_STATUS_LABELS = {
  [REVIEW_STATUS.DRAFT]: 'Draft',
  [REVIEW_STATUS.PENDING_REVIEW]: 'Pending review',
  [REVIEW_STATUS.CHANGE_REQUIRED]: 'Changes required',
  [REVIEW_STATUS.APPROVED]: 'Approved',
  [REVIEW_STATUS.REJECTED]: 'Rejected',
};

export const CAMPAIGN_TYPES =  [
  {
    value: 'CUSTOM_MARKETING_EMAIL',
    label: 'Custom Marketing Email',
    id: 7,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'MASS_MARKETING_EMAIL',
    label: 'Mass Marketing Emails',
    id: 8,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'RETARGETING_EMAIL',
    label: 'Retargeting Email',
    id: 9,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'ABANDONED_CART_EMAIL',
    label: 'Abandoned Cart Email',
    id: 10,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'NEWSLETTER_EMAIL',
    label: 'Newsletter Email',
    id: 6,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'CUSTOM_MARKETING_CAMPAIGN',
    label: 'Custom Marketing Campaign',
    id: 3,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'EMOTIONAL_TRIGGERS_CAMPAIGN',
    label: 'Emotional Triggers Campaign',
    id: 4,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'QUAD_LINK_CAMPAIGN',
    label: 'QuadLink Campaign',
    id: 2,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'POSITIONING_CAMPAIGN',
    label: 'Positioning Campaign',
    id: 1,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'MARKETING_INFLUENCE_CAMPAIGN',
    label: 'Marketing Influence Campaign',
    id: 5,
    color: '#57BE99',
    category: 'MARKETING',
    checked: false
  },
  {
    value: 'CUSTOM_SALES_EMAIL_NON_PAYING',
    label: 'Custom Sales Email',
    id: 17,
    color: '#d93855',
    checked: false
  },
  {
    value: 'EXPLORATION_EMAIL',
    label: 'Exploration Email',
    id: 16,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'DEMO_EMAIL',
    label: 'Demo Email',
    id: 18,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'RE_ENGAGEMENT_EMAIL',
    label: 'Re-engagement Email',
    id: 19,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'POST_EVENT_EMAIL_NON_PAYING',
    label: 'Post-event Email',
    id: 20,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'CUSTOM_SALES_EMAIL_PAYING',
    label: 'Custom Sales Email',
    id: 11,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'UPSELL_EMAIL',
    label: 'Upsell Email',
    id: 12,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'CROSS_SELL_EMAIL',
    label: 'Cross-sell Email',
    id: 13,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'RENEWAL_DEAL_EMAIL',
    label: 'Renewal Deal Email',
    id: 14,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'POST_EVENT_EMAIL_PAYING',
    label: 'Post-event Email',
    id: 15,
    color: '#d93855',
    category: 'SALES',
    checked: false
  },
  {
    value: 'CUSTOM_PRODUCT_EMAIL',
    label: 'Custom Product Email',
    id: 25,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  },
  {
    value: 'WELCOME_EMAIL',
    label: 'Welcome Email',
    id: 24,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  },
  {
    value: 'GETTING_STARTED_EMAIL',
    label: 'Getting Started Email',
    id: 23,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  },
  {
    value: 'ONBOARDING_STEPS_EMAIL',
    label: 'Onboarding Steps Email',
    id: 22,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  },
  {
    value: 'TIPS_AND_TRICKS_EMAIL',
    label: 'Tips & Tricks Email',
    id: 21,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  },
  {
    value: 'GATHER_FEEDBACK_EMAIL',
    label: 'Gather Feedback Email',
    id: 26,
    color: '#3a84c9',
    category: 'PRODUCT',
    checked: false
  }
];

export const EMAILS_CAMPAIGN_value =  {
  CUSTOM_MARKETING_EMAIL: {
    label: 'Custom Marketing Email',
    id: 7,
    color: '#57BE99',
    category: 'MARKETING',
  },
  MASS_MARKETING_EMAIL: {
    label: 'Mass Marketing Emails',
    id: 8,
    color: '#57BE99',
    category: 'MARKETING',
  },
  RETARGETING_EMAIL: {
    label: 'Retargeting Email',
    id: 9,
    color: '#57BE99',
    category: 'MARKETING',
  },
  ABANDONED_CART_EMAIL: {
    label: 'Abandoned Cart Email',
    id: 10,
    color: '#57BE99',
    category: 'MARKETING',
  },
  NEWSLETTER_EMAIL: {
    label: 'Newsletter Email',
    id: 6,
    color: '#57BE99',
    category: 'MARKETING',
  },
  CUSTOM_MARKETING_CAMPAIGN: {
    label: 'Custom Marketing Campaign',
    id: 3,
    color: '#57BE99',
    category: 'MARKETING',
  },
  EMOTIONAL_TRIGGERS_CAMPAIGN: {
    label: 'Emotional Triggers Campaign',
    id: 4,
    color: '#57BE99',
    category: 'MARKETING',
  },
  QUAD_LINK_CAMPAIGN: {
    label: 'QuadLink Campaign',
    id: 2,
    color: '#57BE99',
    category: 'MARKETING',
  },
  POSITIONING_CAMPAIGN: {
    label: 'Positioning Campaign',
    id: 1,
    color: '#57BE99',
    category: 'MARKETING',
  },
  MARKETING_INFLUENCE_CAMPAIGN: {
    label: 'Marketing Influence Campaign',
    id: 5,
    color: '#57BE99',
    category: 'MARKETING',
  },

  CUSTOM_SALES_EMAIL_NON_PAYING: {
    label: 'Custom Sales Email',
    id: 17,
    color: '#d93855',
  },
  EXPLORATION_EMAIL: {
    label: 'Exploration Email',
    id: 16,
    color: '#d93855',
    category: 'SALES',
  },
  DEMO_EMAIL: {
    label: 'Demo Email',
    id: 18,
    color: '#d93855',
    category: 'SALES',
  },
  RE_ENGAGEMENT_EMAIL: {
    label: 'Re-engagement Email',
    id: 19,
    color: '#d93855',
    category: 'SALES',
  },
  POST_EVENT_EMAIL_NON_PAYING: {
    label: 'Post-event Email',
    id: 20,
    color: '#d93855',
    category: 'SALES',
  },
  CUSTOM_SALES_EMAIL_PAYING: {
    label: 'Custom Sales Email',
    id: 11,
    color: '#d93855',
    category: 'SALES',
  },
  UPSELL_EMAIL: {
    label: 'Upsell Email',
    id: 12,
    color: '#d93855',
    category: 'SALES',
  },
  CROSS_SELL_EMAIL: {
    label: 'Cross-sell Email',
    id: 13,
    color: '#d93855',
    category: 'SALES',
  },
  RENEWAL_DEAL_EMAIL: {
    label: 'Renewal Deal Email',
    id: 14,
    color: '#d93855',
    category: 'SALES',
  },
  POST_EVENT_EMAIL_PAYING: {
    label: 'Post-event Email',
    id: 15,
    color: '#d93855',
    category: 'SALES',
  },

  CUSTOM_PRODUCT_EMAIL: {
    label: 'Custom Product Email',
    id: 25,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
  WELCOME_EMAIL: {
    label: 'Welcome Email',
    id: 24,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
  GETTING_STARTED_EMAIL: {
    label: 'Getting Started Email',
    id: 23,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
  ONBOARDING_STEPS_EMAIL: {
    label: 'Onboarding Steps Email',
    id: 22,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
  TIPS_AND_TRICKS_EMAIL: {
    label: 'Tips & Tricks Email',
    id: 21,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
  GATHER_FEEDBACK_EMAIL: {
    label: 'Gather Feedback Email',
    id: 26,
    color: '#3a84c9',
    category: 'PRODUCT',
  },
}

export const EMPTY_STATE = {
  COMING_SOON: "COMING_SOON",
  ALL_DONE: "ALL_DONE",
  NO_ITEMS: "NO_ITEMS",
  NO_FILTERS_DATA: "NO_FILTERS_DATA"
};

export const CAMPAIGN_TYPES_LABELS =  [
  {
    name: 'Marketing',
    color: '#C0DFCC',
    category: 'MARKETING',
    checked: false
  },
  {
    name: 'Sales',
    color: '#DFC0C0',
    category: 'SALES',
    checked: false
  },
  {
    name: 'Product',
    color: '#C0D4DF',
    category: 'PRODUCT',
    checked: false
  }
];

export const INTELLIGENCE_TYPES_LABELS =  [
  {
    name: 'Marketing',
    color: '#C0DFCC',
    category: 'GENERAL',
    checked: false
  },
  {
    name: 'Strategy',
    color: '#F3DDBFBF',
    category: 'INTELLIGENCE',
    checked: false
  },
];

export const INTELLIGENCE_TYPES =  [
  {
    value: 'GENERAL',
    label: 'Marketing',
    color: '#C0DFCC',
    category: 'CHAT_INTELLIGENCE',
    checked: false
  },
  {
    value: 'INTELLIGENCE',
    label: 'Strategy',
    color: '#F3DDBFBF',
    category: 'CHAT_INTELLIGENCE',
    checked: false
  },
];
