import React from 'react';
import { Box } from '@material-ui/core';
import { CAMPAIGN_TYPES } from "../../../../utils/enums";

import { useStyles } from "./styles";

interface Props {
    value: string;
    expanded?: boolean;
}

const Types: React.FC<Props> = ({ value, expanded }) => {
    const classes = useStyles();

    const typeData = CAMPAIGN_TYPES.find(type => type.value === value) || null;

    const typeClass = typeData?.category === 'MARKETING'
        ? classes.typeMarketing
        : typeData?.category === 'SALES'
            ? classes.typeSales
            : classes.typeProduct;

    return (
        <Box
            className={`${classes.typesContainer} ${typeClass}`}
            style={{ backgroundColor: expanded ? '#ebebeb' : undefined }}
        >
            {typeData?.label}
        </Box>
    );
};

export default Types;
