import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    borderBottom: '1px solid #E9EAEB'
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    padding: '14px 16px',
    gap: '8px',
    borderBottom: `3px solid transparent`,
    cursor: 'pointer',
    transition: 'all .3s',
    userSelect: 'none',
    '& svg': {
      '& path': {
        fill: '#50555E'
      }
    },
    '&:hover': {
      '& .MuiTypography-root': {
        color: '#262C37 !important',
      },
      '& svg': {
        '& path': {
          fill: '#262C37'
        }
      }
    }
  },
  tabActive: {
    borderColor: '#262C37',
    transition: 'all .3s',
    '& .MuiTypography-root': {
      color: '#262C37 !important',
      fontWeight: '500 !important'
    },
    '& svg': {
      '& path': {
        fill: '#262C37'
      }
    }
  },
}));