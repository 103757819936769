import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";


import { Grid, Box } from "@material-ui/core";

import PrimaryQuestionController, {
  Props,
  configJSON,
} from "./PrimaryQuestionController";
import CustomButton from "../../../components/main/CustomButton.web";
import { FieldArray } from "formik";
import { modalInfoContent } from "../utils/data";

export default class PrimaryQuestionWithFormik extends PrimaryQuestionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      inputList,
      serviceArray,
      title,
      btnDisplay = false,
      buttonName,
      name = "",
      formikHandelChange,
      formikValue,
      formikError,
      maxFeilds = 1,
      touched,
      onAddNewItem,
    } = this.props;

    return (
      <>
        <Grid container style={webStyle.servicesMainContainer}>
          <Grid item xs={12} style={webStyle.servicesContainer}>
            <CustomList
              title={title}
              servicesList={serviceArray}
              modalContent={modalInfoContent[name as keyof typeof modalInfoContent]}
            />
            <Box style={webStyle.offeringBox}>
              <FieldArray
                data-test-id="FormikFieldArray"
                name={name}
                render={(arrayHelpers: any) => (
                  <div>
                    {formikValue[name]?.map((field: any, index: any) => {
                      const _index = index > inputList.length - 1 ? 0 : index
                      return (
                        <Box key={index}>
                          <CustomInput
                            width="100%"
                            textLabel={inputList[_index].label}
                            labelCount={index}
                            placeholder={inputList[_index].placeholder}
                            rowsCount={1}
                            data-testid={"offering-field-" + index}
                            name={`${name}.${index}`}
                            onChange={(
                              e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => formikHandelChange(e, index)}
                            value={formikValue[name][index]}
                            error={formikError[name]?.[index] && !!touched && touched[name]?.[index] ? formikError[name]?.[index] : ''}
                            maxCharacterLenght={inputList[_index].charLength}
                          />
                        </Box>
                      )
                    })}
                    {btnDisplay && (formikValue[name]?.length < maxFeilds) && (
                      <CustomButton
                        data-test-id="FormikFieldButton"
                        onClick={() => {
                          arrayHelpers.push('');
                          onAddNewItem?.();
                        }}
                        buttonName={buttonName}
                      />
                    )}
                  </div>
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  servicesMainContainer: {
    // background: "#EEE7D9",
    // padding: "2% 0 0 0",
    margin: '0.8rem 0px',
  },
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content",
    border: '1px solid #E9EAEB'
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "2%",
  } as any,
};
