import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
    position: "relative",
    display: "flex",
  },
  logo: {
    mixBlendMode: "multiply",
    position: "absolute",
    top: 10,
    left: 20,
    height: "25px",
    width: "125px",
    "@media (min-width: 600px)": {
      height: "40px",
      width: "200px"
    }
  },
  backBtn: {
    position: "absolute",
    top: 84,
    left: 40,
    cursor: "pointer"
  },
  leftRockImg: {
    position: "relative",
    left: 0,
    top: 141
  },
  rightRockImg: {},
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  title: {
    fontSize: "40px",
    lineHeight: "48px",
    fontWeight: 700
  },
  subTitle: {
    fontSize: "18px",
    lineHeight: "23.5px",
    fontWeight: 400,
    whiteSpace: "pre-line",
    textAlign: "center",
    marginTop: "16px",
    color: "#475569",
    maxWidth: "789px"
  },
  iconWrapper: {
    width: "52px",
    height: "52px",
    backgroundColor: "#ffffffcc",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  circle1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "104px",
    height: "104px",
    borderWidth: 1,
    border: "1px solid #0f7f75cc",
    borderRadius: "50%",
    position: "relative",
  },
  circle2: {
    border: "1px solid #0f7f7599",
    width: "167px",
    height: "167px"
  },
  circle3: {
    border: "1px solid #0f7f7566",
    width: "220px",
    height: "220px"
  },
  circle4: {
    border: "1px solid #0f7f7533",
    width: "264px",
    height: "264px",
    marginTop: "40px"
  }
}));
