import { ThemeProvider as Theme, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1920,
  //     '1430': 1430,
  //   },
  // },
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    },
    secondary: {
      main: "#0F7F75",
      contrastText: "#fff"
    }
  },
  typography: {
    fontFamily: "Objectivity, Montserrat, sans-serif",
    // fontFamily: "Montserrat, sans-serif",
    h1: {
      color: "#000",
      textAlign: "center",
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700
    },
    h2: {
      color: "#475569",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600
    },
    h3: {
      color: "#475569",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600
    },
    h4: {
      color: "#000",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500
    },
    h5: {
      color: "#475569",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      color: "#475569",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      margin: "20px 0px"
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      margin: "20px 0px 5px"
    }
  },
  overrides: {
    // MuiCssBaseline: {
    //   "@global": {
    //     body: {
    //       // fontFamily: "Objectivity, Montserrat, sans-serif",
    //       fontFamily: "Montserrat, sans-serif",
    //     }
    //   }
    // },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          opacity: 0,
          animation: "fadeInBackdrop 0.5s forwards",
          "@keyframes fadeInBackdrop": {
            from: { opacity: 0 },
            to: { opacity: 1 },
          },
        },
      },
    },
    MuiTypography: {
      root: {
        margin: "0",
        padding: "0",
        lineHeight: "normal",
        transform: 'translate(0, 1px)',
        "& img": {
          transform: "translate(0, -1px)"
        },
        "& svg": {
          transform: "translate(0, -1px)"
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#000",
        color: "#fff",
        fontSize: "12px",
        borderRadius: "8px"
      },
      arrow: {
        color: "#000"
      }
    },
    MuiDialog: {
      root: {
        zIndex: "1604 !important"
      }
      // paper: {
      //   zIndex: '9999 !important',
      // },
    },
    MuiPopover: {
      root: {
        zIndex: "1700 !important"
      }
    },
    MuiAutocomplete: {
      popper: {
        zIndex: "1700 !important"
      }
    },
    MuiFormControl: {
      root: {
        flex: 1,
        border: "none !important",
        background: "#FFF",
        borderRadius: "8px !important"
      }
    },
    MuiFormControlLabel: {
      label: {
        width: "100%"
      }
    },
    MuiInputBase: {
      root: {
        height: "44px !important",
        border: "1px solid #CBD5E1",
        borderRadius: "8px !important",
        overflow: 'hidden'
      },
      input: {
        "&::placeholder": {
          transform: 'translate(0, 2px)',
        },
        "&:not(:placeholder-shown)": {
          transform: 'translate(0, 2px)',
        }
      },
      multiline: {
        height: "fit-content !important"
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        display: "none !important"
      }
    },
    MuiRadio: {
      root: {
        padding: "8px"
      }
    },
    MuiChip: {
      label: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    MuiSlider: {
      thumb: {
        width: "14px",
        height: "14px",
        border: "2px solid #3A84C9",
        borderRadius: "20px",
        backgroundColor: "white",
        "&.Mui-disabled": {
          width: "14px",
          height: "14px",
          border: "2px solid #64748B",
          marginTop: "-5px"
        }
      },
      rail: {
        height: "4px",
        backgroundColor: "#F1F5F9",
        borderRadius: "10px",
        overflow: "hidden",
        opacity: 1
      },
      track: {
        height: "4px",
        backgroundColor: "#3A84C9",
        opacity: 1,
        borderRadius: "10px",
        overflow: "hidden",
        "&:disabled": {
          backgroundColor: "#64748B"
        }
      }
    },
    MuiPaper: {
      elevation8: {
        boxShadow: "none"
      }
    },
    MuiMenu: {
      paper: {
        border: "1px solid #CBD5E1",
        borderBottomRightRadius: "8px",
        maxHeight: "220px",
        overflowY: "auto",
        borderBottomLeftRadius: "8px",
        overflowX: "hidden"
      },
      list: {
        paddingTop: 0,
        borderBottomRightRadius: "8px",
        paddingBottom: 0,
        borderBottomLeftRadius: "8px"
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: "35px"
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        border: "none"
      }
    },
    MuiSelect: {
      selectMenu: {
        height: "100%",
        width: "100%",
        // display:'flex',
        lineHeight: "40px",
        alignItems: "center",
        padding: "0 8px !important",
        marginRight: "20px"
      },
      select: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Montserrat, sans-serif",
        background: "#fff",
        "& fieldset": {
          borderColor: "transparent",
          height: "40px"
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&:focus": {
          background: "#fff",
          backgroundColor: "#FFF"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .Mui-focus": {
          color: "#000",
          background: "#fff"
        }
      },
      icon: {
        color: "#000"
      }
    },
    MuiButton: {
      root: {
        // General styles for all buttons
        height: "44px",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        gap: "7px",
        padding: "10px 16px",
        textTransform: "none",
        color: "#FFFFFF",
        "&.Mui-disabled": {
          "& svg": {
            fill: "#00000042 !important",
            color: "#00000042 !important",
            "& path": {
              fill: "#00000042 !important"
            },
            "& g": {
              fill: "#00000042 !important",
              "& path": {
                fill: "#00000042 !important"
              }
            },
            "& rect": {
              fill: "#00000042 !important"
            }
          }
        }
      },
      label: {
        lineHeight: "normal",
        padding: "2px 0 0 0"
      },
      startIcon: {
        transform: "translate(0, -1px)"
      },
      endIcon: {
        transform: "translate(0, -1px)"
      },
      containedPrimary: {
        // Styles for primary buttons
        backgroundColor: "#262C37",
        borderColor: "transparent",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#0E0E0E"
        },
        "&.Mui-disabled": {
          backgroundColor: "#E0E0E0 !important",
          color: "#9E9E9E !important"
        }
      },
      outlinedPrimary: {
        // Styles for primary buttons
        backgroundColor: "#262C37",
        borderColor: "#CBD5E1",
        color: "#fff",
        "&:hover": {
          backgroundColor: "#0E0E0E",
          borderColor: "#94A3B8"
        },
        "&.Mui-disabled": {
          backgroundColor: "#E0E0E0 !important",
          borderColor: "#CBD5E1 !important",
          color: "#9E9E9E !important"
        }
      },
      containedSecondary: {
        // Styles for secondary buttons
        backgroundColor: "#FFFFFF",
        color: "#000000",
        "&:hover": {
          backgroundColor: "#FFFFFF"
        },
        "&.Mui-disabled": {
          backgroundColor: "#F5F5F5 !important",
          color: "#9E9E9E !important"
        }
      },
      outlinedSecondary: {
        // Styles for secondary buttons
        backgroundColor: "#FFFFFF",
        borderColor: "#CBD5E1",
        color: "#000000",
        "&:hover": {
          backgroundColor: "#FFFFFF",
          borderColor: "#94A3B8"
        },
        "&.Mui-disabled": {
          backgroundColor: "#F5F5F5 !important",
          borderColor: "#CBD5E1 !important",
          color: "#9E9E9E !important"
        }
      },
      text: {
        // Styles for text variant buttons
        backgroundColor: "transparent",
        color: "#000000",
        border: "none",
        padding: "6px 8px",
        "&:hover": {
          backgroundColor: "transparent"
        },
        "&.Mui-disabled": {
          color: "#9E9E9E !important"
        }
      },
      textPrimary: {
        // Additional styles for primary text buttons
        color: "#000000",
        "&:hover": {
          backgroundColor: "transparent"
        },
        "&.Mui-disabled": {
          color: "#9E9E9E !important"
        }
      },
      textSecondary: {
        // Additional styles for secondary text buttons
        color: "#FFFFFF",
        "&:hover": {
          backgroundColor: "transparent"
        },
        "&.Mui-disabled": {
          color: "#9E9E9E !important"
        }
      }
    },
    MuiIconButton: {
      root: {
        "&.Mui-disabled": {
          "& svg": {
            fill: "#00000042 !important",
            color: "#00000042 !important",
            "& g": {
              fill: "#00000042 !important"
            },
            "& path": {
              fill: "#00000042 !important"
            },
            "& rect": {
              fill: "#00000042 !important"
            }
          }
        }
      }
    }
  }
});

export function ThemeProvider({ children }) {
  return (
    <Theme theme={theme}>
      <CssBaseline />
      {children}
    </Theme>
  );
}
