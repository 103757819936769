import React, { useEffect, useState } from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as CloseRedIcon } from '../../../../assets/icons/close_red.svg';
import { ReactComponent as EditOrangeIcon } from '../../../../assets/icons/edit_orange.svg';
import { ReactComponent as CheckmarkGreenIcon } from '../../../../assets/icons/checkmark_green.svg';
import RadioButton from '../../../../components/main/CustomFields/RadioButton';
import { REVIEW_STATUS } from '../../../../utils/enums';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (status: string) => void;
}

const STATUS_OPTIONS = [
  {
    status: REVIEW_STATUS.CHANGE_REQUIRED,
    title: 'Changes required',
    description: 'Not approving notifies the team that revisions are needed before adding to the Library.',
    primaryColor: '#FDF2FA',
    textColor: '#C11574',
    activeColor: '#FDFAFC',
    checkedColor: '#BF1573',
    // activeIcon: <EditOrangeIcon />
  },
  {
    status: REVIEW_STATUS.APPROVED,
    title: 'Approved',
    description: 'Approving adds items to the Library for everyone to access and to support AI training.',
    primaryColor: '#DAF8E6',
    textColor: '#197D42',
    activeColor: '#F5FDFC',
    checkedColor: '#197D42',
    // activeIcon: <CheckmarkGreenIcon />
  },
  {
    status: REVIEW_STATUS.REJECTED,
    title: 'Rejected',
    description: 'Rejecting means the work must be redone from scratch and taken in a new direction.',
    primaryColor: '#FDE3E3',
    textColor: '#B32225',
    activeColor: '#FEF2F2',
    checkedColor: '#B32225',
    // activeIcon: <CloseRedIcon />
  },
]

const ChooseReviewStatusModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onSave,
  } = props;
  const [status, setStatus] = useState<string | null>(null);

  const classes = useStyles();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onSave?.(status);
      } else if (event.key === 'Escape' || event.key === 'Backspace' || event.key === 'Delete') {
        onClose?.();
      }
    };

    if (open && status) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onClose, onSave, status]);

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.header}>
          <Typography style={{ fontSize: '20px', fontWeight: '500', lineHeight: '20px', color: '#262C37' }}>
            Finish review
          </Typography>
        </Box>

        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: '24px 32px', gap: '12px' }}>
          {STATUS_OPTIONS.map((item, index) => (
            <Box
              key={index}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '8px',
                background: item.status === status ? item.activeColor : 'transparent',
                padding: '8px 20px 8px 8px',
                border: '1px solid #ECE2E9'
              }}
            >
              <RadioButton
                checked={item.status === status}
                label={
                  <Box style={{ display: 'flex', gap: '51px' }}>
                    <Box style={{ width: '170px', marginLeft: '5px' }}>
                      <Typography style={{ width: 'fit-content', fontSize: '16px', fontWeight: '400', lineHeight: '24px', padding: '9px 12px', borderRadius: '8px', color: item.textColor, background: item.primaryColor }}>
                        {item.title}
                      </Typography>
                    </Box>
                    <Typography style={{ width: '330px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: '#50555E' }}>
                      {item.description}
                    </Typography>
                  </Box>
                }
                onChange={() => setStatus(item.status)}
              />
              {/* <Box>
                {item.status === status
                  ? item.activeIcon
                  : null
                }
              </Box> */}
            </Box>
          ))}
        </Box>

        <Box className={classes.footer}>
          <Button
            variant='outlined'
            color='secondary'
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!status}
            onClick={() => onSave(status)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChooseReviewStatusModal;
