export const createFilteredRequestParams = (req: Record<string, any> = {}): string => {
    return Object.entries(req)
        .reduce((params, [key, value]) => {
            if (Array.isArray(value)) {
                value = value.filter(Boolean);
                if (value.length > 0) {
                    params.set(key, value.join(','));
                }
            } else if (value) {
                params.set(key, String(value));
            }
            return params;
        }, new URLSearchParams())
        .toString();
};
