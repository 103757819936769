import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  commentItem: {
    width: "100%",
    // maxWidth: '218px',
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    background: "#FFFFFF",
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    padding: "16px 8px",
    position: "relative",
    userSelect: 'none',
    // cursor: 'pointer',
  },
  commentItemMenuIcon: {
    width: "36px",
    height: "36px",
    top: 5,
    right: 5,
    position: "absolute"
  },
  commentItemMenuBackdrop: {
    "& .MuiPaper-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
      borderRadius: "8px"
    }
  },
  commentItemActive: {
    boxShadow: "-2px 0 0 0 #0F7F75 !important",
    borderLeft: "none",
    cursor: "auto"
  },
  commentItemActionLoader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    left: 0,
    zIndex: 3,
    borderRadius: "12px",
    background: "#CBD5E14D",
    "& .MuiCircularProgress-svg": {
      color: "#6b6b6d"
    }
  },
  nestedItemsWrapper: {
    maxHeight: "500px",
    overflowX: "hidden",
    overflowY: "auto",
    // animation: "$expand .3s ease-in-out forwards"
  },
  nestedItem: {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    // background: '#F8FAFC',
    padding: "10px 0px",
    borderTop: '1px solid #E9EAEB'
  },
  commentMessageInput: {
    position: "relative",
    "& .MuiInputBase-root": {
      "& input": {
        paddingRight: "40px"
      }
    }
  },
  commentMessageSendIcon: {
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translate(-12px, -50%)",
    zoom: "0.6",
    // "& svg": {
    //   "& path": {
    //     stroke: "#0F7F75"
    //   }
    // }
  },
  replyCounter: {
    display: "flex",
    alignItems: "center",
    gap: "7px",
    padding: "0 5px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: "#F5FDFC"
    }
  },
  replyCounterText: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#0F7F75",
    cursor: "pointer",
    padding: "8px 5px",
    borderRadius: "8px",
    "&:hover": {
      background: "#F5FDFC"
    }
    // "&:hover": {
    //   textDecoration: "underline"
    // }
  },
  "@keyframes expand": {
    from: {
      maxHeight: "0px",
      overflow: "hidden"
    },
    to: {
      maxHeight: "500px",
      overflowX: "hidden",
      overflowY: "auto"
    }
  }
}));
