import { EditorState, convertFromRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from 'draft-js-export-html';
import { mdToDraftjs } from 'draftjs-md-converter';

export const convertMarkdownToHtml = (content = '') => {
  const newContent = content
    ?.replace(/__(.*?)__/g, "$1")
    ?.replace(/---/g, "&#8213;")
    ?.replace(/```/g, "")
    ?.replace(/ {4}/g, '')
    ?.replace(/(?<=\d\.\s.*)\n+(?=\d\.)/g, '\n') || '';

  const contentState = convertFromRaw(
    mdToDraftjs(newContent, {
      inlineStyles: {
        Strong: {
          type: "BOLD",
          symbol: "**"
        },
        Emphasis: {
          type: "ITALIC",
          symbol: "*"
        },
        Delete: {
          type: "STRIKETHROUGH",
          symbol: "~~"
        }
      }
    })
  );

  const editorState = EditorState.createWithContent(contentState);

  return convertStateToHTML(editorState);
};

export const convertHTMLToState = (html) => {
  const contentState = stateFromHTML(html, {
    customBlockFn: (element: HTMLElement) => {
      // Custom block alignment
      if (element.style.textAlign) {
        return {
          type: 'unstyled',
          data: { 'text-align': element.style.textAlign },
        };
      }
      return null;
    },
    customInlineFn: (element: HTMLElement, { Style }) => {
      // Handle custom inline styles for font size
      if (element.style.fontSize) {
        const fontSize = element.style.fontSize.replace('px', '');
        return Style(`FONT_SIZE_${fontSize}`);
      }
      return null;
    },
  });

  const editorState = EditorState.createWithContent(contentState);

  return editorState;
};

export const convertStateToHTML = (editorState) => {
  if (editorState) {
    const contentState = editorState.getCurrentContent();

    const options = {
      blockStyleFn: (block) => {
        const alignment = block.getData().get('text-align');

        if (alignment) {
          return {
            style: { textAlign: alignment },
          };
        }

        return null;
      },
      inlineStyleFn: (styles) => {
        const fontSize = +styles.toString().match(/"fontsize-(\d+)"/)?.[1];

        if (fontSize) {
          return { 
            style: { fontSize: `${fontSize}px` }
          };
        }

        return null;
      },
    };

    const html = stateToHTML(contentState, options);
  
    return html;
  } else {
    return '';
  }
};

export const convertHTMLToText = (html) => {
  if (html) {
    const tempDiv = document.createElement("div");

    tempDiv.innerHTML = html;

    return tempDiv.textContent || tempDiv.innerText || "";
  } else {
    return '';
  }
}

export const convertMarkdownToText = (markdown) => {
  if (markdown) {
    return markdown
      .replace(/(\*\*|__)(.*?)\1/g, "$2") // bold
      .replace(/(\*|_)(.*?)\1/g, "$2") // italic
      .replace(/\[(.*?)\]\(.*?\)/g, "$1") // links
      .replace(/`(.*?)`/g, "$1") // inline code
      .replace(/```[\s\S]*?```/g, "") // block code
      .replace(/\n{2,}/g, "\n") // remove extra newlines
      .replace(/#+\s/g, "") // headers
      .replace(/[-*+]\s/g, "") // lists
      .replace(/!\[.*?\]\(.*?\)/g, "") // images
      .replace(/>\s/g, "") // blockquotes
      .trim(); // trim whitespace
  } else {
    return '';
  }
}

export const isMarkdown = (text) => {
  // Original Markdown detection pattern
  const markdownPattern = /(^#+\s)|(\*\*.*\*\*)|(\*.*\*)|(!?\[.*\]\(.*\))|(\[.*?\])/;

  // Return false if the text contains HTML, true if it matches the Markdown pattern
  return !isHTML(text) && markdownPattern.test(text);
}

export const isHTML = (text) => {
  // Check if the string contains any HTML tags
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;

  return htmlRegex.test(text);
}

export const copyHtmlToClipboard = async (html: string) => {
  const addStylesToElements = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    doc.body.querySelectorAll('*').forEach((el) => {
      if (el instanceof HTMLElement) {
        el.style.margin = '0';

        if (el.tagName.match(/^H[1-6]$/)) {
          el.style.fontWeight = 'normal';
        }
      }
    });

    return doc.body.innerHTML;
  };

  const modifiedHtml = addStylesToElements(html);

  // Extract plain text from HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = modifiedHtml;
  const plainText = tempDiv.textContent || tempDiv.innerText || '';

  const blobHtml = new Blob([modifiedHtml], { type: 'text/html' });
  const blobText = new Blob([plainText], { type: 'text/plain' });

  const clipboardItem = new ClipboardItem({ 
    'text/html': blobHtml,
    'text/plain': blobText
  });

  try {
    await navigator.clipboard.write([clipboardItem]);
    return true;
  } catch (err) {
    return false;
  }
};
