import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background: '#FAFAFA',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    padding: '40px 30px',
    background: '#FAFAFA',
    height: 'calc(100svh - 2px)',
    maxWidth: '1300px',
    minWidth: '1050px',
    margin: '0 auto',
  },
  header: {
    width: '100%',
    minWidth: '880px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '28px'
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    maxHeight: 'calc(100svh - 300px)',
    overflowX: 'auto',
  },
  groupContainer: {
    paddingRight: '15px',
  },
  groupTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  groupTitle: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '13.2px',
    color: '#262C37',
    textTransform: 'uppercase',
    marginBottom: '16px',
    userSelect: 'none'
  },
  elements: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  searchBox: {

  },

  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filtersList: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  filtersSearch: {
    width: '100%',
    maxWidth: '416px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px'
  },
  filtersLeftBlock: {
    width: '48%',
    display: 'flex',
    alignItems: 'center',
  },
  tabsBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px',
    border: '1px solid #CBD5E1',
    borderRadius: '80px',
    gap: '5px'
  },
  tabsItem: {
    height: '34px',
    borderRadius: '80px',
    padding: '6px 16px',
    cursor: 'pointer',
    transition: 'all .3s',
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: 600,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.1)',
    }
  },
  tabsItemActive: {
    background: 'black !important',
    color: 'white !important'
  },
  resetButton: {
    width: '96px',
    height: '44px',
    '&:hover': {
      background: '#F5F5F5',
    }
  }
}));