import React from 'react';
import { HelmetProvider as Provider, Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';

const HelmetAsyncProvider = ({ children }) => {
  const author = 'Pebbles Ai'
  const title = `${author} — The gold standard for GTM`;
  const description = 'The only GTM orchestration platform you will need to successfully take your products & services to market.';
  const image = 'https://cdn.prod.website-files.com/651d3feb1a11b6c702ed8056/662668b012ab0c170387aad2_%D1%8C%D0%BB%20(1).png';
  const site = 'https://www.trypebbles.ai/';
  
  return (
    <Provider>
      <Helmet>
        {/* General Meta Tags */}
        <title>{title}</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content={description} />
        <meta name="author" content={author} />
        
        {/* Open Graph (Facebook, LinkedIn, etc.) */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={site} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:site" content={site} />
      </Helmet>
      {children}
    </Provider>
  );
};

export const HelmetProvider = withRouter(HelmetAsyncProvider);
