import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Avatar, Badge, Box, Button, CircularProgress, IconButton, TextField, Typography } from "@material-ui/core";
// import { ReactComponent as CopilotIcon } from '../../assets/icons/copilot_chat.svg';
// import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
// import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
// import { ReactComponent as CommunicationIcon } from '../../assets/icons/communication_icon.svg';
// import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SendMessageIcon } from '../../assets/icons/send_message_icon.svg';
// import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
// import { ReactComponent as WelcomeToGeneralChat } from '../../assets/icons/welcome_to_general_chat.svg';
// import { ReactComponent as WelcomeToIntelligenceChat } from '../../assets/icons/welcome_to_intelligence_chat.svg';
import { ReactComponent as RegenerateWhite } from '../../assets/icons/regenerate_white.svg';
import { deleteChat, getChatById, getChats, createChat, sendChatQuestion, saveCopilotAnswer, getFollowUpQuestions } from "../../redux/services/copilot";
import TypingText from "./components/TypingText";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import LimitationWidget from "../../components/main/LimitationWidget";
import { getOnboardingCompleteness } from "../../redux/services/onboarding";
import SideBar from "./components/SideBar";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { observableService } from "../../services/observable";
import { notify } from "../../providers/notification";
import { AnswerStatus, Chat, CopilotTypes, MessageAuthorTypes } from "../../interfaces/copilot";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { ReactComponent as ExecutionWelcomeIcon } from '../../assets/icons/copilot_execution_welocome.svg';
import { ReactComponent as StrategyWelcomeIcon } from '../../assets/icons/copilot_strategy_welocome.svg';
import { ReactComponent as SideBarCloseIcon } from '../../assets/icons/copilot_sidebar_close.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/copilot_sidebar_open.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import SaveToLibraryModal from './components/SaveToLibraryModal';
import pusher from "../../helpers/pusher";
import usePusherChannel from "../../hooks/usePusherChannel";
import useGetSubscription from "../../hooks/useGetSubscription";
import useGetProfile from "../../hooks/useGetProfile";

import { useStyles } from "./styles";

const Copilot = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [chats, setChats] = useState<Chat[]>(null);
  const [chat, setChat] = useState<Chat>(null);
  const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [fetching, setFetching] = useState<boolean>(false);
  const [allowAnimate, setAllowAnimate] = useState<boolean>(false);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [saveAnswerConfig, setSaveAnswerConfig] = useState<{ active: boolean, message: any }>({
    active: false,
    message: null
  });

  const classes = useStyles();
  const history = useHistory();
  const params = useParams<{ id: string, tab: CopilotTypes }>();
  const messagesRef = useRef<any>(null);
  const abortController = useRef<AbortController | null>(null);
  const { setPusher, clearPusher, inProgress, isConnected } = usePusherChannel({
    onMessageReceived: (data, channel) => {
      setChat(prevChat => ({
        ...prevChat,
        messages: prevChat.messages.map(item => (
          (item.id === channel.name && item.author === 'copilot')
            ? {
              ...item,
              message: item.message + data[0]
            }
            : item
        ))
      }))
    },
    onConnectionClosed: async (channel) => {
      await handleSaveCopilotAnswer('COMPLETED');
      await handleGetFollowUpQuestions();
      await handleGetChats(false);

      if (!params.id) {
        chat.id
          ? window.history.pushState({}, "", `/copilot/${params.tab}/${chat.id}`)
          : window.history.pushState({}, "", `/copilot/${params.tab}`);

        // handleGetChatById(chat.id);
      }
    },
    onError: async () => {
      handleSaveCopilotAnswer('FAILED');
      handleGetChatById(chat.id);
    },
    dependencies: [chats, chat, params]
  });

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    const { id, tab } = params;

    if (tab === CopilotTypes.EXECUTION || tab === CopilotTypes.STRATEGY) {
      setChat(null);
      setMessage('');
      setFollowUpQuestions([]);
      setAllowAnimate(false);

      handleGetChats();

      if (id) {
        handleGetChatById(id);
      }
    } else {
      history.push(`/copilot/${CopilotTypes.EXECUTION}`);
    }

    return () => {
      setAllowAnimate(false);
    };
  }, [params]);

  useEffect(() => {
    if (subscription) {
      handleIsSubscriptionActive();
      handleIsOnboardingCompleted();
    }
  }, [subscription]);

  useEffect(() => {
    scrollToBottom();
  }, [inProgress]);

  useEffect(() => {
    if (!fetching && messagesRef?.current) {
      scrollToBottom();
    }
  }, [fetching, messagesRef]);

  useEffect(() => {
    return () => {
      abortController.current?.abort(); // Cancel the request on unmount
    };
  }, [params.id]);

  const handleIsSubscriptionActive = async () => {
    if (getCurrentSubscriptionStatus(subscription) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handleGetChats = async (addNewChat = true) => {
    setLoading(!chats?.length);

    const { data, error } = await getChats(params.tab);

    if (data && !error) {
      if (addNewChat && !params.id) {
        setChats([
          {
            id: 'none',
            title: 'New chat'
          },
          ...data
        ]);
      } else {
        setChats(data);
      }
    }

    setLoading(false);
  }

  const handleGetChatById = async (id) => {
    setAllowAnimate(false);
    setFetching(true);

    const { data, error } = await getChatById({ id, tab: params.tab });

    if (data && !error) {
      setChat({
        ...data,
        messages: data.messages.map(item => [
          {
            id: item.id,
            author: MessageAuthorTypes.USER,
            answerStatus: item.answerStatus,
            message: item.question,
          },
          {
            id: item.id,
            author: MessageAuthorTypes.COPILOT,
            answerStatus: item.answerStatus,
            message: item.answer || 'error',
          },
        ]).flat(),
      })
    } else {
      notify.error('Chat not found');

      history.push(`/copilot/${params.tab}`);
    }

    setFetching(false);

    setTimeout(() => {
      setAllowAnimate(true);
    }, 100);
  };

  const handleSendChatQuestion = async (incomingMessage = undefined) => {
    if (!inProgress && !isConnected) {
      if (!chat || chat.messages.every(item => item?.message?.trim()?.length)) {
        if (isOnboardingCompleted && isSubscriptionActive) {
          const controller = new AbortController();
          const signal = controller.signal;

          abortController.current = controller;

          scrollToBottom();
          setFollowUpQuestions([]);
  
          const question = incomingMessage || message;
  
          setMessage('');
  
          if (chat?.sessionId) {
            const oldChat = chat;
            const channel_id = uuidv4();
            const channel = pusher.subscribe(channel_id);
  
            setPusher(channel);
  
            setChat({
              ...chat,
              messages: [
                ...chat.messages,
                {
                  id: channel_id,
                  author: MessageAuthorTypes.USER,
                  answerStatus: AnswerStatus.UNEVALUATED,
                  message: question,
                },
                {
                  id: channel_id,
                  author: MessageAuthorTypes.COPILOT,
                  answerStatus: AnswerStatus.UNEVALUATED,
                  message: '',
                },
              ]
            })
  
            const { data, error } = await sendChatQuestion({
              body: {
                messageId: channel_id,
                accountId: profile.account.uuid,
                userId: profile.user.uuid,
                question,
              },
              sessionId: chat.sessionId,
              tab: params.tab,
              signal
            });
  
            if (error?.status === 402) {
              setChat(oldChat);
              clearPusher();
  
              observableService.sendEvent(`Show top up modal for ${params.tab === CopilotTypes.EXECUTION ? 'COPILOT' : 'COPILOT_STRATEGY'}`);
  
              setLeavingModal({ active: false, nextLocation: null });
            } else if (!error?.error?.includes('AbortError')) {
              if (data) {
                observableService.sendEvent('Increase usage amount');
              } else {
                setChat(oldChat);
                clearPusher();
              }
            }
          } else {
            const oldChat = chat;
            const channel_id = uuidv4();
            const channel = pusher.subscribe(channel_id);
  
            setPusher(channel);
  
            setChat({
              ...chat,
              messages: [
                {
                  id: channel_id,
                  author: MessageAuthorTypes.USER,
                  answerStatus: AnswerStatus.UNEVALUATED,
                  message: question,
                },
                {
                  id: channel_id,
                  author: MessageAuthorTypes.COPILOT,
                  answerStatus: AnswerStatus.UNEVALUATED,
                  message: '',
                },
              ]
            })
  
            const { data, error } = await createChat({
              body: {
                messageId: channel_id,
                accountId: profile.account.uuid,
                userId: profile.user.uuid,
                question
              },
              tab: params.tab,
              signal
            });
  
            if (error?.status === 402) {
              setChat(oldChat);
              clearPusher();
  
              observableService.sendEvent(`Show top up modal for ${params.tab === CopilotTypes.EXECUTION ? 'COPILOT' : 'COPILOT_STRATEGY'}`);
  
              setLeavingModal({ active: false, nextLocation: null });
            } else if (!error?.error?.includes('AbortError')) {
              if (data) {
                observableService.sendEvent('Increase usage amount');
  
                setChat(prevChat => ({
                  ...data,
                  messages: prevChat?.messages
                }))
              } else {
                setChat(oldChat);
                clearPusher();
              }
            }
          }

          abortController.current = null;
          handleGetChats(false);
        } else {
          setTimeout(() => {
            setShowActionBlockedModal(true);
          }, 100);
        }
      }
    }
  }

  const handleGetFollowUpQuestions = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    abortController.current = controller;

    const { data, error } = await getFollowUpQuestions({
      sessionId: chat.sessionId,
      modificator: params.tab,
      signal
    });

    if (data && !error) {
      abortController.current = null;

      setFollowUpQuestions(data);

      setTimeout(() => {
        scrollToBottom();
      }, 300);
    }
  }

  const handleSaveCopilotAnswer = async (status) => {
    await saveCopilotAnswer({
      chatId: chat.id,
      messageId: [...chat.messages].pop().id,
      modificator: params.tab,
      body: {
        answer: [...chat.messages].pop().message,
        status
      }
    });
    // handleGetChats(false);
  }

  const scrollToBottom = () => {
    if (messagesRef?.current) {
      setTimeout(() => {
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight - 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const handleKeyDown = (event) => {
    const keyPressed = event.key || event.code || event.nativeEvent.code || event.nativeEvent.key;

    if (keyPressed?.toLowerCase() === 'enter' && !event.shiftKey && !!message?.trim()?.length) {
      event.preventDefault(); // Prevents newline on Enter

      handleSendChatQuestion();
    }
  };

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation: nextLocation.pathname
    })

    return false;
  };

  return (
    <>
      <Prompt
        when={isConnected}
        message={(location) => handleBlockedNavigation(location)}
      />

      <Box className={classes.wrapper}>
        <SideBar
          open={sideBarOpen}
          tab={params.tab}
          chats={chats}
          chat={chat}
          subscription={subscription}
          loading={loading}
          leavingModal={leavingModal}
          inProgress={inProgress || isConnected}
          setLoading={setLoading}
          isOnboardingCompleted={isOnboardingCompleted}
          isSubscriptionActive={isSubscriptionActive}
          handleGetChats={handleGetChats}
          setSideBarOpen={setSideBarOpen}
          setChat={setChat}
          setChats={setChats}
          clearPusher={clearPusher}
        />

        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.headerLeftBox}>
              <Box
                className={classes.newChatButton}
                onClick={() => setSideBarOpen(!sideBarOpen)}
              >
                {sideBarOpen
                  ? <SideBarOpenIcon />
                  : <SideBarCloseIcon />
                }
              </Box>
              <Box
                className={classes.newChatButton}
                onClick={() => {
                  if (!inProgress && !isConnected && chat && chat?.id !== 'none' && isOnboardingCompleted && isSubscriptionActive) {
                    setChat(null);

                    history.push(`/copilot/${params.tab}`);
                  }
                }}
              >
                <PlusIcon />
              </Box>
            </Box>
            <Box className={classes.headerCenterBox}>
              <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                {chat?.title || 'New chat'}
              </Typography>
            </Box>
            <Box className={classes.headerRightBox}>
              <Box className={classes.actions}>
                <LimitationWidget
                  type={params.tab === CopilotTypes.EXECUTION ? 'COPILOT' : 'COPILOT_STRATEGY'}
                />
              </Box>
            </Box>
          </Box>
          <Box
            className={classes.copilot}
            style={{
              justifyContent: (!chat || chat?.id === 'none') ? 'flex-end' : 'flex-start',
            }}
          >
            <div
              ref={messagesRef}
              className={classes.messages}
              style={{
                height: (!chat || chat.id === 'none') ? 'fit-content' : '100%',
              }}
              id='messages'
            >
              {fetching
                ? <CircularProgress style={{ margin: '0px auto 150px auto', color: '#CAB354' }} />
                : (!chat || !chat?.messages?.length)
                  ? <Box className={classes.welcomeContainer}>
                    <Box className={classes.welcomeBox}>
                      {params.tab === CopilotTypes.EXECUTION
                        ? <ExecutionWelcomeIcon />
                        : <StrategyWelcomeIcon />
                      }
                      <Typography className={classes.welcomeText}>
                        Copilot {params.tab === CopilotTypes.EXECUTION ? 'Marketing' : 'Strategy'} Chat
                      </Typography>
                      <Typography className={classes.chatTitle}>
                        {params.tab === CopilotTypes.EXECUTION
                          ? 'Handle your daily marketing and sales activities.'
                          : 'Shape your GTM strategy with actionable intelligence.'
                        }
                      </Typography>
                    </Box>
                  </Box>
                  : chat.messages.map((item, index) => (
                    <Box
                      key={item.author + item.id + index}
                      className={classes.messagesItem}
                    >
                      <TypingText
                        id={item.id}
                        tab={params.tab}
                        chat={chat}
                        text={item.message}
                        author={item.author}
                        answerStatus={item.answerStatus}
                        status={item.status}
                        inProgress={inProgress || isConnected}
                        allowRefetch={false}
                        allowAnimate={allowAnimate}
                        scrollToBottom={scrollToBottom}
                        setChat={setChat}
                        setSaveAnswerConfig={setSaveAnswerConfig}
                      />
                    </Box>
                  ))
              }
            </div>
          </Box>
          {!fetching && chat?.messages && chat.messages[chat.messages.length - 1].message === 'error'
            ? <Box style={{ paddingTop: '20px' }}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<RegenerateWhite />}
                  onClick={() => {
                    const lastMessages = chat.messages.slice(-2);
                  
                    handleSendChatQuestion(lastMessages[0].message);
                  }}
                >
                  Regenerate response
                </Button>
              </Box>
            : !!followUpQuestions.length && (
                <Box className={`${classes.followUpWrapper} animate__animated animate__fadeIn`}>
                  {followUpQuestions.map((item, index) => (
                    <Box
                      key={index}
                      className={classes.followUpItem}
                      onClick={() => {
                        setMessage('');
                        handleSendChatQuestion(item);
                      }}
                    >
                      <Typography style={{ fontSize: '14px', fontWeight: '400', color: '#262C37' }}>
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )
          }
          <Box
            className={classes.footer}
            style={{
              height: !params.id && (!chat || chat.id === 'none') ? '100%' : '185px'
            }}
          >
            <Box className={classes.footerInput}>
              <TextField
                fullWidth
                value={message}
                placeholder={'Ask your Copilot a question'}
                multiline
                autoFocus
                minRows={4}
                maxRows={6}
                onChange={(event) => setMessage(event.target.value)}
                onKeyDown={handleKeyDown}
              />
              <IconButton
                size="small"
                disabled={!message?.trim()?.length}
                onClick={() => handleSendChatQuestion()}
              >
                <SendMessageIcon />
              </IconButton>
            </Box>
            <Typography style={{ textAlign: 'center', fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
              Pebbles Copilot is still learning. Please double-check the information.
            </Typography>
          </Box>
        </Box>
      </Box>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? history.push('/subscriptions')
            : history.push('/onboarding')
        }
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={'You have an ongoing request'}
        description={'If you leave this chat, your current request may be lost'}
        onClose={() => setLeavingModal({ active: false, nextLocation: null })}
        rejectBtnText={"Stay"}
        confirmBtnText={"Leave"}
        reversedButtons
        onReject={() => {
          setLeavingModal({ active: false, nextLocation: null });
        }}
        onConfirm={() => {
          clearPusher();

          setTimeout(() => {
            handleSaveCopilotAnswer('FAILED');

            setLeavingModal({ active: false, nextLocation: null });

            history.push(leavingModal.nextLocation);
          }, 200);
        }}
      />

      <SaveToLibraryModal
        open={saveAnswerConfig.active}
        title={chat?.title}
        message={saveAnswerConfig.message}
        subscription={subscription}
        onClose={(refetchChat) => {
          // if (refetchChat) {
          //   handleGetChatById(chat.id);
          // }

          setSaveAnswerConfig({
            active: false,
            message: null
          });
        }}
      />
    </>
  );
};

export default Copilot;
