import React from 'react';
import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { ReactComponent as InfoHintHighlightArrow } from '../../../../assets/icons/info_hint_highlight_arrow.svg';
import { ReactComponent as InfoHint } from '../../../../assets/icons/info-hint-before-start.svg';

import { useStyles } from './styles';

const InfoHighlightIcon = ({
  onClose,
  onShowModal
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.overlay} />

      <Box className={classes.hightLightInner4}>
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Box className={classes.descriptionContainer}>
              <Typography
                component="span"
                className={classes.description}
              >
                Click here to find out additional information
              </Typography>

              <Box className={classes.arrowInner}>
                <InfoHintHighlightArrow
                  className={classes.arrow}
                />
              </Box>
            </Box>

            <Button
              variant='outlined'
              color='secondary'
              style={{
                marginTop: '20px',
                background: 'transparent',
                color: 'white'
              }}
              onClick={onClose}
            >
              OK, I got it
            </Button>
          </Box>
        </Box>

        <Box className={classes.hightLightInner3} />
        <Box className={classes.hightLightInner2} />
        <Box className={classes.hightLightInner1} />

        <IconButton
          onClick={onShowModal}
        >
          <InfoHint className={classes.highlightIcon} />
        </IconButton>
      </Box>
    </>
  );
};

export default InfoHighlightIcon;
