import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containter: {
    width: "80%",
    maxWidth: "520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
    border: "1px solid #CBD5E1",
    borderRadius: "28px",
    margin: "0 auto",
    gap: "7px"
  }
}));
