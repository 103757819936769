import React from "react";
import Robot_8 from "../../../../assets/robots/robot_8.png";
import Robot_9 from "../../../../assets/robots/robot_9.png";
import Robot_10 from "../../../../assets/robots/robot_10.png";
import { Avatar, Box, Typography } from "@material-ui/core";
import { EMPTY_STATE } from "../../../../utils/enums";
import { useStyles } from "./styles";

interface Props {
    type: keyof typeof EMPTY_STATE;
    minHeight?: string;
}

const EmptyState: React.FC<Props> = ({ type, minHeight = "" }) => {
    const classes = useStyles();

    const emptyStateData = {
        [EMPTY_STATE.ALL_DONE]: {
            text: "All done!",
            description: "Seems like there are no items left.",
            icon: Robot_9,
        },
        [EMPTY_STATE.NO_ITEMS]: {
            text: "No intelligence yet",
            description: "",
            icon: Robot_10,
        },
        [EMPTY_STATE.NO_FILTERS_DATA]: {
            text: "No results found",
            description: "Try adjusting your filters.",
            icon: Robot_10,
        },
        default: {
            text: "Coming soon!",
            description: "The team is working on it. Stay tuned!",
            icon: Robot_8,
        },
    };

    const { text, description, icon } = emptyStateData[type] || emptyStateData.default;

    return (
        <Box className={classes.container} style={{ minHeight }}>
            <Box className={classes.icon}>
                <Avatar
                    src={icon}
                    alt="robot icon"
                    style={{ width: "100%", height: "100%", objectFit: "contain", overflow: "visible" }}
                />
            </Box>
            <Typography component="h3" className={classes.title}>
                {text}
            </Typography>
            <Typography component="p" className={classes.text}>
                {description}
            </Typography>
        </Box>
    );
};

export default EmptyState;
