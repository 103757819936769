import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { InfoOutlined, Close } from '@material-ui/icons';
import { ReactComponent as InfoHint } from '../../../../assets/icons/info-hint-before-start.svg';

import { useStyles } from './styles';

const CONTENTS = [
  "A brief history of the company",
  "The company's mission, vision and USP",
  "Core company values",
  "Significant milestones and achievements",
  "Detailed descriptions of products/services",
  "Benefits of product/service features",
  "Brief biographies of key team members",
  "Previous marketing and sales materials",
  "Briefs on news stories featuring the company",
  "Testimonials from customers",
  "Narratives of customer success stories",
  "Key performance metrics",
  "Endorsements from satisfied customers",
  "Findings from competitive analysis",
  "Market insights specific to your company",
  "Insights from your customers' markets"
];

const InfoHighlightIcon = ({
  open,
  onClose
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        className={classes.dialog}
        open={open}
        scroll="paper"
        PaperProps={{ style: { borderRadius: 20 } }}
      >
        <DialogTitle className={classes.title}>
          <InfoHint className={classes.icon} />

          <IconButton
            className={classes.closeButton}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.content}>
          <Box className={classes.topContent}>
            <Typography className={classes.description}>
              Our proprietary engine, Pebbles Copilot, is able to generate
              unparalleled intelligence, guidance, and content tailored to
              each specific goal. Though to maximise its capabilities, it's
              crucial to provide it with up to date information about your
              company.
            </Typography>
          </Box>

          <Box className={classes.list}>
            <Typography className={classes.listTitle}>
              Some topics you might consider uploading:
            </Typography>

            <ul className={classes.listContent}>
              {CONTENTS.map((item, index) => (
                <li className={classes.listItem} key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </Box>
        </DialogContent>

        <DialogActions className={classes.action}>
          <Button 
            fullWidth
            variant='contained'
            color='primary'
            onClick={onClose}
          >
            Thanks
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InfoHighlightIcon;
