import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getProfile } from '../redux/services/profile';
import { clearStorages } from '../helpers/other';
import { ProfileResponse } from '../interfaces/profile';
import { Settings } from 'luxon';

const useGetProfile = (forceFetch = false): { profile: ProfileResponse, refetch: (forceFetch?: boolean) => void } => {
  const [profile, setProfile] = useState<ProfileResponse | null>(null);

  const history = useHistory();

  useEffect(() => {
    handleGetProfile(forceFetch);
  }, []);

  const handleGetProfile = async (forceFetch = false) => {
    const { data, error } = await getProfile(undefined, forceFetch);

    if (data && !error) {
      Settings.defaultZone = data.user.timezone;

      setProfile(data);
    } else {
      clearStorages();

      history.push('/');
    }
  };

  return { profile, refetch: handleGetProfile };
};

export default useGetProfile;
