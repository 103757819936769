import React from 'react';
import Dropdown from '../../../../../../components/main/CustomFields/Dropdown/index';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, newValidationSchema?: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
}

const RenderDropdown: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  return (
    <Dropdown
      name={name}
      value={values[name]}
      placeholder={'Choose option...'}
      options={item.options.map(item => ({ value: item, label: item }))}
      error={touched[name] && errors[name]}
      onChange={(event) => setFieldValue(event.target.name, event.target.value)}
    />
  );
};

export default RenderDropdown;
