import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: "flex",
    justifyContent: 'flex-start',
    background: '#FAFAFA'
  },
  content: {
    width: '100%',
    height: '100%',
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  },
  header: {
    minWidth: '720px',
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E9EAEB",
    padding: "12px 24px"
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    width: 'fit-content',
    height: '32px',
    padding: '8px 12px',
    borderRadius: '8px',
    background: '#F5F5F5'
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    '& .MuiIconButton-root': {
      opacity: 0
    },
    '&:hover': {
      '& .MuiIconButton-root': {
        opacity: 1
      }
    }
  },
  editor: {
    width: "100%",
    maxWidth: "950px",
    minWidth: "720px",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: "Objectivity",
    position: "relative",
    overflow: "hidden",
    padding: "12px",
    border: "1px solid #E9EAEB",
    borderRadius: "8px",
    gap: "10px",
    background: "#FAFAFA",
    margin: '0 auto',
    "& .MuiFormControl-root": {
      height: "100% !important"
    },
    "& .MuiFormControlLabel-root": {
      height: "100% !important"
    },
    "& .MuiTextField-root": {
      height: "100% !important"
    },
    "& .MuiInputBase-multiline": {
      height: "fit-content !important"
    }
  },
  editorWrapper: {
    height: 'calc(100% - 70px)',
    display: "flex",
    flexDirection: "column",
    gap: "20px"
  },
  editorToolbar: {
    width: "96%",
    margin: "0px !important",
    padding: "8px !important",
    border: "none !important",
    background: "#F5F5F5 !important",
    borderRadius: "8px !important",
    flexWrap: "nowrap",
    overflow: "visible",
    "& .rdw-block-wrapper": {
      borderRight: "1px solid #CBD5E1",
      paddingRight: "4px",
      margin: "0px",
      flexWrap: "nowrap",
      "& .rdw-dropdown-selectedtext": {
        color: "#475569",
        fontWeight: "bold",
        "& .rdw-dropdown-carettoopen": {
          borderTopColor: "#475569",
          transform: "translate(0, 50%)"
        }
      }
    },
    "& .rdw-inline-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-list-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-block-dropdown": {
      width: "130px",
      flexWrap: "nowrap"
    },
    "& .rdw-text-align-wrapper": {
      margin: "0px",
      flexWrap: "nowrap"
    },
    "& .rdw-link-wrapper": {
      margin: "0px",
      flexWrap: "nowrap",
      "& .rdw-link-modal": {
        transform: "translate(-85%, 0px)",
        // "@media (max-width: 1347px)": {
        //   transform: "translate(0%, 0px)"
        // }
      }
    },
    "& .rdw-fontsize-wrapper": {
      borderRight: "1px solid #CBD5E1",
      padding: "0px 4px",
      flexWrap: "nowrap"
    },
    "& .rdw-remove-wrapper": {
      padding: "0px 4px",
      margin: "0px",
      flexWrap: "nowrap"
    }
  },
  editorTextarea: {
    // height: 'calc(100% - 100px) !important',
    height: "100%",
    paddingRight: "10px",
    cursor: "text",
    "& .DraftEditor-root": {
      height: "100%",
      "& .DraftEditor-editorContainer": {
        height: "100%",
        "& .public-DraftStyleDefault-pre": {
          margin: "0px !important"
        },
        "& .public-DraftStyleDefault-block": {
          margin: "0px !important"
        },
        "& .rdw-link-decorator-wrapper": {
          "& .rdw-link-decorator-icon": {
            // display: 'none'
            top: "-15px"
          }
        },
        '& .rdw-left-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'left !important'
          }
        },
        '& .rdw-center-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'center !important'
          }
        },
        '& .rdw-right-aligned-block': {
          '& .public-DraftStyleDefault-block': {
            textAlign: 'right !important'
          }
        },
        "& h1": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h2": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h3": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h4": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h5": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& h6": {
          margin: "0px !important",
          fontWeight: "normal"
        },
        "& ul": {
          margin: "0px !important"
        },
        "& ol": {
          margin: "0px !important"
        }
      }
    }
  },
  toolbarGroup: {
    borderRight: "1px solid #CBD5E1",
    padding: "0px 4px"
  },
  toolbarButton: {
    background: "transparent !important",
    border: "none !important",
    boxShadow: "none !important",
    borderRadius: "6px !important",
    margin: "0 1px !important",
    "&:hover": {
      background: "#CBD5E14D !important"
    },
    "&.rdw-option-active": {
      background: "#CBD5E1 !important"
    },
    "&.rdw-option-disabled": {
      background: "transparent !important"
    }
  },
  footer: {
    width: '100%',
    maxWidth: '1080px',
    minWidth: '650px',
    // padding: '0px 20px 30px 20px',
    display: 'flex',
    rowGap: '20px',
    margin: '0 auto'
  },
  actions: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '12px',
    background: '#F8FAFC',
    borderRadius: '8px',
  },
  sideBar: {
    width: '0px',
    minWidth: '0px',
    maxWidth: '0px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '12px 0px',
    transition: 'all .3s',
    position: 'relative',
    borderLeft: '1px solid #E9EAEB'
  },
  sideBarOpen: {
    // width: '270px',
    // minWidth: "270px",
    // maxWidth: '270px',
    padding: '12px',
    background: '#F5F5F5'
  },
  sideBarContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "5px 12px 5px 2px"
  },
  resizeController: {
    position: "absolute",
    height: "100%",
    width: "5px",
    top: 0,
    left: 0,
    cursor: "col-resize",
    "&:hover": {
      borderLeft: "1px solid #1890ff"
    }
  },
  historyHeaderBottomRight: {
    width: '100%',
    minWidth: '720px',
    maxWidth: '950px',
    display: 'flex',
    margin: '0 auto 40px auto',
    padding: '0 20px'
  },
  owner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px'
  },
  ownerAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    color: '#50555E',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '38px',
    fontWeight: 500
  },
  ownerInfo: {
    textAlign: 'left',
  },
  ownerTitle: {
    fontSize: '12px',
  },
  ownerName: {
    fontSize: '14px',
    color: '#262C37',
  },
}));
