import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  Divider,
  Box, Typography,
} from '@material-ui/core';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow_down_v2.svg';
import CheckBoxIcon from '../../../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';

import { useStyles } from './styles';

interface Props {
  value: any[],
  onChange: (value: any) => void;
}

const colors = [
  { bg: '#FFFAEB' , color: '#B54708'},
  { bg: '#F5F5F5' , color: '#50555E'},
  { bg: '#DAF8E6' , color: '#197D42'},
  { bg: '#EFF8FF' , color: '#175CD3'},
  { bg: '#FDF2FA' , color: '#C11574'},
]


const CreateByDropdown: React.FC<Props> = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (user) => {
    const newValue = value.map((s) =>
      s.uuid === user.uuid ? { ...s, checked: !s.checked } : s
    );
    onChange(newValue);
  };

  const handleSelectAll = () => {
    const newValue = value.map((s) => {
      return {
        ...s,
        checked: true,
      }
    })
    onChange(newValue);
  };

  const clearSelection = () => {
    const newValue = value.map((s) => {
      return {
        ...s,
        checked: false,
      }
    })
    onChange(newValue);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<ArrowDown width={17} height={17} />}
        style={{
          textTransform: "none",
          borderRadius: "8px",
          borderColor: "#E9EAEB",
          color: "#262C37",
          backgroundColor: "#fff",
          fontSize: 16,
        }}
      >
        Created by
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            maxHeight: 'max-content',
            maxWidth: '340px',
            width: '100%',
            marginTop: '10px',
            borderRadius: '8px',
            border: '1px solid #E9EAEB',
            paddingTop: '8px',
          },
        }}
      >
        <Box className={classes.listWrapper}>
          {value.map((user, index) => {
            const colorIndex = index % colors.length;
            return (
                <MenuItem
                    key={user.uuid}
                    onClick={() => handleToggle(user)}
                    className={classes.menuItem}
                >
                  <Checkbox
                      icon={<img width={20} height={20} src={CheckBoxIcon} alt={'checkbox icon'}/>}
                      checkedIcon={<img width={20} height={20} src={CheckBoxCheckedIcon} alt={'checkbox checked icon'}/>}
                      checked={!!user.checked}
                  />
                  <Box className={classes.userWrapper}>
                    <Box
                        className={classes.userAvatar}
                        style={{ backgroundColor: colors[colorIndex].bg, color: colors[colorIndex].color }}>
                      {user.name.slice(0, 1).toUpperCase()}
                    </Box>
                    <Box className={classes.userName}>
                      {user.name}
                    </Box>
                  </Box>
                </MenuItem>
            )
          })}
        </Box>

        <Divider style={{ marginTop: "6px" }} />
        <MenuItem
          onClick={value.filter(s => s.checked)?.length > 0 ? clearSelection : handleSelectAll}
          className={classes.menuSelectAll}
        >
          {
            value.filter(s => s.checked).length > 0
              ? <Box className={classes.clearSelection}>
                <Typography>Clear selection</Typography>
                <Typography>{value.filter(s => s.checked)?.length}</Typography>
              </Box>
              : <ListItemText primary={'Select all'} />
          }
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default CreateByDropdown;
