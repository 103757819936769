import { StorageService } from "../providers/storage";
import { removeProfile, setProfile } from "../redux/features/profile";
import { store } from "../redux/store";

export const isLocalhost = () => {
  return (
    location.hostname === "localhost" ||
    location.hostname === "127.0.0.1" ||
    location.hostname === ""
  );
};

export const clearStorages = async () => {
  const dispatch = store.dispatch;

  await StorageService.clear();
  window.localStorage.clear();
  window.sessionStorage.clear();

  // to clear all cookies for the current domain
  document.cookie
    .split(";")
    .forEach(
      (cookie) =>
        (document.cookie =
          cookie.split("=")[0] +
          "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/")
    );

  setTimeout(() => {
    dispatch(removeProfile());
  }, 1000);
};

export const getUrlAndParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  return {
    url,
    params
  };
};

export const removeAllUrlParams = (hardRemove = false) => {
  if (!isLocalhost() || hardRemove) {
    const url = new URL(window.location.href);

    url.search = "";

    window.history.replaceState({}, document.title, url.href);
  }
};

export const removeUrlParam = (key, hardRemove = false) => {
  if (!isLocalhost() || hardRemove) {
    const url = new URL(window.location.href);

    url.searchParams.delete(key);

    window.history.replaceState({}, document.title, url.toString());
  }
};

export const getVisibleElementsByClassName = (
  className: string
): HTMLElement[] => {
  // Get all elements by class name
  const elements = document.getElementsByClassName(className);
  const visibleElements = [];

  // Check visibility of each element
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLElement;
    const style = window.getComputedStyle(element);
    const isVisible =
      element.offsetWidth > 0 &&
      element.offsetHeight > 0 &&
      style.display !== "none" &&
      style.visibility !== "hidden";

    if (isVisible) {
      visibleElements.push(element);
    }
  }

  return visibleElements;
};
