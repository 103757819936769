import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  cardContainer: {
    maxWidth: "270px",
    width: "100%",
    height: "351px",
    borderRadius: 16,
    border: "1px solid #E9EAEB",
    backgroundColor: '#F5F5F5',
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    overflow: "hidden",
    cursor: "pointer",
    userSelect: 'none'
  },
  cardTop: {
    padding: '12px 12px 0',
    height: '208px',
    position: 'relative',
  },
  cardTopXl: {
    height: '225px',
  },
  cardTopAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '24px',
  },
  cardPreview: {
    width: '217px',
    height: '217px',
    backgroundColor: '#FFF',
    margin: '0 auto',
    position: 'relative',
    top: '35px',
    borderRadius: '12px 12px 0 0',
    overflow: 'hidden',
  },
  cardPreviewHeader: {
    height: '20px',
  },
  cardPreviewContent: {
    padding: '38px 22px 0',
  },
  cardPreviewTitle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    color: '#262C37',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  cardDate: {
    fontSize: "11px",
    lineHeight: "135%",
    fontWeight: 400,
    color: "#6E737D",
  },
  cardMenuButton: {
    marginRight: 0,
    padding: 0
  },
  cardMenuButtonOpen: {
    background: '#FFF',
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 3px #12BAA51A'
  },
  cardBottom: {
    position: 'relative',
    height: "126px",
    padding: '16px 12px',
    backgroundColor: '#FFF',
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardBottomDescription: {
    height: '38px',
    fontSize: "14px",
    lineHeight: "135%",
    color: "#50555E",
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  cardBottomInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '32px'
  },
  cardBottomEmails: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#6E737D",
  },
  cardBottomCreator: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: '#EFF8FF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '15px',
    fontWeight: 400,
    color: '#175CD3',
  }
}));