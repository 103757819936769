import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  chipsBox: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    height: 44,
    borderRadius: 8,
    padding: '8px 36px 8px 12px',
    position: 'relative',
    marginTop: '24px',
  },
  chipsTitle: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '16px',
    color: '#6E737D',
  },
  chipsValue: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '16px',
    color: '#262C37',
    textTransform: 'capitalize',
    marginLeft: 4
  },
  chipsClose: {
    top: 12,
    right: 10,
    position: 'absolute',
    width: 20,
    height: 20,
    padding: 2
  }
}));
