import React, { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Prompt, useHistory } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../assets/icons/blue_checkmark.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
import TextField from "../../components/main/CustomFields/TextField";
import { clearStorages, getUrlAndParams, removeAllUrlParams, removeUrlParam } from "../../helpers/other";
import { getCampaignById, getCampaignByIdForReview, getCampaignEmails, regenerateEmail, saveAdditionalEmail, triggerEmailGenerate, updateCampaign, updateCampaignEmail } from "../../redux/services/tactical-outreach";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { trimTextByCharacters } from "../../helpers/text";
import SideBar from "./components/SideBar";
import ContentEditor from "./components/ContentEditor";
import { convertHTMLToState, convertMarkdownToHtml, convertStateToHTML, isHTML, isMarkdown } from "./helpers";
import LimitationWidget from "../../components/main/LimitationWidget";
import { observableService } from "../../services/observable";
import ReplyGenerator from './components/ReplyGenerator';
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { getProfile } from "../../redux/services/profile";
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForReviewer } from "../../helpers/roles";
import { notify } from "../../providers/notification";
import { deleteLibraryEmail, getCampaignComments } from "../../redux/services/my-library";
import { REVIEW_STATUS, REVIEW_STATUS_LABELS } from "../../utils/enums";
import { CampaignCategories, CampaignEmailRequest, CampaignEmailResponse, CampaignResponse, CampaignStatuses, CampaignSubCategory } from "../../interfaces/campaign";
import { ProfileResponse } from "../../interfaces/profile";
import pusher from "../../helpers/pusher";
import usePusherChannel from "../../hooks/usePusherChannel";
import GenerationLoading from "./components/GenerationLoading";
import { GenerationStatusType } from './types';
import useGetSubscription from "../../hooks/useGetSubscription";
import useGetProfile from "../../hooks/useGetProfile";

import { useStyles } from "./styles";

const EmailEditor = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<(CampaignResponse & { emails: Partial<CampaignEmailResponse> }) | null>(null);
  const [email, setEmail] = useState<Partial<CampaignEmailResponse> | null>(null);
  const [pageType, setPageType] = useState<'EDIT' | 'REVIEW' | 'VIEW'>(null);
  // const [replyTemplate, setReplyTemplate] = useState<any>(null);
  // const [unsavedDataModal, setUnsavedDataModal] = useState<{ active: boolean, targetEmail: number }>({
  //   active: false,
  //   targetEmail: null,
  // });
  const [editSubject, setEditSubject] = useState<{ value: string, field: string } | null>(null);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [editorState, setEditorState] = useState<any>(null);
  const [showGenerationFailed, setShowGenerationFailed] = useState<boolean>(false);
  // const [showStopGeneration, setShowStopGeneration] = useState<boolean>(false);
  // const [emailGeneratingProcessActive, setEmailGeneratingProcessActive] = useState<boolean>(false);
  // const [replyTemplateLoading, setReplyTemplateLoading] = useState<boolean>(false);
  const [loadingGenerationStatus, setLoadingGenerationStatus] = useState<GenerationStatusType>(GenerationStatusType.NONE);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  // const [isAauthomationActive, setIsAuthomationActive] = useState<boolean>(true);

  const history = useHistory();
  const classes = useStyles();
  const containerRef = useRef(null);
  const isAauthomationActive = useRef(true);
  const { setPusher, clearPusher, inProgress, isConnected } = usePusherChannel({
    onMessageReceived: async (data) => {
      setLoadingGenerationStatus(GenerationStatusType.NONE);

      const prevEmail: any = JSON.parse(sessionStorage.getItem("pusher_info"));
        
      sessionStorage.setItem(
        "pusher_info",
        JSON.stringify({
          ...prevEmail,
          content: `${prevEmail?.content || ''}` + data[0]
        })
      );

      if (isAauthomationActive.current) {
        setEmail((prevState: any) => {
          let content = `${prevState?.content || ''}`;
          content += data[0];
  
          return {
            ...prevState,
            content: content
          }
        });
      }
    },
    onConnectionClosed: async () => {
      await handleSaveEmail(true);
      const newCampaign = await handleGetCampaign(campaign.id, email.id);

      if (newCampaign.status === CampaignStatuses.INCOMPLETE && newCampaign?.emails?.length < newCampaign?.metaData?.totalEmails) {
        const channel_id = uuidv4();

        generateInitEmail(newCampaign.id, newCampaign.emails[newCampaign.emails.length - 1].id, channel_id);
      }

      sessionStorage.removeItem("pusher_info");
    },
    dependencies: [isAauthomationActive.current, email?.id]
  });

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    if (email?.id) {
      const { params } = getUrlAndParams();
      params.set('email_id', email.id as string);

      window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);

      // setReplyTemplate(null);
    }
  }, [email?.id]);

  useEffect(() => {
    let content = email?.content || '';

    if (!isHTML(content)) {
      content = convertMarkdownToHtml(content)
    }

    setEditorState(
      convertHTMLToState(content)
    );
  }, [email?.content]);

  useEffect(() => {
    sessionStorage.removeItem("pusher_info");

    return () => {
      sessionStorage.removeItem("pusher_info");
    };
  }, []);

  useEffect(() => {
    if (pageType && profile && subscription) {
      setLoading(true);

      const { params } = getUrlAndParams();
      const campaign_id = params.get('campaign_id');
      const email_id = params.get('email_id');
      const channel_id = params.get('channel_id');
  
      if (!campaign_id || campaign_id === 'null') {
        notify.error('Campaign not found');
  
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        if (channel_id) {
          setLoadingGenerationStatus(GenerationStatusType.SUBMITTING);
          cycleStatus();
  
          generateInitEmail(campaign_id, email_id, channel_id, !!email_id);
        } else {
          handleGetCampaign(campaign_id, email_id).then(campaign => {
            if (campaign && campaign.status !== CampaignStatuses.COMPLETED) {
              const { params } = getUrlAndParams();
              const channel_id = params.get('channel_id');
  
              if (!channel_id) {
                setShowGenerationFailed(true);
              }
            }
          })
        }
      }
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
  
        clearPusher();
      };
    }
  }, [pageType, profile, subscription]);

  // Clean up the controller when tab or chat changed
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  useEffect(() => {
    setLeavingModal({
      active: false,
      nextLocation: null
    })
  }, [inProgress]);

  useEffect(() => {
    if (subscription) {
      if (window.location.pathname.includes('/view/')) {
        setPageType('VIEW');
      } else if (window.location.pathname.includes('/edit/')) {
        setPageType('EDIT');
      } else if (window.location.pathname.includes('/review/') && (showViewForReviewer(subscription) || showViewForAdmin(subscription))) {
        setPageType('REVIEW');
      } else {
        setLoading(true);

        notify.error('Forbidden access');

        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    }
  }, [subscription]);

  const generateInitEmail = async (campaign_id, email_id, channel_id, regenerate = false) => {
    const campaign = await handleGetCampaign(campaign_id, email_id);

    if (isAauthomationActive.current) {
      setEmail({
        subject: '',
        content: '',
        status: 'PENDING'
      });
    }

    if (!regenerate) {
      setCampaign({
        ...campaign,
        emails: [
          ...campaign.emails.filter(item => item.status === 'COMPLETED'),
          {
            subject: '',
            content: '',
            status: 'PENDING'
          } as any
        ]
      })
    }

    handleSubscribeOnChannel(channel_id);

    await handleTriggerGenerateEmail(campaign, email_id, channel_id, regenerate);
  }

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetCampaign = async (campaignId, emailId) => {
    const { data: campaign, error: campaignError } = pageType === 'REVIEW'
      ? await getCampaignByIdForReview(campaignId)
      : await getCampaignById(campaignId)
    const { data: emails, error: emailsError } = pageType === 'REVIEW'
      ? {
          data: []
        }
      : await getCampaignEmails(campaignId)
    const { data: comments, error: commentsError } = isStartUpTeamsSubscription(subscription) && campaign?.reviewStatus !== REVIEW_STATUS.DRAFT && campaign?.reviewStatus !== REVIEW_STATUS.APPROVED
      ? await getCampaignComments(campaignId) 
      : {
          data: []
        }

    if (!campaign || campaignError) {
      notify.error('Campaign not found');

      setTimeout(() => {
        history.goBack();
      }, 500);

      return null;
    } else {
      const newCampaign = {
        ...campaign,
        emails: pageType === 'REVIEW'
          ? campaign.emails
          : emails && !emailsError ? emails : [],
        comments: comments && !commentsError ? comments : []
      };
      setCampaign(newCampaign);
      
      if (isAauthomationActive.current) {
        // Determine the target email
        const currentEmail = newCampaign?.emails?.find(item => item.id === emailId) || newCampaign.emails[newCampaign.emails?.length - 1];

        setEmail(currentEmail);
      }

      setLoading(false);

      return newCampaign;
    }
  }

  const handleSubscribeOnChannel = (channel_id) => {
    const channel = pusher.subscribe(channel_id);

    setPusher(channel);
  }

  const handleTriggerGenerateEmail = async (campaign, email_id, channel_id, regenerate = false) => {
    // setLoading(true);

    const controller = new AbortController();
    setAbortController(controller);

    const { data, error } = regenerate
      ? await regenerateEmail({
        type: campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS
          ? 'multi-touch'
          : 'single-touch',
        emailId: email_id
      })
      : await triggerEmailGenerate({
        id: channel_id,
        campaignId: campaign.id,
        type: campaign.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS
          ? 'multi-touch'
          : 'single-touch',
        signal: controller.signal
      });

    if (data && !error) {
      if (!campaign?.emails?.length) {
        setLoadingGenerationStatus(GenerationStatusType.READY);
      }

      sessionStorage.setItem(
        "pusher_info",
        JSON.stringify({
          ...data,
          content: ''
        })
      );

      if (isAauthomationActive.current) {
        setEmail({
          ...data,
          content: ''
        })
      }
    } else {
      clearPusher();
      setLoadingGenerationStatus(GenerationStatusType.NONE);

      if (!error?.error?.includes('AbortError')) {
        notify.error('Failed to request Ai');
      }

      if (!regenerate) {
        if (campaign.emails.filter(item => item.status === 'COMPLETED').length) {
          if (isAauthomationActive.current) {
            const lastCompletedEmail = campaign.emails.find(item => item.status === 'COMPLETED');
  
            setEmail(lastCompletedEmail);
          }
  
          setCampaign({
            ...campaign,
            emails: [
              ...campaign.emails.filter(item => item.status === 'COMPLETED'),
              {
                subject: '',
                content: '',
                status: 'FAILED'
              }
            ]
          });
        } else {
          setTimeout(() => {
            history.push(`/edit/campaign/builder?campaign_id=${campaign.id}`);
          }, 500);
        }
      }
    }

    removeUrlParam('channel_id', true);

    // setLoading(false);
  }

  const handleSaveEmail = async (isShadowSave = false, status = 'COMPLETED') => {
    setLoading(!isShadowSave);

    const pusherInfo: any = JSON.parse(
      sessionStorage.getItem("pusher_info")
    );

    const { data, error } = email.status === 'FAKE'
      ? await saveAdditionalEmail({
        campaignId: campaign.id as string,
        subject: email.subject,
        content: convertStateToHTML(editorState)
      })
      : await updateCampaignEmail({
          body: {
            ...(pusherInfo || email),
            content: pusherInfo ? pusherInfo.content : convertStateToHTML(editorState),
            status,
          } as CampaignEmailRequest,
          id: pusherInfo ? pusherInfo.id : email.id
        });

    if (data && !error) {
      // handleGetCampaign(data.campaignId, data.id);

      if (!isShadowSave) {
        notify.success(
          email.status === 'FAKE'
            ? 'Email saved successfully'
            : 'Changes saved successfully'
        );
      }
    } else {
      clearPusher();

      if (!isShadowSave) {
        notify.error('Failed to save changes');
      }

      setLoading(false);
    }
  }

  const handleSaveEdit = async () => {
    if (
      (editSubject.field === 'name' && editSubject.value.trim().length >= 60) ||
      (editSubject.field === 'description' && editSubject.value.trim().length >= 1000)
    ) {
      notify.error(`Max ${editSubject.field === 'name' ? '60' : '1000'} characters`);
    } else {
      const { error } = await updateCampaign({
        body: editSubject.field === 'name'
          ? {
            name: editSubject.value.trim().length ? editSubject.value : campaign.name,
            description: campaign.description,
          }
          : {
            name: campaign.name,
            description: editSubject.value,
          },
        id: campaign.id
      });

      if (!error) {
        handleGetCampaign(campaign.id, email.id);

        // notify.success('Campaign edited successfully');
      } else {
        notify.error('Failed to save name and description');
      }

      setEditSubject(null);
    }
  }

  const isSaveDisabled = () => {
    if (campaign && email) {
      if (email.status === 'FAILED') {
        return true;
      } else if (campaign?.emails?.length) {
        const originalEmail = {
          subject: campaign.emails.find(item => item.id === email.id)?.subject || '',
          content: campaign.emails.find(item => item.id === email.id)?.content || ''
        }

        originalEmail.content = isHTML(originalEmail.content)
          ? originalEmail.content
          : convertMarkdownToHtml(originalEmail.content)

        const currentEmail = {
          subject: email.subject,
          content: convertStateToHTML(editorState),
        }

        return JSON.stringify(originalEmail) === JSON.stringify(currentEmail);
      }
    } else {
      return true;
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation: nextLocation.pathname + nextLocation.search
    })

    return false;
  };

  const cycleStatus = useCallback(() => {
    const statusList = [
      // GenerationStatusType.SUBMITTING,
      GenerationStatusType.SAVING,
      GenerationStatusType.GENERATION,
      GenerationStatusType.OPTIMISING
    ];
    let index = 0;
  
    const interval = setInterval(() => {
      index = (index + 1) % statusList.length;
      const newStatus = statusList[index];

      setLoadingGenerationStatus(prevStatus => (
        prevStatus !== GenerationStatusType.NONE
          ? newStatus
          : prevStatus
      ));

      if (
        newStatus === GenerationStatusType.OPTIMISING ||
        newStatus === GenerationStatusType.READY ||
        newStatus === GenerationStatusType.NONE
      ) {
        clearInterval(interval); // Stop when reaching OPTIMISING
        // setLoadingGenerationStatus(GenerationStatusType.NONE);
      }
    }, 8000);
  }, [loadingGenerationStatus, setLoadingGenerationStatus]);

  return (
    <>
      <Prompt
        when={inProgress || isConnected}
        message={(location) => handleBlockedNavigation(location)}
      />

      <GenerationLoading
        loadingGenerationStatus={loadingGenerationStatus}
      />

      <Backdrop
        style={{ color: '#fff', zIndex: 999999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.wrapper}>
        <div className={classes.container} ref={containerRef}>
          {campaign && email && (
            <Box className={classes.header}>
              <Box
                className={classes.innerHeader}
                style={{
                  minHeight: editSubject ? '100px' : 'fit-content'
                }}
              >
                <Box className={classes.nameBlock}>
                  {editSubject?.field === 'name'
                    ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Name'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                        onBlur={handleSaveEdit}
                        onKeyPress={(event) => {
                          if ((event.code === 'Enter' || event.key === 'Enter') && !event.shiftKey) {
                            handleSaveEdit()
                          }
                        }}
                      />
                    </Box>
                    : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                        {trimTextByCharacters(campaign.name, 60)}
                      </Typography>
                      {(!isStartUpTeamsSubscription(subscription) || (pageType === 'EDIT' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED)) && (
                        <IconButton
                          size="small"
                          disabled={inProgress || isConnected || campaign.status === CampaignStatuses.INCOMPLETE}
                          onClick={() => {
                            setEditSubject({
                              field: 'name',
                              value: campaign.name
                            })
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  }
                  {editSubject?.field === 'description'
                    ? <Box style={{ width: '80%', display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Description'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                        onBlur={handleSaveEdit}
                        onKeyPress={(event) => {
                          if ((event.code === 'Enter' || event.key === 'Enter') && !event.shiftKey) {
                            handleSaveEdit()
                          }
                        }}
                      />
                      {/* <IconButton
                          size='small'
                          onClick={handleSaveEdit}
                        >
                          <BlueCheckmarkIcon />
                        </IconButton> */}
                    </Box>
                    : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <Typography noWrap style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                        {trimTextByCharacters(campaign.description, 1000)}
                      </Typography>
                      {(!isStartUpTeamsSubscription(subscription) || (pageType === 'EDIT' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED)) && (
                        <IconButton
                          size='small'
                          disabled={inProgress || isConnected || campaign.status === CampaignStatuses.INCOMPLETE}
                          onClick={() => {
                            setEditSubject({
                              field: 'description',
                              value: campaign.description
                            })
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  }
                </Box>
                <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                  {isStartUpTeamsSubscription(subscription) && (
                    <Box className={classes.status}>
                      <Typography noWrap style={{ fontSize: '16px', fontWeight: '500', lineHeight: '18.6px', color: '#50555E' }}>
                        {REVIEW_STATUS_LABELS[campaign.reviewStatus]}
                      </Typography>
                    </Box>
                  )}
                  {campaign.type && (
                    <Box className={classes.tag}>
                      <Box
                        className={classes.tagDot}
                        style={{
                          background: campaign.category === CampaignCategories.MARKETING
                            ? '#57BE99'
                            : campaign.category === CampaignCategories.SALES
                              ? '#D93855'
                              : '#3A84C9'
                        }}
                      />
                      <Typography noWrap style={{ fontSize: '16px', fontWeight: '500', lineHeight: '18.6px', color: '#50555E' }}>
                        {campaign.type.toLowerCase()?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '20px' }}>
                  <Button
                    data-test-id="Edit parameters"
                    variant="outlined"
                    color="secondary"
                    startIcon={<EditIcon />}
                    disabled={inProgress || isConnected || email.status === 'FAKE' || campaign.status === CampaignStatuses.INCOMPLETE}
                    style={{
                      whiteSpace: 'nowrap'
                    }}
                    onClick={() => {
                      const mode = !isStartUpTeamsSubscription(subscription)
                        ? 'edit'
                        : pageType === 'EDIT' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED
                          ? 'edit'
                          : 'view'

                      history.push(`/${mode}/campaign/builder?campaign_id=${campaign.id}&target_email_id=${email.id}`);
                    }}
                  >
                    {!isStartUpTeamsSubscription(subscription)
                      ? 'Edit parameters'
                      : pageType === 'EDIT' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED
                        ? 'Edit parameters'
                        : 'View parameters'
                    }
                  </Button>
                  <LimitationWidget
                    type='TACTICAL_OUTREACH'
                  />
                  {!sideBarOpen && (
                    <>
                      &nbsp;
                      &nbsp;
                      &nbsp;
                      <IconButton onClick={() => setSideBarOpen(true)}>
                        <SideBarOpenIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
          )}

          {campaign && email && (
            <>
              <ContentEditor
                campaign={campaign}
                email={email}
                profile={profile}
                editorState={editorState}
                subscription={subscription}
                inProgress={inProgress || isConnected}
                pageType={pageType}
                isAauthomationActive={isAauthomationActive.current}
                loadingGenerationStatus={loadingGenerationStatus}
                setEmail={setEmail}
                isSaveDisabled={isSaveDisabled}
                handleSaveEmail={handleSaveEmail}
                handleGetCampaign={handleGetCampaign}
                setEditorState={setEditorState}
                setLoading={setLoading}
                setPusher={setPusher}
                clearPusher={clearPusher}
                setIsAuthomationActive={(isActive) => isAauthomationActive.current = isActive}
                setLoadingGenerationStatus={setLoadingGenerationStatus}
                cycleStatus={cycleStatus}
              />

              {pageType === 'REVIEW' && campaign?.owner && (
                <Box className={classes.historyHeaderBottomRight}>
                  <Box className={classes.owner}>
                    <Box className={classes.ownerAvatar}>
                      {campaign?.owner?.name?.slice(0, 1).toUpperCase()}
                    </Box>
                    <Box className={classes.ownerInfo}>
                      <Typography className={classes.ownerTitle}>Created by:</Typography>
                      <Typography className={classes.ownerName}>{campaign?.owner?.name}</Typography>
                    </Box>
                  </Box>
                </Box>
              )}

              {email.status !== 'FAKE' &&
                !inProgress &&
                !isConnected &&
                campaign.status === CampaignStatuses.COMPLETED &&
                campaign.reviewStatus === REVIEW_STATUS.APPROVED &&
                (
                  <ReplyGenerator
                    email={email}
                    containerRef={containerRef}
                  />
                )}
            </>
          )}
        </div>
        {campaign && email && (
          <SideBar
            open={sideBarOpen}
            email={email}
            campaign={campaign}
            profile={profile}
            subscription={subscription}
            inProgress={inProgress || isConnected}
            pageType={pageType}
            isSaveDisabled={isSaveDisabled}
            setSideBarOpen={setSideBarOpen}
            setEmail={setEmail}
            setCampaign={setCampaign}
            handleGetCampaign={handleGetCampaign}
            setLoading={setLoading}
            setPusher={setPusher}
            clearPusher={clearPusher}
            setEditorState={setEditorState}
            setIsAuthomationActive={(isActive) => isAauthomationActive.current = isActive}
            generateInitEmail={generateInitEmail}
          />
        )}
      </Box>

      {/* <ConfirmationModal
        open={unsavedDataModal.active}
        title={"You have unsaved data!"}
        description={"Do you want to save latest changes?"}
        onClose={() => setUnsavedDataModal({ active: false, targetEmail: null })}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => {
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
        onConfirm={async () => {
          await handleSaveEmail()
          await handleGetCampaign(campaign.id, email.id)

          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
      /> */}

      <ConfirmationModal
        open={leavingModal.active}
        title={'This action will stop process!'}
        description={'Are you sure you want to stop the process and leave the page?'}
        onClose={() => setLeavingModal({
          active: false,
          nextLocation: null
        })}
        reversedButtons
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        onReject={() => {
          clearPusher();

          setLeavingModal({
            ...leavingModal,
            active: false,
          });

          setTimeout(() => {
            const pusherInfo: any = JSON.parse(
              sessionStorage.getItem("pusher_info")
            );
          
            if (pusherInfo) {
              deleteLibraryEmail({
                campaignId: pusherInfo.campaignId,
                emailId: pusherInfo.id
              });
            }

            history.push(leavingModal.nextLocation);
          }, 500);
        }}
        onConfirm={() => {
          setLeavingModal({
            active: false,
            nextLocation: null
          });
        }}
      />

      <ConfirmationModal
        open={showGenerationFailed}
        title={"This campaign isn't completed yet"}
        description={"Continue to generate?"}
        onClose={() => {
          setShowGenerationFailed(false);

          setCampaign({
            ...campaign,
            emails: [
              ...campaign.emails.filter(item => item.status === 'COMPLETED'),
              {
                subject: '',
                content: '',
                status: 'FAILED'
              } as any
            ]
          })
        }}
        reversedButtons
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        onConfirm={() => {
          setShowGenerationFailed(false);

          setCampaign({
            ...campaign,
            emails: [
              ...campaign.emails.filter(item => item.status === 'COMPLETED'),
              {
                subject: '',
                content: '',
                status: 'FAILED'
              } as any
            ]
          })
        }}
        onReject={async () => {
          setShowGenerationFailed(false);

          isAauthomationActive.current = true;
          
          const lastFailedEmail = campaign.emails.find(item => !item.content?.trim()?.length);
          const channel_id = uuidv4();

          if (lastFailedEmail) {
            await deleteLibraryEmail({
              campaignId: campaign.id as string,
              emailId: lastFailedEmail.id as string
            });
            generateInitEmail(campaign.id, null, channel_id);
          } else {
            generateInitEmail(campaign.id, email.id, channel_id);
          }
        }}
      />

      {/* <ConfirmationModal
        open={showStopGeneration}
        title={"Action Not Allowed"}
        description={'Please generate all emails before continuing.'}
        onClose={() => setShowStopGeneration(false)}
        // confirmBtnText={"Close"}
        rejectBtnText={campaign?.emails?.some(item => item.status !== 'COMPLETED')
          ? "Ok"
          : "Continue generation"
        }
        onReject={() => {
          if (campaign?.emails?.some(item => item.status !== 'COMPLETED')) {
            setShowStopGeneration(false);
          } else {
            setShowStopGeneration(false);
            handleGetCampaign(campaign.id, email.id);
          }
        }}
      // onConfirm={() => setShowStopGeneration(false)}
      /> */}
    </>
  );
};

export default EmailEditor;
