import React from 'react';
import { Box } from '@material-ui/core';
import { INTELLIGENCE_TYPES } from "../../../../utils/enums";

import { useStyles } from "./styles";

interface Props {
    value: string;
    expanded?: boolean;
}

const CopilotTypes: React.FC<Props> = ({ value, expanded }) => {
    const classes = useStyles();

    const typeData = INTELLIGENCE_TYPES.find(type => type.value === value) || null;

    const typeClass = typeData?.value === 'GENERAL'
        ? classes.typeGeneral
        : classes.typeIntelligence;

    return (
        <Box
            className={`${classes.typesContainer} ${typeClass}`}
            style={{ backgroundColor: expanded ? '#ebebeb' : undefined }}
        >
            {typeData?.label}
        </Box>
    );
};

export default CopilotTypes;
