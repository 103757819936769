import React, { useEffect, useRef, useState } from "react";
import pusher from "../../../../helpers/pusher";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { trimTextByCharacters } from "../../../../helpers/text";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { getCampaignEmails, triggerEmailGenerate } from "../../../../redux/services/tactical-outreach";
import { observableService } from "../../../../services/observable";
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reload_icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as CommentsIcon } from '../../../../assets/icons/comments.svg';
import { ReactComponent as SideBarOpenIcon } from '../../../../assets/icons/right_bar_icon_2.svg';
import EmailsList from './components/EmailsList';
import CommentsList from './components/CommentsList';
import { REVIEW_STATUS } from "../../../../utils/enums";
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForEditor, showViewForReviewer } from "../../../../helpers/roles";
import { notify } from "../../../../providers/notification";
import { getCampaignComments } from "../../../../redux/services/my-library";
import { CampaignStatuses, CampaignSubCategory, CampaignTypes } from "../../../../interfaces/campaign";

import { useStyles } from "./styles";

const SideBar = ({
  open,
  email,
  campaign,
  profile,
  subscription,
  inProgress,
  pageType,
  isSaveDisabled,
  setSideBarOpen,
  setEmail,
  setCampaign,
  handleGetCampaign,
  setLoading,
  setPusher,
  clearPusher,
  setEditorState,
  setIsAuthomationActive,
  generateInitEmail
}) => {
  const [tab, setTab] = useState<'emails' | 'comments'>('emails');
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [sideBarWidth, setSideBarWidth] = useState<number>(270);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  const history = useHistory();
  const classes = useStyles();
  const sideBarContent = useRef<any>(null);

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseOver]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseDown]);

  useEffect(() => {
    // handleGetCamaignComments();
    // handleGetCamaignEmails();

    scrollToBottom();
  }, [tab, open]);

  useEffect(() => {
    setSideBarWidth(270);
  }, [open]);

  // Clean up the controller when tab or chat changed
  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  // const handleGetCamaignComments = async () => {
  //   const { data, error } = await getCampaignComments(campaign.id);

  //   if (data && !error) {
  //     setCampaign({
  //       ...campaign,
  //       comments: data
  //     });
  //   }
  // }

  // const handleGetCamaignEmails = async () => {
  //   const { data, error } = await getCampaignEmails(campaign.id);

  //   if (data && !error) {
  //     setCampaign({
  //       ...campaign,
  //       emails: [...data].sort((a, b) => a.order - b.order)
  //     });
  //   }
  // }

  const handleAddNewEmail = async () => {
    if (!campaign.emails.find(item => item.status === 'FAKE')) {
      setIsAuthomationActive(true);
      setEditorState(null);

      const uuid = uuidv4();

      setEmail({
        id: uuid,
        subject: '',
        content: '',
        status: 'FAKE'
      });
      setCampaign({
        ...campaign,
        emails: [
          ...campaign.emails.filter(item => item.status !== 'FAKE'),
          {
            id: uuid,
            subject: '',
            content: '',
            status: 'FAKE',
            order: 9999999
          }
        ]
      });
    }
  }

  const handleAddNewComment = () => {
    if (!campaign.comments.find(item => item.status === "NEW")) {
      setCampaign({
        ...campaign,
        comments: [
          ...campaign.comments,
          {
            id: uuidv4(),
            status: "NEW",
          }
        ]
      })

      scrollToBottom();
    }
  }

  const scrollToBottom = () => {
    if (sideBarContent?.current) {
      setTimeout(() => {
        sideBarContent.current.scrollTo({
          top: sideBarContent.current.scrollHeight - 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const handleMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.id === 'resizeController') {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  }

  const handleMouseDown = (event) => {
    if (isMouseOver) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.id === 'resizeController') {
        setIsMouseDown(true);
      } else {
        setIsMouseDown(false);
      }
    }
  }

  const handleMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsMouseDown(false);
  }

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      const newWidth = window.innerWidth - event.x;

      if (newWidth >= 270 && newWidth <= window.innerWidth / 3) {
        setSideBarWidth(newWidth);
      }
    }
  }

  return (
    <>
      <Box
        className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}
        style={{
          width: open ? sideBarWidth : '0',
          minWidth: open ? sideBarWidth : '0',
          maxWidth: open ? sideBarWidth : '0',
          transition: isMouseDown ? 'none' : 'all .3s',
        }}
      >
        <Box
          id={'resizeController'}
          className={classes.resizeController}
        />
        <Box
          className={classes.sideBarCloseButton}
          style={{
            opacity: inProgress ? '0.7' : '1',
            pointerEvents: inProgress ? 'none' : 'all',
          }}
        >
          {campaign.reviewStatus === REVIEW_STATUS.REJECTED ||
            (pageType === 'EDIT' && (campaign.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED || campaign.reviewStatus === REVIEW_STATUS.PENDING_REVIEW)) ||
            pageType === 'REVIEW'
            ? <Box className={classes.sideBarNavigationButtons}>
              <Box
                className={`${classes.sideBarNavigationButton} ${tab === 'emails' ? classes.sideBarNavigationButtonActive : ''}`}
                onClick={() => {
                  if (campaign.status === CampaignStatuses.COMPLETED) {
                    setTab('emails')
                  }
                }}
              >
                <SideBarOpenIcon
                  height={20}
                />
              </Box>
              <Box
                className={`${classes.sideBarNavigationButton} ${tab === 'comments' ? classes.sideBarNavigationButtonActive : ''}`}
                onClick={() => {
                  if (campaign.status === CampaignStatuses.COMPLETED) {
                    setTab('comments')
                  }
                }}
              >
                <CommentsIcon
                  height={20}
                />
              </Box>
            </Box>
            : <Box />
          }
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <div
          ref={sideBarContent}
          className={classes.sideBarContent}
          style={{
            justifyContent: tab === 'comments' && !campaign.comments.length ? 'center' : 'flex-start'
          }}
        >
          {tab === 'emails'
            ? <EmailsList
              campaign={campaign}
              email={email}
              setEmail={setEmail}
              setCampaign={setCampaign}
              setLoading={setLoading}
              handleGetCampaign={handleGetCampaign}
              isSaveDisabled={isSaveDisabled}
              inProgress={inProgress}
              setIsAuthomationActive={setIsAuthomationActive}
              generateInitEmail={generateInitEmail}
            />
            : <CommentsList
              campaign={campaign}
              email={email}
              profile={profile}
              subscription={subscription}
              handleGetCampaign={handleGetCampaign}
              setCampaign={setCampaign}
              inProgress={inProgress}
              handleAddNewComment={handleAddNewComment}
            />
          }
        </div>

        {tab === 'comments'
          ? !!campaign.comments.filter(item => item.status === 'OPEN').length && (pageType === 'REVIEW' || (pageType === 'EDIT' && (campaign.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED || campaign.reviewStatus === REVIEW_STATUS.PENDING_REVIEW))) && (
            <Button
              variant="contained"
              color="primary"
              disabled={inProgress || campaign.status === CampaignStatuses.INCOMPLETE}
              startIcon={<>+</>}
              onClick={() => handleAddNewComment()}
            >
              Add comment
            </Button>
          )
          : (
              campaign.subCategory !== CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS &&
              (
                !isStartUpTeamsSubscription(subscription) || 
                (pageType === 'EDIT' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED)
              )
            ) && (
            <Button
              variant="contained"
              color="primary"
              disabled={inProgress || campaign.status === CampaignStatuses.INCOMPLETE}
              startIcon={<>+</>}
              onClick={() => handleAddNewEmail()}
            >
              New email
            </Button>
          )
        }
      </Box>
    </>
  );
};

export default SideBar;
