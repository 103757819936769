import { api, createApiCall } from "../api";
import {
  Chat,
  ChatRequest,
  ChatSimplified,
  CreateChatRequest,
  GetChatByIdRequest,
  LikeDislikeRequest,
  SendChatMessageRequest,
  SendChatMessageResponse,
  SnippetCommentResponse,
  SnippetResponse
} from "../../interfaces/copilot";
import { REVIEW_STATUS } from '../../utils/enums';

const copilotAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getChats: builder.query<ChatSimplified[], string>({
      query: (tab) => ({
        url: `/v1/chatbot/${tab}`,
        method: "GET"
      })
    }),
    getChatById: builder.query<Chat, { id: string; tab: string }>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "GET"
      })
    }),
    createChat: builder.mutation<
      Chat,
      CreateChatRequest
    >({
      query: ({ body, tab, signal }) => ({
        url: `/v1/chatbot/${tab}/trigger`,
        method: "POST",
        body,
        signal
      })
    }),
    sendChatQuestion: builder.mutation<
      SendChatMessageResponse,
      SendChatMessageRequest
    >({
      query: ({ body, sessionId, tab, signal }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/trigger`,
        method: "PUT",
        body,
        signal
      })
    }),
    deleteChat: builder.mutation<void, { id: string; tab: string }>({
      query: ({ id, tab }) => ({
        url: `/v1/chatbot/${tab}/${id}`,
        method: "DELETE"
      })
    }),
    likeMessage: builder.mutation<
      void,
      { sessionId: string; messageId: string; tab: string }
    >({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/like/${messageId}`,
        method: "POST"
      })
    }),
    dislikeMessage: builder.mutation<
      void,
      { sessionId: string; messageId: string; tab: string }
    >({
      query: ({ sessionId, messageId, tab }) => ({
        url: `/v1/chatbot/${tab}/${sessionId}/dislike/${messageId}`,
        method: "POST"
      })
    }),
    saveMessageToLibrary: builder.mutation<
      void,
      { body, saveAsModificator: 'single-user' | 'pending-review' | 'draft' | 'approved' }
    >({
      query: ({ body, saveAsModificator }) => ({
        url: `/v1/chatbot/chat-intelligence/${saveAsModificator}`,
        method: "POST",
        body
      })
    }),
    getSnippetById: builder.mutation<
      SnippetResponse,
      { id, modificator: 'single-user' | 'multi-user' }
    >({
      query: ({ id, modificator }) => ({
        url: `/v1/library/chat-intelligence/${id}/${modificator}`,
        method: "GET"
      })
    }),
    changeSnippetReviewStatus: builder.mutation<
      void,
      { id: string, status: keyof typeof REVIEW_STATUS }
    >({
      query: ({ id, status }) => ({
        url: `/v1/library/chat-intelligence/${id}/status`,
        method: "PUT",
        body: {
          status
        }
      })
    }),
    sendSnippetToReview: builder.mutation<
      void,
      string
    >({
      query: (id) => ({
        url: `/v1/library/chat-intelligence/${id}/review`,
        method: "PUT"
      })
    }),
    getSnippetComments: builder.mutation<
      SnippetCommentResponse[],
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/v1/library/chat-intelligence/comments?chatIntelligenceId=${id}`,
        method: "GET"
      })
    }),
    sendSnippetComment: builder.mutation<void, { chatIntelligenceId: string, text: string }>({
      query: (body) => ({
        url: `/v1/library/chat-intelligence/comments`,
        method: "POST",
        body
      })
    }),
    replyForSnippetComment: builder.mutation<void, { body: { text: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/chat-intelligence/comments/${commentId}/reply`,
        method: "POST",
        body
      })
    }),
    saveSnippetContent: builder.mutation<void, { body: { name: string, description: string, text: string }, id: string, modificator: 'single-user' | 'multi-user' }>({
      query: ({ body, id, modificator }) => ({
        url: `/v1/library/chat-intelligence/${id}/${modificator}`,
        method: "PUT",
        body
      })
    }),
    changeSnippetCommentStatus: builder.mutation<void, { body: { status: string }, commentId: string }>({
      query: ({ body, commentId }) => ({
        url: `/v1/library/chat-intelligence/comments/${commentId}`,
        method: "PUT",
        body
      })
    }),
    changeChatName: builder.mutation<void, { body: { title: string }, chatId: string, modificator: 'general' | 'intelligence' }>({
      query: ({ body, chatId, modificator }) => ({
        url: `/v1/chatbot/${modificator}/rename/${chatId}`,
        method: "PUT",
        body
      })
    }),
    saveCopilotAnswer: builder.mutation<void, { body: { answer: string, status: 'COMPLETED' | 'FAILED' }, chatId: string, messageId: string, modificator: 'general' | 'intelligence' }>({
      query: ({ body, chatId, messageId, modificator }) => ({
        url: `/v1/chatbot/${modificator}/${chatId}/messages/${messageId}`,
        method: "PUT",
        body
      })
    }),
    getFollowUpQuestions: builder.mutation<string[], { sessionId: string, modificator: 'general' | 'intelligence', signal: AbortSignal }>({
      query: ({ sessionId, modificator, signal }) => ({
        url: `/v1/chatbot/${modificator}/${sessionId}/followup-questions`,
        method: "GET",
        signal
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getChats = createApiCall<ChatSimplified[], string>(
  copilotAPI.endpoints.getChats
);
export const getChatById = createApiCall<Chat, GetChatByIdRequest>(
  copilotAPI.endpoints.getChatById
);
export const createChat = createApiCall<Chat, CreateChatRequest>(
  copilotAPI.endpoints.createChat
);
export const sendChatQuestion = createApiCall<
  SendChatMessageResponse,
  SendChatMessageRequest
>(copilotAPI.endpoints.sendChatQuestion);
export const deleteChat = createApiCall<void, GetChatByIdRequest>(
  copilotAPI.endpoints.deleteChat
);
export const likeMessage = createApiCall<void, LikeDislikeRequest>(
  copilotAPI.endpoints.likeMessage
);
export const dislikeMessage = createApiCall<void, LikeDislikeRequest>(
  copilotAPI.endpoints.dislikeMessage
);
export const saveMessageToLibrary = createApiCall<void, { body, saveAsModificator: 'single-user' | 'pending-review' | 'draft' | 'approved' }>(
  copilotAPI.endpoints.saveMessageToLibrary
);
export const getSnippetById = createApiCall<SnippetResponse, { id, modificator: 'single-user' | 'multi-user' }>(
  copilotAPI.endpoints.getSnippetById
);
export const changeSnippetReviewStatus = createApiCall<void, { id: string, status: keyof typeof REVIEW_STATUS }>(
  copilotAPI.endpoints.changeSnippetReviewStatus
);
export const sendSnippetToReview = createApiCall<void, string>(
  copilotAPI.endpoints.sendSnippetToReview
);
export const getSnippetComments = createApiCall<SnippetCommentResponse[], { id: string }>(
  copilotAPI.endpoints.getSnippetComments
);
export const sendSnippetComment = createApiCall<void, { chatIntelligenceId: string, text: string }>(
  copilotAPI.endpoints.sendSnippetComment
);
export const replyForSnippetComment = createApiCall<void, { body: { text: string }, commentId: string }>(
  copilotAPI.endpoints.replyForSnippetComment
);
export const saveSnippetContent = createApiCall<void, { body: { name: string, description: string, text: string }, id: string, modificator: 'single-user' | 'multi-user' }>(
  copilotAPI.endpoints.saveSnippetContent
);
export const changeSnippetCommentStatus = createApiCall<void, { body: { status: string }, commentId: string }>(
  copilotAPI.endpoints.changeSnippetCommentStatus
);
export const changeChatName = createApiCall<void, { body: { title: string }, chatId: string, modificator: 'general' | 'intelligence' }>(
  copilotAPI.endpoints.changeChatName
);
export const saveCopilotAnswer = createApiCall<void, { body: { answer: string, status: 'COMPLETED' | 'FAILED' }, chatId: string, messageId: string, modificator: 'general' | 'intelligence' }>(
  copilotAPI.endpoints.saveCopilotAnswer
);
export const getFollowUpQuestions = createApiCall<string[], { sessionId: string, modificator: 'general' | 'intelligence', signal: AbortSignal }>(
  copilotAPI.endpoints.getFollowUpQuestions
);