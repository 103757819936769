import React from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { validationSchema } from './validations';
import { Form, Formik } from 'formik';
import TextField from "../../../../components/main/CustomFields/TextField";

import { useStyles } from './styles';

interface Props {
  open: boolean;
  title: string | Element | React.ReactElement;
  element: any;
  rejectBtnText?: string;
  confirmBtnText?: string;
  width?: string;
  onClose: () => void;
  onConfirm?: (value: any) => void;
}

const RenameModal: React.FC<Props> = (props) => {
  const {
    open,
    title,
    element,
    rejectBtnText,
    confirmBtnText,
    onClose,
    onConfirm,
  } = props;

  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      {element && (
        <Formik
          initialValues={{ 
            name: element?.name,
            description: element?.description || ''
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          validateOnBlur={false}
          isInitialValid={false}
          onSubmit={(values: any) => onConfirm(values)}>
          {({
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form className={classes.modalBox}>
              <Box className={classes.modalHeader}>
                <Typography className={classes.title}>
                  {title}
                </Typography>
                <IconButton onClick={onClose} className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box className={classes.form}>
                <TextField
                  name={'name'}
                  label={'Title'}
                  required
                  maxLength={60}
                  value={values['name']}
                  placeholder={'Enter title'}
                  error={(touched['name'] && errors['name']) as string}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                />
                <TextField
                  name={'description'}
                  label={'Description'}
                  type={'textarea'}
                  maxLength={1000}
                  value={values['description']}
                  placeholder={'Enter description'}
                  error={(touched['description'] && errors['description']) as string}
                  onChange={(event) => setFieldValue('description', event.target.value)}
                />
              </Box>
              <Box className={classes.buttonContainer}>
                {rejectBtnText && (
                  <Button
                    onClick={onClose}
                    variant="outlined"
                    className={classes.rejectButton}
                  >
                    {rejectBtnText}
                  </Button>
                )}
                {confirmBtnText && (
                  <Button
                    type="submit"
                    className={classes.confirmButton}
                    disabled={!values['name']}
                  >
                    {confirmBtnText}
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default RenameModal;
