import { useEffect } from 'react';
import { Settings, DateTime } from 'luxon';
import { HALF_DAY_TIMEZONES } from '../utils/timezones';

export const TimezoneProvider = ({ children }) => {
  useEffect(() => {
    const originalToFormat = DateTime.prototype.toFormat;

    DateTime.prototype.toFormat = function (format) {
      // Check if the format string contains a time pattern (like 'H', 'h', 'a', 'mm', etc.)
      const timeFormatPatterns = ['H', 'h', 'a', 'mm', 'ss'];
      const includesTime = timeFormatPatterns.some(pattern => format.includes(pattern));
    
      if (includesTime) {
        // Determine the time format based on the locale
        const timeFormat = HALF_DAY_TIMEZONES.includes(Settings.defaultZone.zoneName) && Settings.defaultZone.valid
          ? 'h:mm a' 
          : 'H:mm';
      
        // Extract the date part by removing time-related parts from the format string
        const dateFormat = format.replace(/[Hhms:]+/g, '').trim();
  
        // Get the formatted date without time using the original toFormat method
        const formattedDate = originalToFormat.call(this, dateFormat);
      
        // Get the formatted time with the correct locale formatting using the original toFormat method
        const formattedTime = originalToFormat.call(this, timeFormat);
      
        // Return the combined result
        return `${formattedDate} ${formattedTime}`;
      }
    
      // If no time-related part is found, just call the original toFormat
      return originalToFormat.call(this, format);
    };
  }, []);

  return children;
};
