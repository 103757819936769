import React, { useEffect, useState } from 'react';
import { Backdrop, Box, CircularProgress, Typography } from '@material-ui/core';
import LibraryCard from "./LibraryCard";
import { ReactComponent as EmailCampaignCard } from '../../../assets/icons/email_campaign_card.svg';
import { ReactComponent as LinkedinCampaignCard } from '../../../assets/icons/linkedin_campaign_card.svg';
import { ReactComponent as IntelligenceCampaignCard } from '../../../assets/icons/intelligence_card.svg';
import { ReactComponent as AssetsCampaignCard } from '../../../assets/icons/assets_card.svg';
import StaticCard from "./StaticCard";
import { getLibraryFolders, getRecentFiles } from "../../../redux/services/my-library";
import { RecentFiles } from "../../../interfaces/campaign";
import { DateTime } from "luxon";
import useGetSubscription from '../../../hooks/useGetSubscription';
import useGetProfile from '../../../hooks/useGetProfile';

import { useStyles } from "./styles";

const STATIC_CARD = [
  {
    key: 'CHAT_INTELLIGENCE',
    title: 'Copilots',
    description: 'Marketing & strategy content',
    count: 0,
    isComingSoon: false,
    date: null,
    redirectUrl: 'copilots',
    icon: <IntelligenceCampaignCard />,
  },
  {
    key: 'CAMPAIGN',
    title: 'Email Outreach',
    description: 'Tactical emails & campaigns',
    count: 0,
    isComingSoon: false,
    date: null,
    redirectUrl: 'email-outreach',
    icon: <EmailCampaignCard />,
  },
  {
    key: 'LINKEDIN_CAMPAIGNS',
    title: 'LinkedIn Outreach',
    description: 'Tactical messages & campaigns',
    count: 0,
    isComingSoon: true,
    date: null,
    redirectUrl: '',
    icon: <LinkedinCampaignCard />,
  },
  {
    key: 'ASSETS',
    title: 'Sales Assets',
    description: '2-pagers, RFQ/Ps & proposals',
    count: 0,
    isComingSoon: true,
    date: null,
    redirectUrl: '',
    icon: <AssetsCampaignCard />,
  },
];

const MyLibrary = () => {
  const [staticCards, setStaticCards] = useState<any[]>(STATIC_CARD);
  const [recentFiles, setRecentFiles] = useState<RecentFiles[]>([]);

  const classes = useStyles();

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    handleFoldersMetadata();
    handleRecentFiles();
  }, []);

  const handleFoldersMetadata = async () => {
    const { data, error } = await getLibraryFolders();
    if (data && !error) {
      const newStaticCards = [...STATIC_CARD];
      data.forEach(folder => {
        const index = newStaticCards.findIndex(c => c.key === folder.type);
        newStaticCards[index].count = folder.count;
        newStaticCards[index].date = folder.count ? formatLastUpdated(folder.updatedAt) : null;
      })
      setStaticCards(newStaticCards);
    }
  }

  const handleRecentFiles = async () => {
    const { data, error } = await getRecentFiles();
    if (data && !error) {
      setRecentFiles(data)
    }
  }

  const formatLastUpdated = (date) => {
    const dateTime = DateTime.fromJSDate(date);
    const time = dateTime.toFormat('H:mm');
    return dateTime.hasSame(DateTime.now(), 'day')
      ? `Last updated today at ${time}`
      : `Last updated on ${dateTime.toFormat('MMMM d')}`;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography style={{ fontSize: '40px', fontWeight: '700', lineHeight: '48px', color: '#262C37' }}>
            Library
          </Typography>
        </Box>
        <Box className={classes.folders}>
          <Typography className={classes.subtitle}>
            Workflow Categories
          </Typography>
          <Box className={classes.foldersContainer}>
            {staticCards.length && staticCards.map((item, index) => (
              <StaticCard key={item.key} element={item} />
            ))}
          </Box>
        </Box>
        <Box className={classes.recentFiles}>
          <Typography className={classes.subtitle}>
            Recent files
          </Typography>
          <Box className={classes.foldersContainer}>
            {recentFiles.length 
              ? recentFiles.map((item, index) => (
                  <LibraryCard
                    key={item.id}
                    element={item}
                    subscription={subscription}
                    profile={profile}
                    actions={[]}
                    onAction={null}
                  />
                ))
              : 'No recent files.'
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MyLibrary;