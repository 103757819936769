import { REVIEW_STATUS } from '../../utils/enums';

export interface ChatSimplified {
  id: string;
  title: string;
  createdAt?: Date;
}

export interface Chat extends ChatSimplified {
  accountId?: string;
  sessionId?: string;
  messages?: Message[];
}

export interface Message {
  id: string;
  answerStatus: AnswerStatus;
  status?: string;
  author?: MessageAuthorTypes;
  question?: string;
  answer?: string;
  message?: string | "error";
  timestamp?: Date;
}

export interface SendChatMessageResponse {
  answer: string;
  messageId: string;
  sessionId: string;
}

export interface ChatRequest {
  messageId: string;
  accountId: string;
  userId: string;
  question: string;
}

export interface LikeDislikeRequest {
  sessionId: string;
  messageId: string;
  tab: string;
}

export interface CreateChatRequest {
  body: ChatRequest;
  tab: string;
  signal?: AbortSignal;
}

export interface SendChatMessageRequest extends CreateChatRequest {
  sessionId: string;
}

export interface GetChatByIdRequest {
  id: string;
  tab: string;
}

export interface SnippetResponse {
  id: string;
  createdBy: string;
  name: string;
  description: string;
  text: string;
  type: string;
  reviewStatus: keyof typeof REVIEW_STATUS;
  owner?: {
    id: string,
    name: string,
    profileImageUrl: string
  };
  createdAt: string;
  updatedAt: string;
}

export interface UserMetadata {
  userId: string;
  userName: string;
  userImageUrl: string;
}

export interface SnippetCommentReply {
  ownerId: string;
  text: string;
  createdAt: Date;
  metadata: UserMetadata;
}

export interface SnippetComment {
  userId: string;
  text: string;
  replies: SnippetCommentReply[];
}

export interface SnippetCommentResponse {
  id: string;
  chatIntelligenceId: string;
  ownerId: string;
  comment: SnippetComment;
  status: "OPEN" | "RESOLVED";
  createdAt: Date;
  updatedAt: Date;
  metadata: UserMetadata;
}

export enum AnswerStatus {
  UNEVALUATED = "UNEVALUATED",
  LIKED = "LIKED",
  DISLIKED = "DISLIKED"
}

export enum MessageAuthorTypes {
  USER = "user",
  COPILOT = "copilot"
}

export enum CopilotTypes {
  EXECUTION = "general",
  STRATEGY = "intelligence"
}
