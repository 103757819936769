import { colors, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    background: '#F5F5F5',
  },
  container2: {
    width: "100%",
    background: "transparent",
    height: "fit-content",
    "& $form": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important"
    }
  },
  logo: {
    position: "absolute",
    top: 20,
    left: 40
  },
  backBtn: {
    position: "absolute",
    top: 84,
    left: 40,
    borderRadius: "50%",
    backgroundColor: "transparent",
    padding: 12,
    border: "1px solid #E2E8F0",
    cursor: "pointer"
  },

  backBtnIcon: {
    fontSize: 16
  },

  inner: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    zIndex: 1,
    overflowY: "auto",
  },

  form: {
    position: "relative",
    boxSizing: "border-box",
    maxWidth: 980,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  formTop: {},

  formHeading: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "32px"
  },

  formHeadingIcon: {
    position: "absolute",
    bottom: -19,
    right: -12
  },
  formDescription: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "21.5px",
    marginTop: 8,
    marginBottom: '64px',
    color: "#6E737D",
  },
  formDescriptionMark: {
    color: "#0F7F75"
  },
  formSubtitle: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '13.2px',
    textTransform: 'uppercase',
    color: '#262C37'
  },
  formInformation: {
    position: 'relative',
  },
  fileSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 32
  },

  fileInput: {
    width: "100%"
  },

  dropfileWrapper: {
    borderRadius: 20,
    width: "100%",
    border: "1px solid #CBD5E1",
    background: "#3A84C90D",
    height: 136,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer"
  },

  uploadIcon: {
    fontSize: 24,
    color: "#3A84C9"
  },

  uploadTitle: {
    marginTop: 8,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex"
  },

  chooseFile: {
    color: "#3A84C9",
    textDecorationLine: "underline",
    fontWeight: 600,
    marginLeft: 4
  },

  input: {
    display: "none"
  },

  fileNoted: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 6px",
    marginTop: 8
  },

  noted: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
    color: "#8C8C8C"
  },

  action: {
    marginTop: 32,
    display: "flex",
    justifyContent: "flex-end",
    gap: "12px"
  },

  cancelBtn: {
    fontWeight: 500,
    fontSize: 16,
    width: "84px",
    boxShadow: "none",
    cursor: "pointer"
  },

  submitBtn: {
    fontWeight: 500,
    fontSize: 16,
    width: "118px",
    boxShadow: "none",
    cursor: "pointer"
  },

  fileWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  itemWrapper: {
    display: "flex",
    padding: 16,
    marginTop: 18,
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #E9EAEB",
    borderRadius: 8
  },

  fileScrollview: {
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: 8
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#CBD5E1"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#64748B",
      borderRadius: 8
    }
  },

  fileType: {
    minWidth: 60,
    height: 60,
    borderRadius: 8,
    border: '1px solid #F4F7F7',
    backgroundColor: "#F5FDFC",
    color: "#0F7F75",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
    fontSize: 14,
    lineHeight: "19px",
    userSelect: 'none'
  },

  fileName: {
    width: '350px',
    fontSize: 16,
    lineHeight: "22px",
    fontWeight: 400,
    color: "#262C37",
  },

  fileSize: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '19px',
    color: '#6E737D'
  },

  fileIcon: {
    width: 35,
    height: 35,
    color: "#E04E4E",
    marginRight: "15px"
  },

  fileContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "calc(100% - 132px)",
  },

  font16: {
    fontSize: "16px",
    lineHeight: "22px"
  },

  font12: {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 400
  },

  fontWeight600: {
    fontWeight: 600
  },

  deleteBtn: {
    marginLeft: "auto",
    '& svg': {
      color: '#64748B'
    }
  },

  dropdown: {
    marginTop: 0,
    width: 200,
    borderRadius: 8
  },

  dropownInput: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "21px",
    color: "#22507B",
    padding: "8px 12px",
    border: "1px solid #CBD5E1",
    borderRadius: 8
  },

  deleteBtnIcon: {
    fontSize: 16
  },

  fileSelectInner: {
    display: "flex",
    flexDirection: "column",
    margin: "0 12px",
  },

  errorMessage: {
    color: "#DC2626",
    marginTop: "2px",
    fontSize: "12px",
    lineHeight: 1.2,
    fontWeight: 500
  },
  highlightIcon: {
    color: "#0F7F75",
    cursor: "pointer",
    zIndex: 3
  },
  robotIcon: {
    width: '186px',
    height: '311px',
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 0,
  },
}));
