import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    flexGrow: 0,
    position: 'relative',
    '&.MuiFormControl-root': {
      width: '100%',
      margin: '4px 0px',
      background: 'transparent'
    }
  },
  controll: {
    '&.MuiFormControlLabel-root': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      margin: '0px',
      padding: '0px',
      background: 'white',
      borderRadius: '8px',
      cursor: 'default',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '20px',
      color: '#0F172A',
    },
    '& .MuiInput-formControl': {
      width: '100%',
      minWidth: '100%',
      height: '44px',
      borderRadius: '8px',
      padding: '0px',
    }
  },
  input: {
    width: '100%',
    minWidth: '100%',
    height: '44px',
    borderRadius: '8px',
    padding: '0px',
    '& .MuiInputBase-root': {
      border: '1px solid #E9EAEB !important',
      overflow: 'hidden',
      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type="number"]': {
        '-moz-appearance': 'textfield',
      },
      '& input::-webkit-search-cancel-button': {
        position: 'relative',
        right: '20px'
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    },
    '& .MuiOutlinedInput-input': {
      height: '44px',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      color: '#000000',
      boxSizing: 'border-box',
      overflow: 'hidden',
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    },
  },
  textarea: {
    width: '100%',
    minWidth: '100%',
    height: 'fit-content !important',
    borderRadius: '8px',
    padding: '0px',
    '& .MuiInputBase-root': {
      border: '1px solid #E9EAEB !important',
      paddingRight: (props: any) => props.endAdornment ? '65px' : '14px',
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    },
    '& .MuiOutlinedInput-input': {
      height: '99% !important',
      minHeight: '99% !important',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      color: '#000000',
      boxSizing: 'border-box',
      overflowX: 'hidden !important',
      overflowY: 'auto !important',
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    },
  },
  emptyInput: {
    '& input': {
      color: '#475569 !important',
    }
  },
  inputAdornment: {
    position: 'absolute',
    top: '26px',
    right: '5px',
    transform: (props: any) => `translate(0px, ${props.label ? '0' : '-23px'})`,
    '& svg': {
      width: '15px',
      height: '15px',
      color: '#94A3B8',
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center', 
    fontSize: '16px', 
    fontWeight: 600, 
    lineHeight: '19px', 
    color: '#000000', 
    marginBottom: '4px',
  }
}));
