import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Grid, Paper } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ReactComponent as EmailIcon } from '../../../../assets/icons/email_filled.svg';
import { ReactComponent as LinkedinIcon } from '../../../../assets/icons/linkedin_filled.svg';
import { ReactComponent as QuickReplyIcon } from '../../../../assets/icons/quick_reply_filled.svg';
import { ReactComponent as Robot_2 } from "../../../../assets/robots/robot_2.svg";
import { ReactComponent as Robot_3 } from '../../../../assets/robots/robot_3.svg';
import { ReactComponent as Robot_4 }  from '../../../../assets/robots/robot_4.svg';

import { useStyles } from './styles';

const taticalOutReaches = [
  {
    image: <Robot_2 />,
    reachTitle: "Marketing Emails",
    reachDescription: "Powerful one-touch emails and strategic multi-touch campaigns.",
    icon: <EmailIcon color={'#90BEA2'} />,
    activeStep: 1,
  },
  {
    image: <Robot_3 />,
    reachTitle: "Sales Emails",
    reachDescription: "Persuasive sales emails for both non-paying and paying customers.",
    icon: <EmailIcon color={'#E3A1A1'} />,
    activeStep: 2,
  },
  {
    image: <Robot_4 />,
    reachTitle: "Product Emails",
    reachDescription: "Effective customer onboarding and engagement emails.",
    icon: <EmailIcon color={'#A3C3D4'} />,
    activeStep: 3,
  },
  {
    image: <Robot_4 />,
    reachTitle: "Marketing Messages",
    reachDescription: "Powerful one-touch messages and strategic multi-touch sequences.",
    icon: <LinkedinIcon color={'#90BEA2'} />,
    commingSoon: true
  },
  {
    image: <Robot_4 />,
    reachTitle: "Sales Messages",
    reachDescription: "Persuasive sales messages for potential customers and partners.",
    icon: <LinkedinIcon color={'#E3A1A1'} />,
    commingSoon: true
  },
  {
    image: <Robot_4 />,
    reachTitle: "Quick Replies",
    reachDescription: "Smart responses to accelerate sales conversions from inbound.",
    icon: <QuickReplyIcon />,
    commingSoon: true
  },
];

const TacticalOutreachRef = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.group}>
      {taticalOutReaches.map((item, index) => (
        <Grid
          item
          xs={12}
          sm={4}
          key={index}
          onClick={() => {
            if (!item.commingSoon) {
              history.push(`/tactical-outreach?tab=${item.activeStep}`)
            }
          }}
        >
          <Paper
            className={classes.paper}
          >
            <Box className={classes.paperHeader}>
              {item.icon}
              {item.commingSoon
                ? <Box style={{ userSelect: 'none', padding: '5px 8px', borderRadius: '8px', background: '#F5F5F5', fontSize: '14px', fontWeight: '400', lineHeight: 'normal', color: '#50555E' }}>
                    Coming soon
                  </Box>
                : <Box />
              }
            </Box>
            <Box className={classes.content}>
              <Typography className={classes.title}>
                {item.reachTitle}
              </Typography>
              <Typography className={classes.description}>
                {item.reachDescription}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default TacticalOutreachRef;
