import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from "./styles";
import { useHistory } from "react-router-dom";

interface Props {
  element: any,
}

const StaticCard: React.FC<Props> = ({ element }) => {

  const history = useHistory();
  const classes = useStyles();

  const handleRedirectUrl = () => {
    if (element.redirectUrl) {
      history.push('/library/' + element.redirectUrl)
    }
  };

  return (
    <Box className={classes.cardContainer} onClick={handleRedirectUrl}>
      <Box className={classes.cardTop}>
        <Box className={classes.cardPreview}>
          <Box style={{marginTop: element.key === 'ASSETS' ? '-17px' : 0}}>
            {element.icon}
          </Box>
        </Box>
      </Box>
      <Box className={classes.cardBottom}>
        <Box>
          <Box className={classes.cardBottomTopGroup}>
            <Typography className={classes.cartTitle}>
              {element.title}
            </Typography>
            {
              !element.isComingSoon && (
                <Typography className={classes.cartCount}>
                  {element.count}
                </Typography>
              )
            }
          </Box>
          <Typography className={classes.cartDescription}>
            {element.description}
          </Typography>
        </Box>

        {
          element.isComingSoon && (
            <Box className={classes.cardComingSoon}>
              Coming soon!
            </Box>
          )
        }
        {
          element.date && (
            <Typography className={classes.cartDate}>
              {element.date}
            </Typography>
          )
        }
      </Box>
    </Box>
  );
};

export default StaticCard;
