import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from "uuid";
import { clearStorages, getUrlAndParams, getVisibleElementsByClassName, removeAllUrlParams } from '../../helpers/other';
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import SectionAccordion from './components/SectionAccordion';
import { Form, Formik } from 'formik';
import { getCampaignById, getCampaignEmailParameters, getCampaignParameters, getCampaignTemplate, regenerateEmail, saveCampaignEmailParameters, saveCampaignParameters, saveCampaignTemplate, triggerEmailGenerate, updateCampaign, updateCampaignEmail } from '../../redux/services/tactical-outreach';
import TooltipsModal from './components/TooltipModal';
import Header from './components/Header';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { Prompt, useHistory } from 'react-router-dom';
import { findElementByName, generateInitialValue, generateParameters, generateValidationRules, generateYupSchema, isFieldNotEmpty } from './helpers';
import { getCurrentSubscriptionStatus } from '../../helpers/subscription';
import { isStartUpTeamsSubscription, showViewForAdmin } from '../../helpers/roles';
import { notify } from '../../providers/notification';
import { CampaignModelResponse, CampaignResponse, CampaignStatuses, CampaignSubCategory, TemplateAttributeTypes } from '../../interfaces/campaign';
import { REVIEW_STATUS } from '../../utils/enums';
import useGetSubscription from '../../hooks/useGetSubscription';
import useGetProfile from '../../hooks/useGetProfile';

import { useStyles } from './styles';

const CampaignBuilder = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<CampaignModelResponse | CampaignResponse | null>(null);
  const [pageType, setPageType] = useState<'EDIT' | 'VIEW'>(null);
  const [validationSchema, setValidationSchema] = useState<any>({});
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [templateMetadata, setTemplateMetadata] = useState<any>({
    name: {
      active: true,
      value: ''
    },
    description: {
      active: false,
      value: ''
    },
  });
  const [resetFormModal, setResetFormModal] = useState<boolean>(false);
  const [submitFormModal, setSubmitFormModal] = useState<boolean>(false);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [bloackNavigation, setBloackNavigation] = useState<boolean>(false);
  const [isNewCampaign, setIsNewCampaign] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();
  const formRef = useRef<any>(null);

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  const isStartupTeams = isStartUpTeamsSubscription(subscription);
  const isApproved = (template?.reviewStatus === REVIEW_STATUS.APPROVED || template?.reviewStatus === REVIEW_STATUS.REJECTED);
  const isTemplateOwner = template?.createdBy === profile?.user?.uuid;
  // const isAdminView = showViewForAdmin(subscription);
  // const isIncomplete = (isNewCampaign || !template?.status || template?.status === CampaignStatuses.INCOMPLETE);

  const isAllowToEdit = isStartupTeams
    ? !isApproved && (isNewCampaign || isTemplateOwner) && pageType === 'EDIT'
    : true

  useEffect(() => {
    if (profile && subscription) {
      setLoading(true);

      const { params } = getUrlAndParams();
      const template_id = params.get('template_id');
      const campaign_id = params.get('campaign_id');
      const target_email_id = params.get('target_email_id');

      if (campaign_id) {
        handleGetCampaignById(campaign_id, target_email_id);
      } else {
        handleGetCampaignTemplate(template_id, null);
      }

      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [profile, subscription]);

  useEffect(() => {
    if (template && profile && subscription) {
      if (window.location.pathname.includes('/view/')) {
        setPageType('VIEW');
      } else if (window.location.pathname.includes('/edit/')) {
        setPageType('EDIT');
      } else {
        setLoading(true);
  
        notify.error('Forbidden access');
  
        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    }
  }, [template, profile, subscription]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetCampaignById = async (campaign_id, target_email_id) => {
    setLoading(true);
    setIsNewCampaign(false);
    setBloackNavigation(true);

    const { data: metadata, error: metadataError } = await getCampaignById(campaign_id);
    const { data: parameters, error: parametersError } = target_email_id
      ? await getCampaignEmailParameters(target_email_id)
      : await getCampaignParameters(campaign_id)

    if (metadata && !metadataError) {
      setTemplate(metadata);

      await handleGetCampaignTemplate(
        parameters?.campaignTypeId || metadata?.typeId,
        parameters?.uiParameters
      );

      if (metadata && !metadataError) {
        setTemplateMetadata({
          name: {
            active: false,
            value: metadata.name || ''
          },
          description: {
            active: false,
            value: metadata.description || ''
          },
        })
      }

      const intervalId = setInterval(() => {
        setLoading(true);

        if (formRef?.current) {
          clearInterval(intervalId);

          if (parameters?.uiParameters) {
            formRef.current.setValues(parameters?.uiParameters);
          }

          setLoading(false);
        }
      }, 1000);
    } else {
      setBloackNavigation(false);

      notify.error('Saved parameters not found');

      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  }

  const handleGetCampaignTemplate = async (template_id, parameters) => {
    if (template_id) {
      setBloackNavigation(true);

      const { data, error } = await getCampaignTemplate(template_id);

      if (!error && data && !!Object.keys(data).length) {
        setTemplate((prevState) => ({
          ...prevState,
          ...data,
          id: +template_id
        }));
        const shape = generateYupSchema(data, parameters);

        setValidationSchema(shape);

        if (data.subCategory === CampaignSubCategory.MULTI_TOUCH_CAMPAIGNS) {
          const intervalId = setInterval(() => {
            setLoading(true);

            if (formRef?.current) {
              clearInterval(intervalId);

              const newValues = {
                ...formRef?.current?.values
              };
              const newValidationSchema = {
                ...shape
              }

              data.sections.forEach((section) => {
                const subSections = section.attributes.filter(item => item.type === TemplateAttributeTypes.SEPARATED_QUESTION_ATTRIBUTE);

                subSections.forEach(subSection => {
                  const element = subSection.question.elements[0];

                  const nestedElement = element.attributes.find(item => item.type === TemplateAttributeTypes.ON_OPTION_CHILD_ATTRIBUTE);
                  const value = element.attributes.find(item => item.type === TemplateAttributeTypes.DEFINED_OPTION_ATTRIBUTE)?.option;
                  const name = `${section.id}-${subSection.question.id}-${element.id}`;

                  if (value) {
                    newValues[subSection.key] = {
                      ...newValues[subSection.key],
                      [name]: value
                    }
                    newValidationSchema[subSection.key] = Yup.object().shape({
                      [name]: generateValidationRules(`${subSection.key}.${name}`, subSection.question.elements[0], subSection.question),
                      [`${name}-${nestedElement.element.id}`]: generateValidationRules(`${subSection.key}.${name}`, nestedElement.element, subSection.question.elements[0])
                    }).required()
                  }
                })
              });

              formRef?.current?.setValues(newValues);

              setLoading(false);
              setValidationSchema(newValidationSchema);
              setBloackNavigation(true);
            }
          }, 1000);
        }
      } else {
        setBloackNavigation(false);

        notify.error('Template not found');

        setTimeout(() => {
          history.push('/tactical-outreach');
        }, 500);
      }

      setLoading(false);
    } else {
      setBloackNavigation(false);

      notify.error('Template not found');

      setTimeout(() => {
        history.push('/tactical-outreach');
      }, 500);
    }
  }

  const getInitialValues = () => {
    let initialValues = {};

    Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
      initialValues[key] = generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable })
    });

    Object.keys(initialValues).forEach(item => {
      const element = findElementByName(template, item);
      const defaultValueAttribute = element?.attributes?.find(item => item.type === TemplateAttributeTypes.DEFAULT_VALUE_ATTRIBUTE);

      if (defaultValueAttribute) {
        initialValues = {
          ...initialValues,
          [item]: defaultValueAttribute.value
        }
      }
    });

    return initialValues;
  };

  const getCompletedFields = (startName, values, uukey = null) => {
    const newValues = Object.fromEntries(Object.keys(uukey ? validationSchema[uukey].fields : validationSchema).map(key => {
      return uukey
        ? values[uukey]
          ? [key, values[uukey][key] || null]
          : [key, null]
        : [key, values[key] || null]
    }));
    const fieldsBySection = Object.fromEntries(Object.entries(newValues).filter(([key, value]) => key.startsWith(startName)));
    let fields = Object.fromEntries(Object.keys(fieldsBySection).map(key => [key, false]));
    let required = {};

    // check and set all not empty fields
    Object.entries(fieldsBySection).forEach(([key, value]: any) => {
      if (
        isFieldNotEmpty(value) &&
        !Object.keys(fieldsBySection).some(item =>
          item.startsWith(key) &&
          item !== key
        )
      ) {
        fields[key] = true
      } else {
        fields[key] = false
      }
    })

    // get only required fields from all non empty fields
    Object.entries(fieldsBySection).forEach(([key, value]: any) => {
      if (
        (uukey
          ? validationSchema[uukey]?.fields[key]?._exclusive?.required
          : validationSchema[key]?._exclusive?.required
        ) &&
        !Object.keys(fields).some(item =>
          item.startsWith(key) &&
          item !== key &&
          (uukey ? validationSchema[uukey]?.fields[key]?._exclusive?.required : validationSchema[key]?._exclusive?.required)
        )
      ) {
        required[key] = fields[key];
      }
    })

    // the result of sorting according to the principle: the key with the name 1-1-1-2 is higher in the list by 1-1-1
    fields = Object.fromEntries(Object.entries(fields).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));
    required = Object.fromEntries(Object.entries(required).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));

    let allAmount = 0;
    let requiredAmount = 0;

    Object.values(fields).forEach(item => {
      if (item === true) {
        allAmount = allAmount + 1
      }
    })

    Object.values(required).forEach(item => {
      if (item === true) {
        requiredAmount = requiredAmount + 1
      }
    })

    return {
      all: {
        fields: fields || {},
        amount: allAmount
      },
      required: {
        fields: required || {},
        amount: requiredAmount
      }
    }
  };

  const handleSaveCampaignTemplate = async (values: any, redirect?: string) => {
    if (templateMetadata.name.value.trim().length <= 60 && templateMetadata.description.value.trim().length <= 1000) {
      setBloackNavigation(false);
      setLoading(true);

      const { params } = getUrlAndParams();
      const campaign_id = params.get('campaign_id');
      const target_email_id = params.get('target_email_id');
      let saveAsModificator = '';

      if (getCurrentSubscriptionStatus(subscription) !== 'EXPIRED' && isStartUpTeamsSubscription(subscription)) {
        saveAsModificator = '/draft';
      }

      const { data, error } = !isNewCampaign
        ? await (async function () {
          try {
            await updateCampaign({
              body: {
                name: templateMetadata.name.value.trim(),
                description: templateMetadata.description.value.trim(),
              },
              id: campaign_id
            })

            return {
              data: { id: campaign_id },
              error: undefined
            }
          } catch (error) {
            return {
              data: undefined,
              error: error
            }
          }
        })()
        : await saveCampaignTemplate({
          body: {
            name: templateMetadata.name.value?.trim() || '',
            description: templateMetadata.description.value?.trim() || '',
            typeId: +template.id
          },
          saveAsModificator: saveAsModificator
        })

      if (data && !error) {
        setIsDirty(false);

        const body = {
          campaignTypeId: +template.id,
          uiParameters: values,
          parameters: generateParameters(template, values)
        };

        if (target_email_id) {
          body['emailId'] = target_email_id
        } else {
          body['campaignId'] = data.id
        }

        const { data: answer, error: answerError } = target_email_id
          ? await saveCampaignEmailParameters({
            body,
            saveAsModificator: isStartUpTeamsSubscription(subscription)
              ? '/multi-user'
              : '/single-user'
          })
          : await saveCampaignParameters({
            body,
            saveAsModificator: isStartUpTeamsSubscription(subscription)
              ? '/multi-user'
              : '/single-user'
          });

        if (redirect) {
          notify.success(
            !isNewCampaign
              ? 'Campaign updated successfully'
              : 'Campaign saved successfully'
          );

          history.push(redirect);
        } else {
          if (answer && !answerError) {
            let url = `/edit/tactical-outreach/email?campaign_id=${answer.campaignId || data.id}`;

            if (target_email_id) {
              url += `&email_id=${target_email_id}`;
              url += `&channel_id=${target_email_id}`;
            } else {
              const channel_id = uuidv4();

              url += `&channel_id=${channel_id}`;
            }

            history.push(url);
          } else {
            setBloackNavigation(true);
            setLoading(false);

            notify.error(`Failed to ${!isNewCampaign ? 'update' : 'save'} parameters`);
          }
        }
      } else {
        setBloackNavigation(true);
        setLoading(false);

        notify.error(`Failed to ${!isNewCampaign ? 'update' : 'create'} template`);
      }
    } else {
      if (templateMetadata.name.value.trim().length > 60) {
        notify.error('Template name must be fewer than 50 characters');
      } else if (templateMetadata.description.value.trim().length > 1000) {
        notify.error('Template description must be fewer than 1000 characters');
      } else {
        handleScrollToError();
      }

      setLoading(false);
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation
    })

    return false;
  };

  const handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleBeforeSubmitCheck = (values) => {
    let result = {};

    const sections = template.sections.map(section => {
      if (!section.subSections.length) {
        return section.attributes.filter(item => item.type === TemplateAttributeTypes.SEPARATED_QUESTION_ATTRIBUTE).map(item => {
          return {
            ...item,
            id: section.id
          }
        })
      } else {
        return section
      }
    }).flat();

    sections.forEach(item => {
      const { required } = getCompletedFields(item.id, values, item.key);

      if (item.key) {
        result[item.key] = required.fields
      } else {
        result = {
          ...result,
          ...required.fields
        }
      }
    });

    const hasFalseValue = (result) => {
      return Object.entries(result).some(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          // Recursively check nested objects
          return hasFalseValue(value);
        }
        // Check if the value is explicitly false
        return value === false;
      });
    };

    if (hasFalseValue(result)) {
      setSubmitFormModal(true);
    } else {
      handleScrollToError();
    }
  }

  return (
    <>
      <Prompt
        when={isDirty && isAllowToEdit && bloackNavigation}
        message={(location) => handleBlockedNavigation(location)}
      />

      <Backdrop
        style={{ color: '#fff', zIndex: 9999999999 }}
        open={loading || !template?.sections}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        className={classes.container}
      // style={{
      //   opacity: loading ? '0.5' : '1'
      // }}
      >
        {template && !!Object.keys(validationSchema).length && (
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues()}
            validationSchema={Yup.object().shape(validationSchema)}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => handleSaveCampaignTemplate(values)}
          >
            {({
              values,
              errors,
              touched,
              submitForm,
              validateField,
              validateForm,
              resetForm,
              setValues,
              setErrors,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <>
                {formRef && (
                  <Header
                    values={values}
                    template={template}
                    errors={errors}
                    touched={touched}
                    templateMetadata={templateMetadata}
                    validationSchema={validationSchema}
                    isAllowToEdit={isAllowToEdit}
                    setTemplateMetadata={setTemplateMetadata}
                    getCompletedFields={getCompletedFields}
                    setIsDirty={setIsDirty}
                  />
                )}

                <Form
                  translate={undefined}
                  onKeyDown={handleKeyDown}
                >
                  <Box className={classes.content}>
                    {template?.sections?.map((item, index) => (
                      <Box key={index}>
                        <SectionAccordion
                          section={item}
                          values={values}
                          errors={errors}
                          touched={touched}
                          validationSchema={validationSchema}
                          template={template}
                          validateForm={validateForm}
                          isAllowToEdit={isAllowToEdit}
                          setValues={setValues}
                          setErrors={setErrors}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          setValidationSchema={setValidationSchema}
                          setTooltipMessage={setTooltipMessage}
                          getCompletedFields={getCompletedFields}
                          setIsDirty={setIsDirty}
                        />
                      </Box>
                    ))}

                    <Box className={classes.footer}>
                      {isAllowToEdit
                        ? <Box className={classes.footerButtons}>
                            {!isNewCampaign && (
                              <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  history.goBack()
                                }}
                              >
                                Back
                              </Button>
                            )}
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={() => setResetFormModal(true)}
                            >
                              Reset
                            </Button>
                            <Button
                              data-test-id="Submit campaign generate"
                              fullWidth
                              variant="contained"
                              color="primary"
                              // type="submit"
                              onClick={() => {
                                setErrors({});

                                submitForm().then(() => {
                                  validateForm().then((errors) => {
                                    console.clear();
                                    console.log('errors', errors);

                                    handleBeforeSubmitCheck(values);

                                    // if (Object.keys(errors).length) {
                                    //   handleBeforeSubmitCheck(values);
                                    // } else {
                                    //   submitForm();
                                    // }
                                  })
                                })
                              }}
                            >
                              {(isNewCampaign || template.status === CampaignStatuses.INCOMPLETE)
                                ? 'Generate Content'
                                : 'Re-generate Content'
                              }
                            </Button>
                          </Box>
                        : <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setBloackNavigation(false);

                            setTimeout(() => {
                              history.goBack();

                              // const { params } = getUrlAndParams();
                              // const campaign_id = params.get('campaign_id');

                              // history.push(`/edit/tactical-outreach/email?campaign_id=${campaign_id}`)
                            }, 500);
                          }}
                        >
                          Go back
                        </Button>
                      }
                      {isAllowToEdit && (
                        <Typography style={{ width: '100%', textAlign: 'center', fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                          In order to continue you have to complete all mandatory parameters.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Box>

      <TooltipsModal
        open={!!tooltipMessage?.length}
        message={tooltipMessage}
        onClose={() => setTooltipMessage(null)}
      />

      <ConfirmationModal
        open={resetFormModal}
        title={"Are you sure you want to reset the form?"}
        description={"All entered data will be permanently lost"}
        onClose={() => setResetFormModal(false)}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => setResetFormModal(false)}
        onConfirm={async () => {
          setLoading(true);
          formRef.current.resetForm();
          setValidationSchema({});

          await handleGetCampaignTemplate(template.id, null);

          setResetFormModal(false);
        }}
      />

      <ConfirmationModal
        open={submitFormModal}
        title={"Please, ensure the entered data is correct!"}
        onClose={() => {
          handleScrollToError()
          setSubmitFormModal(false)
        }}
        rejectBtnText={"Ok"}
        onReject={() => {
          handleScrollToError()
          setSubmitFormModal(false)
        }}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={!isNewCampaign
          ? 'By leaving this page, your last changes will be lost!'
          : 'Save changes and create a draft campaign?'
        }
        onClose={() => setLeavingModal({ active: false, nextLocation: null })}
        rejectBtnText={isNewCampaign ? "Yes" : null}
        confirmBtnText={!isNewCampaign ? "Leave" : "No"}
        reversedButtons
        onReject={() => {
          setLoading(true);

          setBloackNavigation(false);

          if (isNewCampaign) {
            handleSaveCampaignTemplate(formRef.current.values, leavingModal.nextLocation);
          }

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
        onConfirm={async () => {
          setLoading(true);

          setBloackNavigation(false);
          setLeavingModal({ active: false, nextLocation: null });

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
      />
    </>
  );
};

export default CampaignBuilder;

