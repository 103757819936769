import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  historyBlock: {
    backgroundColor: '#FFF',
    borderRadius: '16px !important',
    boxShadow: 'none',
    border: '3px solid transparent',
    overflow: 'hidden',
    transition: 'all 200ms ease-in-out',
    userSelect: 'none',
    '&.MuiAccordion-root': {
      '&::before': {
        content: 'none'
      }
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    '&:hover': {
      border: '3px solid #12BAA51A',
    }
  },
  historyHeader: {
    padding: '24px 14px 24px 24px',
    cursor: 'default !important',
    '& .MuiAccordionSummary-content': {
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  historyHeaderTop: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  historyHeaderLeft: {
    width: '50%',
    display: 'flex',
    alignItems: 'center'
  },
  historyHeaderRight: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8
  },
  historyHeaderRightAction: {

  },
  cardMenuButton: {
    marginRight: 0,
    padding: 6
  },
  cardMenuButtonOpen: {
    background: '#FFF',
    padding: 0,
    marginRight: 6,
    marginLeft: 6,
    borderRadius: '4px',
    boxShadow: '0px 0px 0px 3px #12BAA51A'
  },
  historyHeaderBottom: {
    borderTop: '1px solid #E9EAEB',
    paddingTop: '16px',
    marginTop: '16px',
    color: '#50555E',
    fontSize: '16px',
    lineHeight: '22px',
    display: 'flex',
  },
  historyHeaderBottomLeft: {
    width: '70%',
  },
  historyHeaderBottomRight: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  historyType: {
    height: '28px',
    backgroundColor: '#F5F5F5',
    padding: '8px 12px 8px 22px',
    borderRadius: 8,
    fontSize: '16px',
    lineHeight: '15px',
    color: '#50555E',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 11,
      left: 12,
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: '#58BE70',
    }
  },
  historyHeaderButton: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 100,
    width: '60px',
    height: '28px',
    fontSize: '16px',
    padding: '8px 4px 6px 10px',
    marginRight: '16px',
    "&:hover": {
      backgroundColor: '#6e737d4D'
    }
  },
  historyHeaderArrow: {
    width: 20,
    height: 20,
    marginTop: '-3px'
  },
  historyHeaderTitle: {
    width: '100%',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
    color: '#262C37',
    cursor: 'pointer'
  },
  emailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: '1px solid #E9EAEB',
    }
  },
  emailsWrapperLeft: {
    minWidth: '400px',
    marginRight: '40px',
  },
  emailsWrapperRight: {
    width: '100%'
  },
  emailSubject: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    overflow: 'hidden',
    lineHeight: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    color: '#262C37',
  },
  emailContent: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
    overflow: 'hidden',
    lineHeight: 'normal',
    fontSize: '16px',
    color: '#50555E',
  },
  owner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px'
  },
  ownerAvatar: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: '#F5F5F5',
    color: '#50555E',
    marginLeft: '8px',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    lineHeight: '38px',
    fontWeight: 500
  },
  ownerInfo: {
    textAlign: 'right',
  },
  ownerTitle: {
    fontSize: '12px',
  },
  ownerName: {
    fontSize: '14px',
    color: '#262C37',
  },
}));