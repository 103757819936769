import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Editor } from 'react-draft-wysiwyg';
import { useHistory, useParams } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, IconButton, Tooltip, Typography } from "@material-ui/core";
import { notify } from "../../providers/notification";
import { ReactComponent as ButtonBackIcon } from '../../assets/icons/button_back.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/comments_2.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import Bold from '../../assets/editor/bold.svg';
import Italic from '../../assets/editor/italic.svg';
import Underline from '../../assets/editor/underline.svg';
import Strikethrough from '../../assets/editor/strikethrough.svg';
import ListBulleted from '../../assets/editor/list_bulleted.svg';
import ListNumbered from '../../assets/editor/list_numbered.svg';
import AlignLeft from '../../assets/editor/align_left.svg';
import AlignCenter from '../../assets/editor/align_center.svg';
import AlignRight from '../../assets/editor/align_right.svg';
import InsertLink from '../../assets/editor/insert_link.svg';
import RemoveLink from '../../assets/editor/remove_link.svg';
import FormatClear from '../../assets/editor/format_clear.svg';
import { convertHTMLToState, convertHTMLToText, convertMarkdownToHtml, convertStateToHTML, copyHtmlToClipboard, isHTML } from "../EmailEditor/helpers";
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForEditor, showViewForReviewer } from "../../helpers/roles";
import { changeSnippetReviewStatus, getSnippetById, getSnippetComments, saveSnippetContent, sendSnippetToReview } from "../../redux/services/copilot";
import { SnippetResponse } from "../../interfaces/copilot";
import { REVIEW_STATUS, REVIEW_STATUS_LABELS } from "../../utils/enums";
import ChooseReviewStatusModal from "../EmailEditor/components/ChooseReviewStatusModal";
import CommentsList from './components/CommentsList';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy_chat_message_icon.svg';
import { renameCopilotHistory } from "../../redux/services/my-history";
import RenameModal from "../IntelligenceHub/components/RenameModal";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import useGetSubscription from "../../hooks/useGetSubscription";
import useGetProfile from "../../hooks/useGetProfile";

import { useStyles } from "./styles";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

const customStyleMap = {
  'FONT_SIZE_8': { fontSize: '8px' },
  'FONT_SIZE_9': { fontSize: '9px' },
  'FONT_SIZE_10': { fontSize: '10px' },
  'FONT_SIZE_11': { fontSize: '11px' },
  'FONT_SIZE_12': { fontSize: '12px' },
  'FONT_SIZE_14': { fontSize: '14px' },
  'FONT_SIZE_16': { fontSize: '16px' },
  'FONT_SIZE_18': { fontSize: '18px' },
  'FONT_SIZE_24': { fontSize: '24px' },
  'FONT_SIZE_30': { fontSize: '30px' },
  'FONT_SIZE_36': { fontSize: '36px' },
  'FONT_SIZE_48': { fontSize: '48px' },
  'FONT_SIZE_60': { fontSize: '60px' },
  'FONT_SIZE_72': { fontSize: '72px' },
  'FONT_SIZE_96': { fontSize: '96px' },
};

const SnippetEditor = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [snippet, setSnippet] = useState<(SnippetResponse & { comments: any }) | null>(null);
  const [editorState, setEditorState] = useState<any>(null);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [sideBarWidth, setSideBarWidth] = useState<number>(270);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [showChooseReviewStatusModal, setShowChooseReviewStatusModal] = useState<boolean>(false);
  const [pageType, setPageType] = useState<'EDIT' | 'REVIEW' | 'VIEW'>(null);
  const [elementToRename, setElementToRename] = useState<(SnippetResponse & { comments: any }) | null>(null);

  const history = useHistory();
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const sideBarContent = useRef<any>(null);

  const { subscription } = useGetSubscription();
  const { profile } = useGetProfile();

  useEffect(() => {
    if (profile && subscription) {
      handleGetSnippet(params.id);
    }
  }, [params.id, profile, subscription]);

  useEffect(() => {
    if (snippet && profile && subscription) {
      if (window.location.pathname.includes('/view/')) {
        setPageType('VIEW');
      } else if (window.location.pathname.includes('/edit/')) {
        setPageType('EDIT');
      } else if (window.location.pathname.includes('/review/') && (showViewForReviewer(subscription) || showViewForAdmin(subscription))) {
        setPageType('REVIEW');
      } else {
        setLoading(true);

        notify.error('Forbidden access');

        setTimeout(() => {
          history.goBack();
        }, 500);
      }
    }
  }, [snippet, profile, subscription]);

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseOver);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isMouseOver]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMouseDown]);

  useEffect(() => {
    setSideBarWidth(sideBarOpen ? 270 : 0);
  }, [sideBarOpen]);

  const handleGetSnippet = async (id) => {
    const { data, error } = await getSnippetById({
      id,
      modificator: isStartUpTeamsSubscription(subscription)
        ? 'multi-user'
        : 'single-user'
    });
    const { data: comments, error: commentsError } = isStartUpTeamsSubscription(subscription) && data?.reviewStatus !== REVIEW_STATUS.DRAFT && data?.reviewStatus !== REVIEW_STATUS.APPROVED
      ? await getSnippetComments({ id })
      : {
          data: []
        }

    if (data && !error) {
      setSnippet({
        ...data,
        comments: (commentsError || !comments?.length) ? [] : comments
      });

      let content = data.text || '';

      if (!isHTML(content)) {
        content = convertMarkdownToHtml(content)
      }

      setEditorState(
        convertHTMLToState(content)
      );

      setLoading(false);
    } else {
      notify.error('Snippet not found');

      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  }

  const onSendToReview = async () => {
    setLoading(true);

    const { error } = await sendSnippetToReview(snippet.id)

    if (!error) {
      notify.success('Snippet has been sent to review');

      setTimeout(() => {
        history.goBack();
      }, 500);
    } else {
      setLoading(false);

      notify.error('Failed to send to review');
    }
  }

  const onFinishReview = async (status) => {
    setLoading(true);

    const { error } = await changeSnippetReviewStatus({
      id: snippet.id,
      status: status as keyof typeof REVIEW_STATUS
    });

    if (!error) {
      notify.success(`Snippet ${status === REVIEW_STATUS.APPROVED ? 'has been published' : 'status has been changed'}`);

      setTimeout(() => {
        history.goBack();
      }, 500);
    } else {
      setLoading(false);

      notify.error(`Failed to ${status === REVIEW_STATUS.APPROVED ? 'publish' : 'change status'}`);
    }
  }

  const scrollToBottom = () => {
    if (sideBarContent?.current) {
      setTimeout(() => {
        sideBarContent.current.scrollTo({
          top: sideBarContent.current.scrollHeight - 1,
          behavior: 'smooth', // Smooth scrolling
        });
      }, 100);
    }
  };

  const handleAddNewComment = () => {
    if (!snippet.comments.find(item => item.status === "NEW")) {
      setSnippet({
        ...snippet,
        comments: [
          ...snippet.comments,
          {
            id: uuidv4(),
            status: "NEW",
          }
        ]
      });

      scrollToBottom();
    }
  }

  const handleMouseOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.id === 'resizeController') {
      setIsMouseOver(true);
    } else {
      setIsMouseOver(false);
    }
  }

  const handleMouseDown = (event) => {
    if (isMouseOver) {
      event.preventDefault();
      event.stopPropagation();

      if (event.target.id === 'resizeController') {
        setIsMouseDown(true);
      } else {
        setIsMouseDown(false);
      }
    }
  }

  const handleMouseUp = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsMouseDown(false);
  }

  const handleMouseMove = (event) => {
    if (isMouseDown) {
      const newWidth = window.innerWidth - event.x;

      if (newWidth >= 270 && newWidth <= window.innerWidth / 3) {
        setSideBarWidth(newWidth);
      }
    }
  }

  const handleCopy = async () => {
    const html = convertStateToHTML(editorState);
  
    const isSuccessully = await copyHtmlToClipboard(html);
  
    isSuccessully
      ? notify.info('Copied to clipboard')
      : notify.error('Failed to copy')
  }

  const disableSaveButton = () => {
    if (snippet) {
        const originalEmail = {
          text: snippet.text || ''
        }

        originalEmail.text = isHTML(originalEmail.text)
          ? originalEmail.text
          : convertMarkdownToHtml(originalEmail.text)

        const currentEmail = {
          text: convertStateToHTML(editorState),
        }

        return !convertHTMLToText(convertStateToHTML(editorState))?.trim()?.length || JSON.stringify(originalEmail) === JSON.stringify(currentEmail);
    } else {
      return true;
    }
  }

  const handleSave = async () => {
    setLoading(true);

    const modificator = isStartUpTeamsSubscription(subscription)
      ? 'multi-user'
      : 'single-user'

    const { error } = await saveSnippetContent({
      id: snippet.id,
      body: {
        name: snippet.name,
        description: snippet.description,
        text: convertStateToHTML(editorState),
      },
      modificator
    });

    if (!error) {
      notify.success('Saved successfully');

      handleGetSnippet(params.id);
    } else {
      notify.error('Failed to save');
    }

    setLoading(false);
  }

  const handleSaveTitle = async (value) => {
    if (value.name.trim().length <= 60) {
      if (!!value.name.trim().length) {
        const { error } = await renameCopilotHistory({
          body: {
            name: value.name.trim(),
            description: value.description.trim()
          },
          id: snippet.id,
          modificator: isStartUpTeamsSubscription(subscription) ? 'multi-user' : 'single-user'
        });

        if (!error) {
          notify.success(`Saved successfully`);
  
          handleGetSnippet(params.id);
        } else {
          notify.error(`Failed to save`);
        }
      }
    } else {
      notify.error(`Max 60 characters`);
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        open={loading}
        style={{
          color: '#fff',
          zIndex: 9999
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {snippet && (
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Box className={classes.header}>
              <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '5px' }}>
                <IconButton
                  size="small"
                  disableRipple
                  style={{
                    borderRadius: '8px'
                  }}
                  onClick={() => history.goBack()}
                >
                  <ButtonBackIcon width={32} height={32} />
                </IconButton>
                {snippet.reviewStatus && (
                  <Box className={classes.status}>
                    <Typography noWrap style={{ fontSize: '16px', fontWeight: '500', lineHeight: '18.6px', color: '#50555E' }}>
                      {REVIEW_STATUS_LABELS[snippet.reviewStatus]}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.titleWrapper}>
                <Typography
                  noWrap
                  style={{ textAlign: 'center', fontSize: '18px', fontWeight: '500', lineHeight: '18px', color: '#262C37' }}
                >
                  {snippet?.name}
                </Typography>
                {(
                  !isStartUpTeamsSubscription(subscription) || 
                  (pageType === 'EDIT' && snippet.reviewStatus !== REVIEW_STATUS.APPROVED && snippet.reviewStatus !== REVIEW_STATUS.REJECTED)
                ) && (
                  <IconButton
                    onClick={() => setElementToRename(snippet)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(
                  pageType === 'EDIT' &&
                  (snippet.reviewStatus === REVIEW_STATUS.DRAFT || snippet.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED)
                ) && (
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    disabled={!convertHTMLToText(convertStateToHTML(editorState))?.trim().length}
                    style={{
                      height: '32px',
                      background: '#0F7F75'
                    }}
                    onClick={() => onSendToReview()}
                  >
                    Send to review
                  </Button>
                )}
                {(
                  pageType === 'REVIEW' &&
                  (showViewForAdmin(subscription) || showViewForReviewer(subscription)) &&
                  snippet.reviewStatus !== REVIEW_STATUS.APPROVED &&
                  snippet.reviewStatus !== REVIEW_STATUS.REJECTED
                ) && (
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    disabled={!convertHTMLToText(convertStateToHTML(editorState))?.trim().length}
                    style={{
                      height: '32px',
                      background: '#0F7F75'
                    }}
                    onClick={() => setShowChooseReviewStatusModal(true)}
                  >
                    Finish review
                  </Button>
                )}
                {(
                  pageType === 'EDIT' && 
                  (snippet.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED || snippet.reviewStatus === REVIEW_STATUS.PENDING_REVIEW)
                ) && (
                  <>
                    &nbsp;
                    &nbsp;
                    <IconButton
                      size="small"
                      disableRipple
                      style={{
                        borderRadius: '8px'
                      }}
                      onClick={() => setSideBarOpen(!sideBarOpen)}
                    >
                      <CommentsIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
            <Box className={classes.editor}>
              <Editor
                editorState={editorState}
                customStyleMap={customStyleMap}
                readOnly={
                  isStartUpTeamsSubscription(subscription)
                    ? (pageType !== 'EDIT' || snippet.reviewStatus === REVIEW_STATUS.APPROVED || snippet.reviewStatus === REVIEW_STATUS.REJECTED)
                    : false
                }
                toolbarHidden={
                  isStartUpTeamsSubscription(subscription)
                    ? (pageType !== 'EDIT' || snippet.reviewStatus === REVIEW_STATUS.APPROVED || snippet.reviewStatus === REVIEW_STATUS.REJECTED)
                    : false
                }
                toolbar={{
                  options: [
                    'blockType',
                    'inline',
                    'list',
                    'textAlign',
                    'link',
                    'remove',
                  ],
                  blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    className: classes.toolbarButton,
                    component: undefined,
                    // dropdownClassName: classes.toolbarButton,
                  },
                  inline: {
                    inDropdown: false,
                    className: classes.toolbarGroup,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                    bold: { icon: Bold, className: classes.toolbarButton },
                    italic: { icon: Italic, className: classes.toolbarButton },
                    underline: { icon: Underline, className: classes.toolbarButton },
                    strikethrough: { icon: Strikethrough, className: classes.toolbarButton },
                  },
                  textAlign: {
                    inDropdown: false,
                    className: classes.toolbarGroup,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['left', 'center', 'right'],
                    left: { icon: AlignLeft, className: classes.toolbarButton },
                    center: { icon: AlignCenter, className: classes.toolbarButton },
                    right: { icon: AlignRight, className: classes.toolbarButton },
                  },
                  list: {
                    inDropdown: false,
                    className: classes.toolbarGroup,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['ordered', 'unordered'],
                    ordered: { icon: ListNumbered, className: classes.toolbarButton },
                    unordered: { icon: ListBulleted, className: classes.toolbarButton }
                  },
                  link: {
                    inDropdown: false,
                    className: classes.toolbarGroup,
                    component: undefined,
                    popupClassName: undefined,
                    dropdownClassName: undefined,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_self',
                    options: ['link', 'unlink'],
                    link: { icon: InsertLink, className: classes.toolbarButton },
                    unlink: { icon: RemoveLink, className: classes.toolbarButton },
                    linkCallback: undefined
                  },
                  remove: {
                    icon: FormatClear,
                    className: classes.toolbarButton,
                    component: undefined
                  }
                }}
                // toolbarCustomButtons={[<CustomOption />]}
                handleReturn={(event) => {
                  // Check if Shift key is pressed along with Enter
                  if (event.shiftKey) {
                    // Prevent the default action (inserting a line break)
                    return 'handled';
                  }
                  return 'not-handled';
                }}
                handlePastedText={(text, html, editorState) => { }}
                onEditorStateChange={(event) => setEditorState(event)}
                wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
                toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
                editorClassName={`editor-class ${classes.editorTextarea}`}
              />
              <Box className={classes.footer}>
                <Box className={classes.actions}>
                  {(
                    !isStartUpTeamsSubscription(subscription) || 
                    (
                      pageType === 'EDIT' && 
                      (snippet.reviewStatus !== REVIEW_STATUS.APPROVED && snippet.reviewStatus !== REVIEW_STATUS.REJECTED)
                    )
                  ) && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={disableSaveButton()}
                      onClick={() => handleSave()}
                    >
                      Save
                    </Button>
                  )}
                  <Tooltip title="Copy content" arrow>
                    <IconButton
                      onClick={() => handleCopy()}
                    >
                      <CopyIcon style={{ width: '20px', height: '20px', color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {pageType === 'REVIEW' && snippet?.owner && (
              <Box className={classes.historyHeaderBottomRight}>
                <Box className={classes.owner}>
                  <Box className={classes.ownerAvatar}>
                    {snippet?.owner?.name?.slice(0, 1).toUpperCase()}
                  </Box>
                  <Box className={classes.ownerInfo}>
                    <Typography className={classes.ownerTitle}>Created by:</Typography>
                    <Typography className={classes.ownerName}>{snippet?.owner.name}</Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {(snippet.reviewStatus === REVIEW_STATUS.REJECTED ||
            ((snippet && pageType === 'REVIEW') || (pageType === 'EDIT' && (snippet.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED || snippet.reviewStatus === REVIEW_STATUS.PENDING_REVIEW)))) && (
            <Box
              className={`${classes.sideBar} ${sideBarOpen ? classes.sideBarOpen : ''}`}
              style={{
                width: sideBarOpen ? sideBarWidth : '0',
                minWidth: sideBarOpen ? sideBarWidth : '0',
                maxWidth: sideBarOpen ? sideBarWidth : '0',
                transition: sideBarOpen && isMouseDown ? 'none' : 'all .3s',
              }}
            >
              <Box
                id={'resizeController'}
                className={classes.resizeController}
              />
              <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px', fontWeight: '500', lineHeight: '32px', color: '#50555E' }}>
                COMMENTS
                {snippet.reviewStatus !== REVIEW_STATUS.APPROVED && snippet.reviewStatus !== REVIEW_STATUS.REJECTED && snippet.reviewStatus !== REVIEW_STATUS.DRAFT && (
                  <IconButton
                    size="small"
                    disableRipple
                    style={{
                      width: '32px',
                      height: '32px',
                      border: '1px solid #E9EAEB',
                      background: '#FFFFFF',
                      borderRadius: '8px'
                    }}
                    onClick={() => {
                      if (!snippet.comments.find(item => item.status === 'NEW')) {
                        handleAddNewComment();
                      }
                    }}
                  >
                    <PlusIcon width={20} height={20} />
                  </IconButton>
                )}
              </Typography>
              <div
                ref={sideBarContent}
                className={classes.sideBarContent}
                style={{
                  justifyContent: !snippet?.comments?.length ? 'center' : 'flex-start'
                }}
              >
                <CommentsList
                  snippet={snippet}
                  profile={profile}
                  subscription={subscription}
                  handleGetSnippet={handleGetSnippet}
                  setSnippet={setSnippet}
                  handleAddNewComment={handleAddNewComment}
                />
              </div>
            </Box>
          )}
        </Box>
      )}

      <ChooseReviewStatusModal
        open={showChooseReviewStatusModal}
        onClose={() => setShowChooseReviewStatusModal(false)}
        onSave={(status) => {
          onFinishReview(status);
          setShowChooseReviewStatusModal(false);
        }}
      />

      <RenameModal
        open={!!elementToRename}
        title={'Rename Snippet'}
        element={elementToRename}
        rejectBtnText={'Close'}
        confirmBtnText={'Rename'}
        onClose={() => setElementToRename(null)}
        onConfirm={(value) => {
          setLoading(true);

          setElementToRename(null);

          handleSaveTitle(value);
        }}
      />
    </>
  );
};

export default SnippetEditor;
