import { CompositeDecorator } from "draft-js";
import { api, createApiCall } from "../api";

const myHistoryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getReviewLogCampaigns: builder.query<any, any>({
      query: (params) => ({
        url: `/v1/library/campaign/review/multi-user`,
        method: "GET",
        params
      })
    }),
    getReviewLogSnippets: builder.query<any, any>({
      query: (params) => ({
        url: `/v1/library/chat-intelligence/review/multi-user`,
        method: "GET",
        params
      })
    }),
    renameReviewLogCampaign: builder.query<void, { body: { name: string, description: string }, id: string }>({
      query: ({ body, id }) => ({
        url: `/v1/library/campaign/history/rename/${id}/multi-user`,
        method: "PUT",
        body
      })
    }),
    renameReviewLogSnippet: builder.query<void, { body: { title: string }, id: string }>({
      query: ({ body, id }) => ({
        url: `/v1/chatbot/intelligence/rename/${id}`,
        method: "PUT",
        body
      })
    }),
    duplicateReviewLogCampaign: builder.query<void, string>({
      query: (id) => ({
        url: `/v1/library/campaign/history/${id}/duplicate/multi-user`,
        method: "PUT"
      })
    }),
    duplicateReviewLogSnippet: builder.query<void, string>({
      query: (id) => ({
        url: `/v1/library/chat-intelligence/${id}/duplicate/multi-user`,
        method: "PUT"
      })
    }),
    deleteReviewLogCampaign: builder.query<void, string>({
      query: (id) => ({
        url: `/v1/library/campaign/history/${id}/multi-user`,
        method: "DELETE"
      })
    }),
    deleteReviewLogSnippet: builder.query<CompositeDecorator, string>({
      query: (id) => ({
        url: `/v1/library/chat-intelligence/${id}/multi-user`,
        method: "DELETE"
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getReviewLogCampaigns = createApiCall<any, any>(
  myHistoryAPI.endpoints.getReviewLogCampaigns
);
export const getReviewLogSnippets = createApiCall<any, any>(
  myHistoryAPI.endpoints.getReviewLogSnippets
);
export const renameReviewLogCampaign = createApiCall<void, { body: { name: string, description: string }, id: string }>(
  myHistoryAPI.endpoints.renameReviewLogCampaign
);
export const renameReviewLogSnippet = createApiCall<void, { body: { title: string }, id: string }>(
  myHistoryAPI.endpoints.renameReviewLogSnippet
);
export const deleteReviewLogCampaign = createApiCall<any, string>(
  myHistoryAPI.endpoints.deleteReviewLogCampaign
);
export const deleteReviewLogSnippet = createApiCall<any, string>(
  myHistoryAPI.endpoints.deleteReviewLogSnippet
);
export const duplicateReviewLogCampaign = createApiCall<any, string>(
  myHistoryAPI.endpoints.duplicateReviewLogCampaign
);
export const duplicateReviewLogSnippet = createApiCall<any, string>(
  myHistoryAPI.endpoints.duplicateReviewLogSnippet
);