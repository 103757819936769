import React from 'react';
import RenderRadioButtons from './components/RenderRadioButtons';
import RenderDropdown from './components/RenderDropdown';
import RenderTextInput from './components/RenderTextInput';
import RenderFileUpload from './components/RenderFileUpload';
import RenderTextArea from './components/RenderTextArea';
import RenderSlider from './components/RenderSlider';
import RenderNumberInput from './components/RenderNumberInput';
import RenderMultiSelector from './components/RenderMultiSelector';
import RenderUrlInput from './components/RenderUrlInput';
import RenderBoxSwitchSelector from './components/RenderBoxSwitchSelector';
import { CampaignModelResponse, CampaignResponse, ReplyTemplateModel, TemplateElement, TemplateElementTypes } from '../../../../interfaces/campaign';

import { useStyles } from './styles';

interface Props {
  parent?: any;
  item: TemplateElement;
  name: string;
  values: any;
  errors: any;
  touched: any;
  validationSchema: any;
  template: CampaignModelResponse | CampaignResponse | ReplyTemplateModel;
  uukey: string;
  isAllowToEdit: boolean;
  setValues: (values: any, shouldValidate?: boolean) => void;
  setErrors: (errors: any) => void;
  setFieldValue: (field: string, value: any, newValidationSchema: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setValidationSchema: (shape: any) => void;
  setTooltipMessage: (message:  string) => void;
}

const SubSectionElement: React.FC<Props> = ({
  parent,
  item,
  name,
  values,
  errors,
  touched,
  validationSchema,
  template,
  uukey,
  isAllowToEdit,
  setValues,
  setErrors,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setValidationSchema,
  setTooltipMessage
}) => {
  const classes = useStyles();

  return (
    <>
      {item.type === TemplateElementTypes.SWITCH_SELECTOR && (
        <RenderRadioButtons 
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          validationSchema={validationSchema}
          template={template}
          uukey={uukey}
          isAllowToEdit={isAllowToEdit}
          setValues={setValues}
          setErrors={setErrors}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
          setValidationSchema={setValidationSchema}
          setTooltipMessage={setTooltipMessage}
        />
      )}
      {item.type === TemplateElementTypes.DROP_DOWN && (
        <RenderDropdown
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      )}
      {item.type === TemplateElementTypes.TEXT_INPUT && (
        <RenderTextInput
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      )}
      {item.type === TemplateElementTypes.FILE_UPLOAD && (
        <RenderFileUpload
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
        />
      )}
      {item.type === TemplateElementTypes.TEXT_AREA && (
        <RenderTextArea
          parent={parent}
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          template={template}
          uukey={uukey}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setFieldTouched={setFieldTouched}
        />
      )}
      {item.type === TemplateElementTypes.OPT_SLIDER_SELECTOR && (
        <RenderSlider
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      )}
      {item.type === TemplateElementTypes.MULTI_SELECTOR && (
        <RenderMultiSelector
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setTooltipMessage={setTooltipMessage}
        />
      )}
      {item.type === TemplateElementTypes.INT_NUMBER_INPUT && (
        <RenderNumberInput
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      )}
      {item.type === TemplateElementTypes.URL_INPUT && (
        <RenderUrlInput
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )}
      {item.type === TemplateElementTypes.BOX_SWITCH_SELECTOR && (
        <RenderBoxSwitchSelector
          item={item}
          name={name}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          setTooltipMessage={setTooltipMessage}
        />
      )}
    </>
  )
};

export default SubSectionElement;
