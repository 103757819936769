import React from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { REVIEW_STATUS } from '../../../../utils/enums';
import { useStyles } from './styles';
import { ReactComponent as AlertCircle } from '../../../../assets/icons/alert-circle.svg';
import { CampaignStatuses } from '../../../../interfaces/campaign';

interface Props {
  value: string;
  expanded?: boolean;
}

const Status: React.FC<Props> = ({ value, expanded }) => {
  const classes = useStyles();

  const statusMetadata = {
    [CampaignStatuses.INCOMPLETE]: {
      text: 'Incomplete',
      backgroundColor: '#F5F5F5',
      color: '#50555E',
    },
    [REVIEW_STATUS.DRAFT]: {
      text: 'Draft',
      backgroundColor: '#F5F5F5',
      color: '#50555E',
    },
    [REVIEW_STATUS.PENDING_REVIEW]: {
      text: 'Pending review',
      backgroundColor: '#FFFAEB',
      color: '#B54708',
    },
    [REVIEW_STATUS.CHANGE_REQUIRED]: {
      text: 'Changes required',
      backgroundColor: '#FDF2FA',
      color: '#C11574',
    },
    [REVIEW_STATUS.REJECTED]: {
      text: 'Rejected',
      backgroundColor: '#FDE3E3',
      color: '#B32225',
    },
    default: {
      text: 'Approved',
      backgroundColor: '#DAF8E6',
      color: '#197D42',
    },
  };

  const { text, backgroundColor, color } = statusMetadata[value] || statusMetadata.default;

  return (
    <Box
      className={classes.statusContainer}
      style={{
        backgroundColor: (value === REVIEW_STATUS.DRAFT || value === CampaignStatuses.INCOMPLETE) && expanded ? '#ebebeb' : backgroundColor,
        color,
      }}
    >
      {text}
      {(value === REVIEW_STATUS.DRAFT || value === CampaignStatuses.INCOMPLETE) && (
        <Tooltip
          title="This item is not yet complete"
          placement="top-end"
          arrow
          PopperProps={{ style: { zIndex: 9999 } }}
        >
          <AlertCircle className={classes.icon} />
        </Tooltip>
      )}
    </Box>
  );
};

export default Status;
