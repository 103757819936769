import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Avatar, Box, Button, CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as SendMessageIcon } from '../../../../../../assets/icons/send_message_icon.svg';
import { getProfile } from "../../../../../../redux/services/profile";
import { lastActivityDateFormat } from "../../../../../../helpers/date";
import TextField from "../../../../../../components/main/CustomFields/TextField";
import { ReactComponent as GreyCheckmarkIcon } from '../../../../../../assets/icons/grey_checkmark_mini.svg';
import { ReactComponent as GreenCheckmarkIcon } from '../../../../../../assets/icons/green_checkmark_mini.svg';
import { ReactComponent as CrossGreyIcon } from '../../../../../../assets/icons/cross_grey.svg';
// import { ReactComponent as ThreeDotsIcons } from '../../../../../../assets/icons/three_dots.svg';
// import { ReactComponent as CheckmarkIcons } from '../../../../../../assets/icons/plan_benefit_checkmark.svg';
import { AvatarGroup } from "@material-ui/lab";
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForEditor, showViewForReviewer } from "../../../../../../helpers/roles";
import { notify } from "../../../../../../providers/notification";
import { changeCommentStatus, replyForComment, sendCampaignComment } from "../../../../../../redux/services/my-library";
import { REVIEW_STATUS } from "../../../../../../utils/enums";

import { useStyles } from "./styles";

const CommentsList = ({
  campaign,
  email,
  profile,
  subscription,
  handleGetCampaign,
  setCampaign,
  inProgress,
  handleAddNewComment
}) => {
  const [currentComment, setCurrentComment] = useState<any>(null);
  const [commentMessage, setCommentMessage] = useState<string>('');

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if ((showViewForEditor(subscription) && campaign.createdBy === profile?.user?.uuid) || showViewForReviewer(subscription) || showViewForAdmin(subscription)) {
      setCommentMessage('');

      const newCommentTemplate = campaign.comments.find(item => item.status === "NEW");

      if (newCommentTemplate) {
        setCurrentComment(newCommentTemplate);
      } else if (!currentComment) {
        const openComments = campaign.comments.filter(item => item.status === 'OPEN');
        const lastOpenComment = openComments[openComments.length - 1];

        setCurrentComment(lastOpenComment);
      }
    }
  }, [campaign.comments, profile]);


  const handleSendComment = async () => {
    if (commentMessage.trim().length) {
      const oldComments = campaign.comments;
      setCommentMessage('');

      setCampaign({
        ...campaign,
        comments: [
          ...campaign.comments.filter(item => item.status !== 'NEW'),
          {
            campaignId: campaign.id,
            createdAt: (new Date()).toISOString(),
            id: uuidv4(),
            ownerId: profile.user.uuid,
            status: 'OPEN',
            comment: {
              text: commentMessage.trim(),
              userId: profile.user.uuid,
              replies: []
            },
            metadata: {
              userId: profile.user.uuid,
              userImageUrl: profile.user.profilePhoto,
              userName: profile.user.name,
            }
          }
        ]
      })

      const { error } = await sendCampaignComment({
        campaignId: campaign.id,
        text: commentMessage.trim()
      });

      if (!error) {
        await handleGetCampaign(campaign.id, email.id);
      } else {
        notify.error('Failed to send the comment');

        setCampaign({
          ...campaign,
          comments: oldComments
        })
      }
    }
  }

  const handleReplyForComment = async () => {
    if (commentMessage.trim().length) {
      const oldComments = campaign.comments;
      setCommentMessage('');

      setCampaign({
        ...campaign,
        comments: campaign.comments.map(item => (
          currentComment.id === item.id
            ? {
              ...item,
              comment: {
                ...item.comment,
                replies: [
                  ...item.comment.replies,
                  {
                    createdAt: (new Date()).toISOString(),
                    ownerId: profile.user.uuid,
                    text: commentMessage.trim(),
                    metadata: {
                      userId: profile.user.uuid,
                      userImageUrl: profile.user.profilePhoto,
                      userName: profile.user.name,
                    }
                  }
                ]
              }
            }
            : item
        ))
      })

      const { error } = await replyForComment({
        body: {
          text: commentMessage.trim()
        },
        commentId: currentComment.id,
      });

      if (!error) {
        await handleGetCampaign(campaign.id, email.id);
      } else {
        notify.error('Failed to send the reply');

        setCampaign({
          ...campaign,
          comments: oldComments
        })
      }
    }
  }

  const handleFilterUniqueReplies = (replies) => {
    const result = replies?.reduce((unique, item) => {
      if (!unique.some(obj => obj.ownerId === item.ownerId)) {
        unique.push(item);
      }
      return unique;
    }, []);

    return result;
  }

  const handleChangeCommentStatus = async (comment) => {
    const { error } = await changeCommentStatus({
      body: {
        status: comment.status === 'OPEN' ? 'RESOLVED' : 'OPEN'
      },
      commentId: comment.id
    });

    if (!error) {
      await handleGetCampaign(campaign.id, email.id);
    } else {
      notify.error(
        comment.status === 'OPEN'
          ? 'Failed to resolve the issue'
          : 'Failed to reopen the issue'
      );
    }
  }

  const renderNewCommentView = (item) => {
    return (
      <Box className={`${classes.commentItem} ${item.id === currentComment?.id ? classes.commentItemActive : ''}`}>
        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '7px' }}>
          <Avatar
            src={profile?.user?.profilePhoto}
            alt={profile?.user?.name}
            style={{ width: '32px', height: '32px' }}
          />
          <Typography noWrap style={{ width: '100%', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
            {profile?.user?.name}
          </Typography>
          <IconButton
            onClick={() => {
              setCampaign({
                ...campaign,
                comments: campaign.comments.filter(item => item.status !== 'NEW')
              });
            }}
          >
            <CrossGreyIcon />
          </IconButton>
        </Box>
        {campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED && campaign.reviewStatus !== REVIEW_STATUS.DRAFT && (
          <Box className={`${classes.commentMessageInput} animate__animated animate__fadeIn animate__faster`}>
            <TextField
              value={commentMessage}
              placeholder={'Comment...'}
              autoFocus
              onChange={(event) => {
                setCommentMessage(event.target.value.substring(0, 2000))
              }}
              onKeyPress={(event) => {
                if (event.code.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') {
                  handleSendComment()
                }
              }}
            />
            <IconButton
              size="small"
              disabled={!commentMessage.length}
              className={classes.commentMessageSendIcon}
              onClick={() => handleSendComment()}
            >
              <SendMessageIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <>
      {!!campaign.comments.length
        ? campaign.comments.map((item, index) => (
          item.status !== "NEW"
            ? <Box
              key={index}
              className={`${classes.commentItem} ${item.id === currentComment?.id ? classes.commentItemActive : ''}`}
            >
              {(campaign.reviewStatus === REVIEW_STATUS.CHANGE_REQUIRED || 
                campaign.reviewStatus === REVIEW_STATUS.PENDING_REVIEW
              ) && (
                <>
                  {item.status !== 'NEW' && (
                    <IconButton
                      size="small"
                      className={classes.commentItemMenuIcon}
                      onClick={() => handleChangeCommentStatus(item)}
                    >
                      <Tooltip
                        arrow
                        placement="left"
                        title={
                          item.status === 'OPEN'
                            ? 'Mark as resolved'
                            : 'Mark as unresolved'
                        }
                      >
                        {item.status === 'OPEN'
                          ? <GreyCheckmarkIcon />
                          : <GreenCheckmarkIcon />
                        }
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              )}
              <Box style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '7px' }}>
                <Avatar
                  src={item.metadata.userImageUrl}
                  alt={item.metadata.userName}
                  style={{ width: '32px', height: '32px' }}
                />
                <Box>
                  <Typography noWrap style={{ maxWidth: '120px', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                    {item.metadata.userName}
                  </Typography>
                  <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                    {lastActivityDateFormat(item.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <Typography style={{ userSelect: 'text', fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#1E293B' }}>
                {item.comment.text}
              </Typography>
              {item.id !== currentComment?.id && (
                !!item.comment.replies?.length
                  ? <Box
                    className={classes.replyCounter}
                    onClick={() => {
                      if (!inProgress) {
                        setCampaign({
                          ...campaign,
                          comments: campaign.comments.filter(item => item.status !== "NEW")
                        });

                        setCurrentComment(item)
                      }
                    }}
                  >
                    <AvatarGroup
                      max={4}
                      spacing="small"
                      style={{ zoom: '0.5' }}
                    >
                      {handleFilterUniqueReplies(item.comment.replies)?.map((replyItem, replyIndex) => (
                        <Avatar
                          key={replyIndex}
                          src={replyItem.metadata.userImageUrl}
                          alt={replyItem.metadata.userName}
                        />
                      ))}
                    </AvatarGroup>
                    <Box className={classes.replyCounterText}>
                      {item.comment.replies?.length} repl{item.comment.replies?.length > 1 ? 'ies' : 'y'}
                    </Box>
                  </Box>
                  : (item.status === 'OPEN' && campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED)
                    ? <Box
                      className={classes.replyCounterText}
                      onClick={() => {
                        if (!inProgress) {
                          setCampaign({
                            ...campaign,
                            comments: campaign.comments.filter(item => item.status !== "NEW")
                          });

                          setCurrentComment(item);
                        }
                      }}
                    >
                      Reply in thread
                    </Box>
                    : null
              )}
              {item.id === currentComment?.id && (
                <>
                  <Box className={classes.nestedItemsWrapper}>
                    {item.comment.replies?.map((nestedItem, nestedIndex) => (
                      <Box
                        key={nestedIndex}
                        className={classes.nestedItem}
                      >
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '7px' }}>
                          <Avatar
                            src={nestedItem.metadata.userImageUrl}
                            alt={nestedItem.metadata.userName}
                            style={{ width: '32px', height: '32px' }}
                          />
                          <Box>
                            <Typography noWrap style={{ maxWidth: '120px', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                              {nestedItem.metadata.userName}
                            </Typography>
                            <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '12px', color: '#475569' }}>
                              {lastActivityDateFormat(nestedItem.createdAt)}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography style={{ userSelect: 'text', fontSize: '12px', fontWeight: '500', lineHeight: '15px', color: '#1E293B' }}>
                          {nestedItem.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  {item.status === 'OPEN' && ((showViewForEditor(subscription) && campaign.createdBy === profile?.user?.uuid) || showViewForReviewer(subscription) || showViewForAdmin(subscription)) && (
                    <>
                      <Box style={{ width: '100%', display: 'flex', gap: '7px' }}>
                        <Avatar
                          src={profile.user.profilePhoto}
                          alt={profile.user.name}
                          style={{ width: '32px', height: '32px' }}
                        />
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography noWrap style={{ maxWidth: '120px', fontSize: '14px', fontWeight: '600', lineHeight: '24px', color: '#000000' }}>
                            {profile.user.name}
                          </Typography>
                        </Box>
                      </Box>
                      {campaign.reviewStatus !== REVIEW_STATUS.APPROVED && campaign.reviewStatus !== REVIEW_STATUS.REJECTED && campaign.reviewStatus !== REVIEW_STATUS.DRAFT && (
                        <Box className={`${classes.commentMessageInput} animate__animated animate__fadeIn animate__faster`}>
                          <TextField
                            value={commentMessage}
                            placeholder={'Reply...'}
                            autoFocus
                            onChange={(event) => {
                              setCommentMessage(event.target.value.substring(0, 2000))
                            }}
                            onKeyPress={(event) => {
                              if (event.code.toLowerCase() === 'enter' || event.key.toLowerCase() === 'enter') {
                                handleReplyForComment()
                              }
                            }}
                          />
                          <IconButton
                            size="small"
                            className={classes.commentMessageSendIcon}
                            onClick={() => handleReplyForComment()}
                          >
                            <SendMessageIcon />
                          </IconButton>
                        </Box>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
            : renderNewCommentView(item)
        ))
        : isStartUpTeamsSubscription(subscription) && 
          campaign.reviewStatus !== REVIEW_STATUS.APPROVED && 
          campaign.reviewStatus !== REVIEW_STATUS.REJECTED && 
          campaign.reviewStatus !== REVIEW_STATUS.DRAFT && (
            <>
              <Typography style={{ textAlign: 'center', fontSize: '16px', fontWeight: '400', lineHeight: '21px', color: '#50555E' }}>
                No comments yet
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleAddNewComment}
              >
                + Add comment
              </Button>
            </>
          )
      }
    </>
  );
};

export default CommentsList;