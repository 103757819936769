import React from 'react';
import { Box, FormHelperText } from '@material-ui/core';
import Slider from '../../../../../../components/main/CustomFields/Slider';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, newValidationSchema?: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
}

const RenderSlider: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  const getCurrentValue = (elem) => {
    return +Object.keys(elem.options).find(key => elem.options[key] === values[name]);
  }

  const findKeyByValue = (options, defaultValue) => {
    return +Object.keys(options).find(key => options[key] === defaultValue);
  };

  const generateMarks = (elem) => {
    const result = Object.keys(elem.options).map(key => ({
      value: +key,
      label: elem.options[key]
    }));

    return result;
  }

  return (
    <>
      {item.sliders.map((elem, index) => (
        <Box 
          key={index}
          className={classes.container} 
        >
          <Slider
            name={name}
            value={getCurrentValue(elem) || findKeyByValue(elem.options, elem.defaultValue)}
            min={+Object.keys(elem.options)[0]}
            max={+Object.keys(elem.options)[Object.keys(elem.options).length - 1]}
            marks={generateMarks(elem)}
            testId={elem.defaultValue}
            onChange={(event, value) => setFieldValue(name, elem.options[value] === elem.defaultValue ? '' : elem.options[value])}
          />
        </Box>
      ))}
    
      {touched[name] && errors[name] && (
        <FormHelperText error>
          {errors[name]}
        </FormHelperText>
      )}
    </>
  );
};

export default RenderSlider;
