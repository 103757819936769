import { ReduxProvider } from "./redux";
import { ThemeProvider } from "./theme";
import { PersistProvider } from "./persist";
import { LayoutProvider } from "./layout";
import { NotificationProvider } from "./notification";
import { AuthProvider } from "./auth";
import { HelmetProvider } from "./helmet";
import { StorageProvider } from "./storage";
import { TimezoneProvider } from "./timezone";

export const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <NotificationProvider>
          <StorageProvider>
            <AuthProvider>
              <TimezoneProvider>
                <LayoutProvider>
                  <HelmetProvider>
                    <PersistProvider>
                      {children}
                    </PersistProvider>
                  </HelmetProvider>
                </LayoutProvider>
              </TimezoneProvider>
            </AuthProvider>
          </StorageProvider>
        </NotificationProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};
