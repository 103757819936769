import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  importFileSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  importFileInput: {
    width: "100%",
  },
  importDropfileWrapper: {
    width: "100%",
    height: "205px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 20,
    border: "1px dashed #E9EAEB",
    background: 'white',
    cursor: "pointer",
    '&:hover': {
      border: "2px dashed #0F7F75",
      background: '#3A84C90D',
    }
  },
  importUploadIcon: {
    width: "44px",
    height: "44px",
    border: "1px solid #F4F7F7",
    borderRadius: 8,
    backgroundColor: "#F5FDFC",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px"
  },
  importUploadTitle: {
    marginTop: 8,
    lineHeight: "22px",
    display: "flex",
    fontSize: 16,
    alignItems: "baseline",
    userSelect: 'none'
  },
  importChooseFile: {
    color: "#0F7F75",
    marginLeft: 4,
    textDecorationLine: "underline",
    fontWeight: 500,
  },
  importFileSupported: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '13.2px',
    textAlign: 'center',
    color: '#6E737D',
    marginTop: '20px',
    userSelect: 'none'
  },
  importInput: {
    display: "none",
  },
  importFileNoted: {
    width: "100%",
    padding: "0 6px",
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
    whiteSpace: "pre-line"
  },
  importNoted: {
    color: "#8C8C8C",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
  },
}));
