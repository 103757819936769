import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box, IconButton, Menu, MenuItem, Tooltip,
  Typography
} from '@material-ui/core';
import { ReactComponent as ArrowDown } from '../../../../assets/icons/arrow_down_v2.svg';
import { ReactComponent as InfoHint } from '../../../../assets/icons/info_hint_v2.svg';
import { ReactComponent as DotMenu } from '../../../../assets/icons/dot_menu.svg';
import Types from "../Types";
import Status from "../Status";
import { DateTime, Settings } from "luxon";
import { useHistory } from "react-router-dom";
import { trimTextByCharacters } from "../../../../helpers/text";
import { convertHTMLToText, convertMarkdownToText, isHTML } from "../../../EmailEditor/helpers";
import { CampaignStatuses } from '../../../../interfaces/campaign';

import { useStyles } from './styles';

interface Props {
  element: any,
  viewType: string,
  page?: string,
  actions: { key: string, label: string, isDelete?: boolean }[],
  onAction: (body: { element: any, type: string }) => void;
}

const menuStyles = {
  marginTop: '36px',
  marginLeft: '3px',
  borderRadius: '8px',
  border: '1px solid #E9EAEB',
  padding: '8px',
}

const menuItemStyles = {
  width: '184px',
  height: '40px',
  borderRadius: 8,
  padding: '4px 12px',
  fontSize: '16px',
  lineHeight: '16px',
}

const ElementRow: React.FC<Props> = ({
  element,
  viewType,
  actions,
  page,
  onAction
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleRedirect = () => {
    if (viewType === 'EMAIL_CAMPAIGNS') {
      if (window.location.pathname.includes('/my-history')) {
        if (element?.emails?.length) {
          history.push(`/edit/tactical-outreach/email?campaign_id=${element.id}`)
        } else {
          history.push(`/edit/campaign/builder?campaign_id=${element.id}`)
        }
      } else {
        if (element.status === CampaignStatuses.INCOMPLETE || !element?.emails?.length) {
          history.push(`/edit/campaign/builder?campaign_id=${element.id}`)
        } else {
          history.push(`${getMode()}/tactical-outreach/email?campaign_id=${element.id}`)
        }
      }
    } else if (viewType === 'COPILOT_EXECUTION' || viewType === 'COPILOT_STRATEGY') {
      const url = window.location.pathname.includes('/my-history')
        ? `${getMode()}/snippet/${element.id}`
        : window.location.pathname.includes('/library')
          ? `${getMode()}/snippet/${element.id}`
          : `${getMode()}/snippet/${element.id}`

      history.push(url);
    }
  }

  const getMode = () => {
    return window.location.pathname.includes('/my-history')
      ? '/edit'
      : window.location.pathname.includes('/library')
        ? '/view'
        : '/review'
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getTooltipDate = () => {
    const createdDate = DateTime.fromISO(element.createdAt, { zone: 'utc' }).setZone();
    const updatedDate = DateTime.fromISO(element.updatedAt, { zone: 'utc' }).setZone();
  
    const createdOn = `Created on ${createdDate.toFormat("MMM d, yyyy 'at' H:mm")}`;
    const lastUpdated = `Last updated on ${updatedDate.toFormat("MMM d, yyyy 'at' H:mm")}`;
    // const author = element.owner ? `Created by: ${element.owner.name}` : '';
  
    return (
      <>
        {lastUpdated}<br />
        {createdOn}
      </>
    );
  };

  return (
    <>
      <Accordion
        className={classes.historyBlock}
        expanded={expanded}
        style={expanded ? { borderColor: '#E9EAEB' } : {}}
      >
        <AccordionSummary className={classes.historyHeader}
          style={expanded ? { backgroundColor: '#F5F5F5' } : {}}>
          <Box className={classes.historyHeaderTop}>
            <Box className={classes.historyHeaderLeft}>
              {viewType === 'EMAIL_CAMPAIGNS' && (
                  <Box
                      className={classes.historyHeaderButton}
                      style={{
                        backgroundColor: expanded ? '#ebebeb' : '#F5F5F5',
                        cursor: element?.emails?.length ? 'pointer' : 'default',
                        padding: !element?.emails?.length ? '8px 10px 6px' : '',
                      }}
                      onClick={() => setExpanded(!expanded)}
                  >
                    {element?.emails?.length || 0}
                    {element?.emails?.length ? (
                        <ArrowDown className={classes.historyHeaderArrow}
                                   style={expanded ? { transform: 'rotate(180deg)' } : {}}
                        />
                    ) : null}
                  </Box>
              )}
              <Typography
                noWrap
                component="p"
                className={classes.historyHeaderTitle}
                onClick={() => handleRedirect()}
              >
                {element?.name}
              </Typography>
            </Box>
            <Box className={classes.historyHeaderRight}>
              <Box>
                {
                  viewType === 'EMAIL_CAMPAIGNS' && (
                    <Types value={element.type} expanded={expanded} />
                  )
                }
                <Status value={element.status === CampaignStatuses.INCOMPLETE ? CampaignStatuses.INCOMPLETE : element.reviewStatus} expanded={expanded} />
              </Box>
              <Box className={classes.historyHeaderRightAction}>
                <Tooltip onClick={e => e.stopPropagation()} title={getTooltipDate()} placement="top-end" arrow>
                  <IconButton style={{ padding: '6px' }}>
                    <InfoHint />
                  </IconButton>
                </Tooltip>
                {!!actions.length && (
                  <>
                    <IconButton id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      className={`${classes.cardMenuButton} ${open ? classes.cardMenuButtonOpen : null}`}
                      onClick={handleClick}
                    >
                      <DotMenu />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{ 'aria-labelledby': 'basic-menu' }}
                      PaperProps={{ style: menuStyles }}
                      onClick={e => e.stopPropagation()}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      {actions.map((item, index) => (
                        <MenuItem
                          key={index}
                          style={{
                            ...menuItemStyles,
                            backgroundColor: item.isDelete ? '#FEF2F2' : '',
                            color: item.isDelete ? '#E94A4D' : '#262C37'
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleClose();
                            onAction({ element, type: item.key });
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.historyHeaderBottom}
            style={expanded ? { display: 'none' } : {}}>
            <Box className={classes.historyHeaderBottomLeft}>
              {element.description ? element.description : ' - '}
            </Box>
            {
              page === 'REVIEW' && (
                    <Box className={classes.historyHeaderBottomRight}>
                      <Box className={classes.owner}>

                        <Box className={classes.ownerInfo}>
                          <Typography className={classes.ownerTitle}>Created by:</Typography>
                          <Typography className={classes.ownerName}>{ element?.owner.name }</Typography>
                        </Box>
                        <Box className={classes.ownerAvatar}>
                          {element?.owner?.name?.slice(0, 1).toUpperCase()}
                        </Box>
                      </Box>
                    </Box>
                )
            }

          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {viewType === 'EMAIL_CAMPAIGNS' && element.emails && element.emails.map((emailItem) => (
            <Box
              key={emailItem.id}
              className={classes.emailsWrapper}
              onClick={() => {
                history.push(`${getMode()}/tactical-outreach/email?campaign_id=${emailItem.campaignId}&email_id=${emailItem.id}`)
              }}
            >
              <Box className={classes.emailsWrapperLeft}>
                <Typography className={classes.emailSubject}>
                  {emailItem.subject}
                </Typography>
              </Box>
              <Box className={classes.emailsWrapperRight}>
                <Typography className={classes.emailContent}>
                  {
                    trimTextByCharacters(
                      isHTML(emailItem.content)
                        ? convertHTMLToText(emailItem.content)
                        : convertMarkdownToText(emailItem.content),
                      120
                    )
                  }
                </Typography>
              </Box>
            </Box>
          ))}


        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ElementRow;