import React from "react";

import { Grid, Button } from "@material-ui/core";

import AddRoundedIcon from "@material-ui/icons/AddRounded";
interface IProps {
  onClick: () => void;
  buttonName: string;
}

export default class CustomButton extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }


  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            style={webStyle.button}
            startIcon={<AddRoundedIcon style={webStyle.icon} />}
            onClick={this.props.onClick}
          >
            <span style={webStyle.txt}>{this.props.buttonName}</span>
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const webStyle = {
  button: {
    borderRadius: "20px",
    marginTop: "1%",
    textTransform: "capitalize" as "capitalize",
    border: "1px solid #0F7F75"
  },
  icon: { color: "#0F7F75", fontSize: "18px" },
  txt: { color: "#0F7F75", fontSize: "14px" }
};
