import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardProgress: ({ colors, sizes, strokeWidth }: any) => ({
    width: sizes.width,
    minWidth: sizes.width,
    height: sizes.height,
    position: 'relative',
    '& .MuiCircularProgress-root': {
      width: '100% !important',
      height: '100% !important',
      color: colors.primary, // Use the primary color
      '& svg': {
        overflow: 'visible',
        '& circle': {
          fill: colors.secondary, // Use the secondary color
          boxShadow: '0px 0px 0px 5px rgba(255,248,220,1)',
          strokeLinecap: 'round',
          strokeWidth: strokeWidth
        }
      }
    }
  }),
  innerContent: ({ colors, strokeWidth }: any) => ({
    width: '82% !important',
    height: '82% !important',
    position: 'absolute',
    top: '9.15%',
    left: '9.15%',
    background: colors.background,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
  }),
}));
