import { PERMISSIONS } from '../utils/enums';

export const isStartUpTeamsSubscription = (subscription) => {
  if (subscription?.subscriptionType) {
    return subscription.subscriptionType === 'team';
  } else {
    return false
  }
}

export const showViewForAdmin = (subscription) => {
  return isStartUpTeamsSubscription(subscription) && subscription.userType.toLowerCase() === PERMISSIONS.ADMIN.toLowerCase();
}

export const showViewForReviewer = (subscription) => {
  return isStartUpTeamsSubscription(subscription) && subscription.userType.toLowerCase() === PERMISSIONS.REVIEWER.toLowerCase();
}

export const showViewForEditor = (subscription) => {
  return isStartUpTeamsSubscription(subscription) && subscription.userType.toLowerCase() === PERMISSIONS.EDITOR.toLowerCase();
}