import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 'calc(100svh - 250px)'
    },
    icon: {
        width: '186px',
        height: '152px',
    },
    title: {
        fontWeight: 700,
        fontSize: '28px',
        lineHeight: '34px',
        color: '#262C37',
        marginTop: '24px'
    },
    text: {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#6E737D',
        marginTop: '16px'
    }
}));
