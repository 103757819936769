import { useState, useEffect, useRef } from 'react';
import { getNotificationsCount } from "../redux/services/notification";
import { StorageService } from '../providers/storage';
import { observableService } from '../services/observable';
import { jwtDecode } from "jwt-decode";

const inactivityDuration = 2 * 60 * 1000;
const pollingInterval = 30 * 1000;

const useGetNotificationsCounter = () => {
  const [isActive, setIsActive] = useState(true);

  const activityTimeout = useRef<number | undefined>(undefined);
  const intervalRef = useRef<number | undefined>(undefined);

  // Function to reset inactivity timer
  const resetActivity = async () => {
    const token = await StorageService.getItem("user-token");

    if (token) {
      const { license_active, license_type }: any = jwtDecode(token);

      if (license_active && license_type === 'team') {
        setIsActive(true);
    
        if (activityTimeout.current !== undefined) {
          clearTimeout(activityTimeout.current);
        }
        
        activityTimeout.current = window.setTimeout(() => {
          setIsActive(false);
        }, inactivityDuration);
      } else {
        setIsActive(false);
      }
    } else {
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (isActive) {
      // API get count request from notifications service below
      handleGetNotificationsCounter();

      intervalRef.current = window.setInterval(handleGetNotificationsCounter, pollingInterval);
    }

    return () => {
      if (intervalRef.current !== undefined) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isActive, pollingInterval]);

  useEffect(() => {
    window.addEventListener('mousemove', resetActivity);
  
    return () => {
      window.removeEventListener('mousemove', resetActivity);
  
      if (activityTimeout.current !== undefined) {
        clearTimeout(activityTimeout.current);
      }
      if (intervalRef.current !== undefined) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleGetNotificationsCounter = async () => {
    const token = await StorageService.getItem("user-token");

    if (token) {
      const { license_active, license_type }: any = jwtDecode(token);

      if (license_active && license_type === 'team') {
        const { data, error } = await getNotificationsCount();

        if (data && !error) {
          setTimeout(() => {
            observableService.sendEvent(`Notification counter: ${data.count}`);
          }, 2000);
        }
      }
    }
  }

  return isActive;
};

export default useGetNotificationsCounter;
