import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    datePickerContainer: {
        position: "relative",
    },
    datePickerInput: {
        height: 44,
        position: "absolute",
        top: 0,
        opacity: 0,
        cursor: "pointer",
        '& input': {
            width: "100%",
            cursor: "pointer",
        }
    }
}));