import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  modalBox: {
    width: '100%',
    maxHeight: '564px !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '24px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  modalHeader: {
    width: '100%',
    padding: '20px 32px',
    borderBottom: '1px solid #E9EAEB',
  },
  closeButton: {
    width: '36px',
    height: '36px',
    position: 'absolute',
    right: '30px',
    top: '14px'
  },
  title: {
    width: '564px',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#262C37',
  },
  description: {
    padding: '0 24px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '#50555E',
    '& span.accent' : {
      color: '#262C37',
      fontWeight: 600,
    },
    '& span.attention' : {
      color: '#942022',
      fontWeight: 600,
    }
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '20px 32px',
    columnGap: '12px',
    borderTop: '1px solid #E9EAEB',
  },
  rejectButton: {
    display: 'inline-flex',
    height: '44px',
    fontSize: '16px',
    fontWeight: 600,
    padding: '8px 24px',
    border: '1px solid #E9EAEB',
    borderRadius: '8px',
    color: '#262C37',
    textTransform: 'none',
  },
  confirmButton: {
    display: 'inline-flex',
    height: '44px',
    fontSize: '16px',
    fontWeight: 600,
    padding: '8px 24px',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#E94A4D',
    color: '#FFF',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#B32225',
    }
  },
}));
