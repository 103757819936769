import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { ReactComponent as PebblesLogo } from '../../assets/icons/pebbles_full_logo_black.svg';
import { ReactComponent as InfoHint } from '../../assets/icons/info-hint-before-start.svg';
import { useHistory } from "react-router-dom";

import { useStyles } from "./styles";

const BeforeWeStart = (props) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <PebblesLogo 
        style={{
          position: 'absolute',
          left: '40px',
          top: '20px'
        }}
      />
      <Box className={classes.content}>
        <Typography className={classes.title}>{"Before we start"}</Typography>
        <Typography className={classes.subTitle}>
          {`Whenever you see the ‘i’ icon, take a moment to click on it for additional context. This will reveal essential context and guidance to enhance your experience with Pebbles Ai.`}
        </Typography>
        <Box className={`${classes.circle4} ${classes.circle1}`}>
          <Box className={`${classes.circle3} ${classes.circle1}`}>
            <Box className={`${classes.circle2} ${classes.circle1}`}>
              <Box className={`${classes.circle1}`}>
                <Box className={`${classes.iconWrapper}`}>
                    <InfoHint
                        width={24}
                        height={24}
                    />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          style={{
            width: "375px",
            marginTop: "50px",
          }}
          onClick={() => history.push('/upload-company-info')}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default BeforeWeStart;
