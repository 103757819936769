import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '700px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '12px',
  },
  header: {
    width: '100%',
    padding: '20px 70px 20px 32px',
    borderBottom: '1px solid #E9EAEB'
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#262C37'
  },
  footer: {
    width: '100%',
    borderTop: '1px solid #E9EAEB',
    padding: '20px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '12px'
  }
}));
