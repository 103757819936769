import React, { useCallback, useEffect, useState } from "react";
import debounce from 'lodash/debounce';
import { Backdrop, Box, Button, CircularProgress, Container, IconButton, Typography } from "@material-ui/core";
import { Edit, InsertDriveFile } from "@material-ui/icons";
import { DateTime } from "luxon";
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_v2.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_black.svg';
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { StorageService } from "../../providers/storage";
import { convertFromBytes } from "../../helpers/file";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { deleteCompanyIntelData, getCompanyIntelAttachments } from "../../redux/services/company-intel";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { useHistory } from "react-router-dom";
import TextField from "../../components/main/CustomFields/TextField";
import DocumentPreviewModal from './components/DocumentPreviewModal';
import UploadDocumentsModal from './components/UploadDocumentsModal';
import { notify } from "../../providers/notification";

import { useStyles } from "./styles";

const CompanyIntel = (props) => {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [isOpenDeletePop, setIsOpenDeletePop] = useState({ active: false, id: null });
  const [companyDoc, setCompanyDoc] = useState({});
  const [searchFile, setSearchFile] = useState('');
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState(false);
  const [preview, setPreview] = useState<{ active: boolean, document: { uri: string, name: string, type: string } | null }>({
    active: false,
    document: null
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      handleIsSubscriptionActive()
    ]).then(() => {
      fetchDocumentList(searchFile);
    })
  }, []);

  const fetchDocumentList = useCallback(async (search = '') => {
    setFetching(true);

    const { data, error } = await getCompanyIntelAttachments({
      fileName: search,
      informationType: 'COMPANY'
    });

    if (!error && data && Array.isArray(data)) {
      const today = DateTime.now().startOf('day');
      const yesterday = today.minus({ days: 1 }).startOf('day');

      const groupedData = Object.fromEntries(
        Object.entries(
          data.reduce((acc, item) => {
            const updatedAt = DateTime.fromISO(item.updatedAt, { zone: 'utc' }).setZone();
            let key;

            if (item.updatedAt) {
              if (updatedAt.hasSame(today, 'day')) {
                key = 'Today';
              } else if (updatedAt.hasSame(yesterday, 'day')) {
                key = 'Yesterday';
              } else {
                key = updatedAt.toFormat('dd MMMM yyyy');
              }
            } else {
              key = 'Unknown';
            }

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);
            return acc;
          }, {})
        ).reverse()
      );

      setCompanyDoc(groupedData);
    }

    setFetching(false);
    setLoading(false);
  }, []);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  };

  const handleOnboarding = async () => {
    if (isSubscriptionActive) {
      await StorageService.setItem("isEdit", 'true');

      history.push('/onboarding');
    } else {
      setShowActionBlockedModal(true);
    }
  };

  const handelIsOpenUpload = async () => {
    if (isSubscriptionActive) {
      await StorageService.setItem("isEdit", 'true');

      setShowUploadDocumentModal(!showUploadDocumentModal);
    } else {
      setShowActionBlockedModal(true);
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      fetchDocumentList(searchText);
    }, 500),
    []
  );

  const handleSearchTextCompanyIntel = (searchText: string) => {
    setSearchFile(searchText);

    debouncedSearch(searchText);
  };

  const handleDeleteCompanyIntel = async (id) => {
    setLoading(true);

    setIsOpenDeletePop({ active: false, id: null });

    const { error } = await deleteCompanyIntelData(id);

    if (!error) {
      notify.success('Document deleted successfully');
    } else {
      notify.error('Document deletion failed');
    }

    await fetchDocumentList(searchFile);

    setLoading(false);
  }

  const uploadMultipleCompanyFile = () => {
    setLoading(true);

    fetchDocumentList(searchFile);
  };

  const getFileType = (name: any) => {
    return [...name.split(".")].pop().toUpperCase();
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container className={classes.mainContainer}>
        <Box className={classes.mainBox}>
          <Box className={classes.uploadContainer}>
            <Typography style={{ fontSize: '48px', fontWeight: '600', lineHeight: '44px' }}>
              Company Intel
            </Typography>
          </Box>
          <Box className={classes.uploadContainer}>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '7px' }}>
              <Button
                className={classes.CompanyIntelButton}
                endIcon={<UploadIcon className={classes.iconBtn} />}
                disabled={Object.values(companyDoc).flat().length >= 3}
                onClick={handelIsOpenUpload}
              >
                Upload new
              </Button>
              <Button
                className={classes.CompanyIntelButton}
                endIcon={<Edit className={classes.iconBtn} />}
                onClick={handleOnboarding}
              >
                Edit onboarding
              </Button>
            </Box>
            <TextField
              value={searchFile}
              type={'search'}
              placeholder={'Search documents'}
              showClear
              onChange={(event: any, value: any) => {
                if (isSubscriptionActive) {
                  handleSearchTextCompanyIntel(event.target.value)
                } else {
                  setShowActionBlockedModal(true)
                }
              }}
            />
          </Box>
        </Box>
        <Box className={classes.listMainContainer}>
          {!loading && fetching && (
            <Box
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {!loading && !fetching && !Object.entries(companyDoc).length && (
            <Box
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '19px'
                }}
              >
                No data
              </Typography>
            </Box>
          )}
          {Object.entries(companyDoc).map(([key, items]: any, index: number) => (
            <Box key={index} style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
              <Typography style={{ userSelect: 'none', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                {key}
              </Typography>
              {items.map((item: any, index: any) => (
                <Box
                  key={index + item.filename}
                  className={classes.listContainer}
                >
                  <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <Box className={classes.fileType}>
                      {getFileType(item.filename)}
                    </Box>
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Typography noWrap className={classes.fileName}>
                        {item.filename}
                      </Typography>
                      <Box style={{ display: 'flex', alignItems: 'center', columnGap: '8px', marginTop: '4px' }}>
                        <Box className={classes.spanText}>
                          {convertFromBytes(item.attachmentSize)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.fileTagsWrapper}>
                    <Typography className={classes.fileTags}>{item.informationType.description}</Typography>
                  </Box>
                  <IconButton
                    onClick={() => setIsOpenDeletePop({ active: true, id: item.id })}
                  >
                    <DeleteIcon width={24} height={24} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Container>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={'To proceed, you have to be subscribed'}
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={'Subscribe later'}
        rejectBtnText={'Go to subscriptions'}
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() => history.push('/subscriptions')}
      />

      <UploadDocumentsModal
        open={showUploadDocumentModal}
        onlyFiles={true}
        onClose={() => {
          setShowUploadDocumentModal(false);
        }}
        onAfterClose={uploadMultipleCompanyFile}
      />

      <ConfirmationModal
        open={isOpenDeletePop.active}
        title={<>Are you sure you want to <br /> delete this company intel document?</>}
        onClose={() => setIsOpenDeletePop({ active: false, id: null })}
        confirmBtnText="Delete"
        rejectBtnText="Cancel"
        onConfirm={() => handleDeleteCompanyIntel(isOpenDeletePop.id)}
        onReject={() => setIsOpenDeletePop({ active: false, id: null })}
      />

      <DocumentPreviewModal
        open={preview.active}
        document={preview.document}
        onClose={() => {
          setPreview({
            active: false,
            document: null
          })
        }}
      />
    </>
  );
};

export default CompanyIntel;
