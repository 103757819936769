import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  statusContainer: {
    height: '28px',
    padding: '8px 12px',
    borderRadius: 8,
    fontSize: '16px',
    lineHeight: '15px',
    display: 'inline-flex',
    marginLeft: 8,
    position: 'relative',
  },
  icon: {
    margin: '-2px 0 0 4px',
  }
}));