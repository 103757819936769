import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: "0px",
    minWidth: "0px",
    maxWidth: "0px",
    height: "100vh",
    background: "#F5F5F5",
    borderRight: "1px solid #E9EAEB",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "20px",
    zIndex: 10,
    padding: "17px 0px",
    overflow: "visible",
    transition: "all .3s",
    position: 'relative'
  },
  sideBarOpen: {
    width: "270px",
    minWidth: "270px",
    maxWidth: "270px",
    padding: "22px 12px"
  },
  sideBarOpenCloseButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '44px',
    height: '44px',
    position: 'absolute',
    background: '#FFFFFF',
    border: '1px solid #E9EAEB',
    borderRadius: '12px',
    cursor: 'pointer',
    top: '14px',
    right: '-60px',
  },
  sideBarHeader: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "space-between",
    padding: '4px 0px 4px 0px',
    overflow: "hidden",
  },
  sideBarContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "7px",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "1px 3px 5px 2px"
  },
  sideBarItem: {
    width: "100%",
    minHeight: "44px",
    maxHeight: "44px",
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "7px",
    padding: '12px',
    borderRadius: "8px",
    cursor: "pointer",
    userSelect: 'none',
    // boxShadow: "-2px 0 0px #0F7F75",
    "&:hover": {
      background: "#FAFAFA"
    }
  },
  sideBarItemActive: {
    background: "#FFFFFF !important",
  },
  renameInput: {
    '$ .MuiInputBase-root': {
      border: 'none !important'
    }
  },
  newChatButton: {
    background: "#10BE98",
    color: "white",
    "&:hover": {
      background: "#10BE98",
      color: "white"
    }
  },
  itemContextMenu: {
    width: '200px',
    borderRadius: '8px',
    border: '1px solid #E9EAEB',
    padding: '8px 0px',
    background: '#FFFFFF',
    boxShadow: '0px -5px 6px -2px #90959F0D',
    '& .MuiListItem-root': {
      height: '40px',
      padding: '0px 20px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '16px',
      color: '#262C37'
    }
  }
}));
