import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Backdrop, Box, Button, CircularProgress, FormHelperText, IconButton, Modal, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { Editor } from 'react-draft-wysiwyg';
import TextField from '../../../../components/main/CustomFields/TextField';
import Bold from '../../../../assets/editor/bold.svg';
import Italic from '../../../../assets/editor/italic.svg';
import Underline from '../../../../assets/editor/underline.svg';
import Strikethrough from '../../../../assets/editor/strikethrough.svg';
import ListBulleted from '../../../../assets/editor/list_bulleted.svg';
import ListNumbered from '../../../../assets/editor/list_numbered.svg';
import AlignLeft from '../../../../assets/editor/align_left.svg';
import AlignCenter from '../../../../assets/editor/align_center.svg';
import AlignRight from '../../../../assets/editor/align_right.svg';
import InsertLink from '../../../../assets/editor/insert_link.svg';
import RemoveLink from '../../../../assets/editor/remove_link.svg';
import FormatClear from '../../../../assets/editor/format_clear.svg';
import { convertHTMLToState, convertHTMLToText, convertMarkdownToHtml, convertStateToHTML } from '../../../EmailEditor/helpers';
import { notify } from '../../../../providers/notification';
import { Form, Formik, FormikHelpers } from 'formik';
import { isStartUpTeamsSubscription, showViewForAdmin, showViewForEditor, showViewForReviewer } from '../../../../helpers/roles';
import { AccountSubscriptionResponse } from '../../../../interfaces/subscriptions';
import { saveMessageToLibrary } from '../../../../redux/services/copilot';
import { useParams } from 'react-router-dom';
import { CopilotTypes } from '../../../../interfaces/copilot';

import { useStyles } from './styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';

const customStyleMap = {
  'FONT_SIZE_8': { fontSize: '8px' },
  'FONT_SIZE_9': { fontSize: '9px' },
  'FONT_SIZE_10': { fontSize: '10px' },
  'FONT_SIZE_11': { fontSize: '11px' },
  'FONT_SIZE_12': { fontSize: '12px' },
  'FONT_SIZE_14': { fontSize: '14px' },
  'FONT_SIZE_16': { fontSize: '16px' },
  'FONT_SIZE_18': { fontSize: '18px' },
  'FONT_SIZE_24': { fontSize: '24px' },
  'FONT_SIZE_30': { fontSize: '30px' },
  'FONT_SIZE_36': { fontSize: '36px' },
  'FONT_SIZE_48': { fontSize: '48px' },
  'FONT_SIZE_60': { fontSize: '60px' },
  'FONT_SIZE_72': { fontSize: '72px' },
  'FONT_SIZE_96': { fontSize: '96px' },
};

interface Props {
  open: boolean;
  title: string;
  message: any;
  subscription: AccountSubscriptionResponse;
  onClose: (refetchChat?: boolean) => void;
}

const SaveToLibraryModal: React.FC<Props> = (props) => {
  const {
    open,
    title,
    message,
    subscription,
    onClose,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState<'description' | 'preview'>('description');
  const [editorState, setEditorState] = useState<any>(null);

  const params = useParams<{ id: string, tab: CopilotTypes }>();
  const classes = useStyles();
  const formRef = useRef<any>(null);

  useEffect(() => {
    if (open) {
      if (message) {
        setEditorState(
          convertHTMLToState(
            convertMarkdownToHtml(message.message)
          )
        );
      } else {
        notify.info(`Content can't be empty`);

        onClose();
      }
    } else {
      setTab('description');
      setEditorState(null);
      formRef.current = null;
    }
  }, [open, message]);

  useEffect(() => {
    if (open && message) {
      const intervalId = setInterval(() => {
        if (formRef?.current) {
          clearInterval(intervalId);

          formRef.current.setFieldValue('content', message.message);
          formRef.current.setFieldValue('name', title);
        }
      }, 1);
    }
  }, [open, message]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const onSaveContent = async (values) => {
    setLoading(true);

    const saveAsModificator = isStartUpTeamsSubscription(subscription)
      ? 'draft'
      : 'single-user'

    const { error } = await saveMessageToLibrary({
      body: {
        name: values.name,
        description: values.description,
        text: convertStateToHTML(editorState),
        type: params.tab.toUpperCase(),
      },
      saveAsModificator: saveAsModificator
    });

    if (!error) {
      notify.success('Snippet saved');

      onClose(true);
    } else {
      notify.error('Failed to save snippet');
    }

    setLoading(false);
  }

  const onSendForReview = async (values) => {
    setLoading(true);

    const { error } = await saveMessageToLibrary({
      body: {
        name: values.name,
        description: values.description,
        text: convertStateToHTML(editorState),
        type: params.tab.toUpperCase(),
      },
      saveAsModificator: 'pending-review'
    })

    if (!error) {
      notify.success('Content sent to review');

      onClose(true);
    } else {
      notify.error('Failed to send to review');
    }

    setLoading(false);
  }

  const onPublishToLibrary = async (values) => {
    setLoading(true);

    const { error } = await saveMessageToLibrary({
      body: {
        name: values.name,
        description: values.description,
        text: convertStateToHTML(editorState),
        type: params.tab.toUpperCase(),
      },
      saveAsModificator: 'approved'
    })

    if (!error) {
      notify.success('Snippet published');

      onClose(true);
    } else {
      notify.error('Failed to publish snippet');
    }

    setLoading(false);
  }

  const handleBeforeSubmitCheck = (errors) => {
    if ('name' in errors || 'description' in errors) {
      setTab('description');
    
      return;
    } else if ('content' in errors) {
      setTab('preview');
    
      return;
    }
  }

  return (
    <Modal open={open} onClose={loading ? () => { } : () => onClose()} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton
          className={classes.closeButton}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>

        <Formik
          innerRef={formRef}
          initialValues={{
            name: '',
            description: '',
            content: ''
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .required('This field is required')
              .max(60, 'Max 60 characters'),
            description: Yup.string()
              .max(1000, 'Max 1000 characters'),
            content: Yup.string()
              .required('This field is required'),
          })}
          validateOnChange={true}
          validateOnBlur={false}
          isInitialValid={true}
          onSubmit={(values: any) => {}}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setErrors,
            submitForm,
            validateForm
          }) => (
            <Form
              style={{
                width: '100%'
              }}
              translate={undefined}
              onKeyDown={handleKeyDown}
            >
              <Box className={classes.header}>
                <Typography style={{ fontSize: '20px', fontWeight: '500', lineHeight: '20px', color: '#262C37' }}>
                  Save Content
                </Typography>
                <Typography style={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px', color: '#50555E' }}>
                  Please review content before saving.
                </Typography>
              </Box>
              <Box className={classes.content}>
                <Box className={classes.tabs}>
                  <Box
                    className={`${classes.tabItem} ${tab === 'description' ? classes.tabItemActive : ''}`}
                    onClick={() => setTab('description')}
                  >
                    Description
                  </Box>
                  <Box
                    className={`${classes.tabItem} ${tab === 'preview' ? classes.tabItemActive : ''}`}
                    onClick={() => setTab('preview')}
                  >
                    Preview
                  </Box>
                </Box>
                {tab === 'description'
                  ? <>
                    <TextField
                      value={values['name']}
                      label={'Intelligence Name'}
                      placeholder={'e.g. Follow up'}
                      maxLength={60}
                      required
                      error={(touched['name'] && errors['name']) as string}
                      onChange={(event) => setFieldValue('name', event.target.value)}
                    />
                    <TextField
                      value={values['description']}
                      label={'Description'}
                      placeholder={'Enter description'}
                      type={'textarea'}
                      maxLength={1000}
                      error={(touched['description'] && errors['description']) as string}
                      minRows={2}
                      maxRows={4}
                      onChange={(event) => setFieldValue('description', event.target.value)}
                    />
                  </>
                  : <>
                    <Box className={classes.editor}>
                      <Editor
                        editorState={editorState}
                        customStyleMap={customStyleMap}
                        // contentState={{}}
                        toolbar={{
                          options: [
                            'blockType',
                            'inline',
                            'list',
                            'textAlign',
                            'link',
                            'remove',
                          ],
                          blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                            className: classes.toolbarButton,
                            component: undefined,
                            // dropdownClassName: classes.toolbarButton,
                          },
                          inline: {
                            inDropdown: false,
                            className: classes.toolbarGroup,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                            bold: { icon: Bold, className: classes.toolbarButton },
                            italic: { icon: Italic, className: classes.toolbarButton },
                            underline: { icon: Underline, className: classes.toolbarButton },
                            strikethrough: { icon: Strikethrough, className: classes.toolbarButton },
                          },
                          textAlign: {
                            inDropdown: false,
                            className: classes.toolbarGroup,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['left', 'center', 'right'],
                            left: { icon: AlignLeft, className: classes.toolbarButton },
                            center: { icon: AlignCenter, className: classes.toolbarButton },
                            right: { icon: AlignRight, className: classes.toolbarButton },
                          },
                          list: {
                            inDropdown: false,
                            className: classes.toolbarGroup,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: ['ordered', 'unordered'],
                            ordered: { icon: ListNumbered, className: classes.toolbarButton },
                            unordered: { icon: ListBulleted, className: classes.toolbarButton }
                          },
                          link: {
                            inDropdown: false,
                            className: classes.toolbarGroup,
                            component: undefined,
                            popupClassName: undefined,
                            dropdownClassName: undefined,
                            showOpenOptionOnHover: true,
                            defaultTargetOption: '_self',
                            options: ['link', 'unlink'],
                            link: { icon: InsertLink, className: classes.toolbarButton },
                            unlink: { icon: RemoveLink, className: classes.toolbarButton },
                            linkCallback: undefined
                          },
                          remove: {
                            icon: FormatClear,
                            className: classes.toolbarButton,
                            component: undefined
                          }
                        }}
                        // toolbarCustomButtons={[<CustomOption />]}
                        handleReturn={(event) => {
                          // Check if Shift key is pressed along with Enter
                          if (event.shiftKey) {
                            // Prevent the default action (inserting a line break)
                            return 'handled';
                          }
                          return 'not-handled';
                        }}
                        handlePastedText={(text, html, editorState) => { }}
                        onEditorStateChange={(event) => {
                          setEditorState(event);

                          setFieldValue(
                            'content',
                            convertHTMLToText(
                              convertStateToHTML(event)
                            )
                          );
                        }}
                        wrapperClassName={`wrapper-class ${classes.editorWrapper}`}
                        toolbarClassName={`toolbar-class ${classes.editorToolbar}`}
                        editorClassName={`editor-class ${classes.editorTextarea}`}
                      />
                    </Box>
                    <FormHelperText
                      error
                      style={{
                        marginTop: '-10px'
                      }}
                    >
                      {touched['content'] && errors['content']}
                    </FormHelperText>
                  </>
                }
              </Box>
              <Box className={classes.footer}>
                {isStartUpTeamsSubscription(subscription)
                  ? <Button
                    // type="submit"
                    color="secondary"
                    variant="outlined"
                    disabled={loading || !values['name'].trim().length || !convertHTMLToText(convertStateToHTML(editorState))?.trim()?.length}
                    onClick={() => {
                      setErrors({});

                      submitForm().then(() => {
                        validateForm().then((errors) => {
                          if (Object.values(errors).length) {
                            handleBeforeSubmitCheck(errors);
                          } else {
                            onSendForReview(values);
                          }
                        })
                      })
                    }}
                  >
                    Send for review
                  </Button>
                  : <Box />
                }
                <Box>
                  {(isStartUpTeamsSubscription(subscription) && (showViewForAdmin(subscription) || showViewForReviewer(subscription)))
                    ? <Button
                      // type="submit"
                      color="secondary"
                      variant="outlined"
                      disabled={loading || !values['name'].trim().length || !convertHTMLToText(convertStateToHTML(editorState))?.trim()?.length}
                      onClick={() => {
                        setErrors({});

                        submitForm().then(() => {
                          validateForm().then((errors) => {
                            if (Object.values(errors).length) {
                              handleBeforeSubmitCheck(errors);
                            } else {
                              onPublishToLibrary(values);
                            }
                          })
                        })
                      }}
                    >
                      Publish to Library
                    </Button>
                    : <Box />
                  }
                  &nbsp;
                  &nbsp;
                  <Button
                    // type="submit"
                    color="primary"
                    variant="outlined"
                    disabled={loading || !values['name'].trim().length || !convertHTMLToText(convertStateToHTML(editorState))?.trim()?.length}
                    style={{
                      background: '#0F7F75'
                    }}
                    onClick={() => {
                      setErrors({});

                      submitForm().then(() => {
                        validateForm().then((errors) => {
                          if (Object.values(errors).length) {
                            handleBeforeSubmitCheck(errors);
                          } else {
                            onSaveContent(values);
                          }
                        })
                      })
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default SaveToLibraryModal;