import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    '&.MuiFormControl-root': {
      width: '100%',
      margin: '4px 0px',
      background: 'transparent'
    }
  },
  controll: {
    '&.MuiFormControlLabel-root': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      margin: '0px',
      padding: '0px',
      background: 'white',
      borderRadius: '8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#0F172A'
    },
    '& .MuiInput-formControl': {
      width: '100%',
      minWidth: '100%',
      height: '44px',
      border: '1px solid #E9EAEB',
      borderRadius: '8px',
      padding: '0px !important',
      '& svg': {
        position: 'absolute',
        right: '19px',
        top: '50%',
        transform: 'translate(0, -65%)',
      },
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      },
    },
    '& .MuiInputBase-input': {
      height: '99% !important',
      minHeight: '99% !important',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      color: '#000000',
      boxSizing: 'border-box',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '18.5px 14px !important',
      '&::placeholder': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '21px',
        color: '#000000 !important',
      },
      '&.Mui-disabled': {
        opacity: 0.5,
        pointerEvents: 'none'
      }
    }
  },
  select: {
    '&.MuiAutocomplete-root': {
      width: '100%',
      minWidth: '100%',
      maxWidth: '100%',
      height: '100%',
      padding: '0px !important',
      boxSizing: 'border-box',
      borderRadius: '8px',
    },
    '&.MuiAutocomplete-hasPopupIcon': {
      '& .MuiAutocomplete-endAdornment': {
        display: 'none'
      }
    }
  },
  menu: {
    '& .MuiMenu-paper': {
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: 'none',
      marginTop: '10px',
    },
    '& .MuiMenu-list': {
      padding: '0'
    }
  },
  item: {
    minHeight: '40px',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    borderBottom: '1px solid #E2E8F0',
  },
  inputAdornment: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: (props: any) => `translate(0px, ${props.label && !props.error ? '10%' : '-35%'})`,
    '& svg': {
      width: '20px',
      height: '20px',
      color: '#94A3B8',
    }
  },
}));