import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    // maxWidth: "1920px",
    height: "fit-content",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    padding: "90px 40px 40px",
    margin: "0 auto",
    background: "#F5F5F5",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
  plans: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "20px",
    marginTop: "32px",
    overflowX: 'auto',
    overflowY: 'hidden',
    padding: '0',
    zoom: 0.8,
    '@media (max-width: 1216px)': {
      justifyContent: "flex-start",
      paddingBottom: '40px'
    },
  },
  planCard: {
    width: "460px",
    minWidth: "460px",
    height: "700px",
    minHeight: "700px",
    maxHeight: "100%",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
    borderRadius: "20px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    border: "1px solid black",
    overflow: "hidden",
    position: "relative"
  },
  benefitTag: {
    padding: "2px 16px 2px 2px",
    background: "#DAF8E6",
    borderRadius: "80px",
    display: "flex",
    alignItems: "center",
    gap: "7px"
  },
  benefitsList: {
    width: "100%",
    height: "100%",
    minHeight: '160px',
    maxHeight: "200px",
    display: "flex",
    flexWrap: "wrap",
    // flexDirection: 'column',
    columnGap: "20px",
    rowGap: "8px",
    overflow: "hidden"
  }
}));
