import { api, createApiCall } from "../api";
import { NotificationParams, PageUserNotification } from "../../interfaces/notification";

const notificationAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<PageUserNotification, NotificationParams>({
      query: (params) => ({
        url: `/v1/notification/user-notifications`,
        method: "GET",
        params
      })
    }),
    getNotificationsCount: builder.query<{ count: number }, void>({
      query: (params) => ({
        url: `/v1/notification/user-notifications/unread-count`,
        method: "GET",
      })
    }),
    updateNotificationStatus: builder.mutation<void, { body: { status: string }, id: string }>({
      query: ({ body, id }) => ({
        url: `/v1/notification/user-notifications/status/${id}`,
        method: "PUT",
        body
      })
    }),
    readAllNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/notification/user-notifications/read-all`,
        method: "PUT",
      })
    }),
    deleteNotification: builder.mutation<void, string>({
      query: (id) => ({
        url: `/v1/notification/user-notifications/delete/${id}`,
        method: "DELETE"
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getNotifications = createApiCall<PageUserNotification, NotificationParams>(notificationAPI.endpoints.getNotifications);
export const getNotificationsCount = createApiCall<{ count: number }, void>(notificationAPI.endpoints.getNotificationsCount);
export const updateNotificationStatus = createApiCall<void, { body: { status: string }, id: string }>(notificationAPI.endpoints.updateNotificationStatus);
export const readAllNotifications = createApiCall<void, void>(notificationAPI.endpoints.readAllNotifications);
export const deleteNotification = createApiCall<void, string>(notificationAPI.endpoints.deleteNotification);
