export interface Campaign {
  id: string | number;
  name: string;
  description: string;
  typeId: number;
  createdAt: Date;
  updatedAt: Date;
  emails?: CampaignEmailResponse[];
}

export interface CampaignResponse extends Campaign {
  id: string | number;
  name: string;
  description: string;
  accountId: string;
  type: string;
  typeId: number;
  category: string;
  subCategory: CampaignSubCategory;
  // totalEmails: number;
  createdBy: string;
  reviewStatus: string;
  status: CampaignStatuses;
  metadata?: {
    userImageUrl: string;
    userName: string;
  };
  metaData?: {
    totalEmails: number;
  };
  sections?: TemplateSection[];
  owner?: {
    id: string,
    name: string,
    profileImageUrl: string
  };
}

export interface CampaignTypeResponse {
  id: number;
  title: string;
  subTitle: string;
  description: string;
  setup: string;
  parameters: string;
  subCategory: CampaignSubCategoryResponse;
}

export interface CampaignSubCategoryResponse {
  id: number;
  name: string;
}

export interface CampaignModelResponse {
  id?: string | number;
  sections?: TemplateSection[];
  numberOfSection: number;
  totalNumberOfQuestions: number;
  name: string;
  category: string;
  subCategory: CampaignSubCategory;
  createdBy?: string;
  reviewStatus?: string;
  status?: CampaignStatuses;
}

export interface CampaignParameters {
  campaignId?: string;
  emailId?: string;
  campaignTypeId: number;
  parameters: ModelAnswer;
  uiParameters: any;
}

export interface ModelAnswer {
  campaignTypeId?: number;
  separatedSectionAnswers?: any;
  sectionAnswerList: any;
}

export interface ReplyTemplateModel {
  sections?: TemplateSection[];
  numberOfSection: number;
  totalNumberOfQuestions: number;
}

export interface ReplyRequest {
  reply: string;
  channel: string;
  // emailId: number;
  // campaignTypeId: number;
  arguments: ModelAnswer;
}

export interface GetReplyTemplateRequest {
  body: {
    reply: string;
  };
  emailId: string;
}

export interface ReplyResponse {
  subject: string;
  content: string;
}

export interface CampaignEmailResponse {
  id: number | string;
  campaignId: string;
  subject: string;
  content: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
  status: "NEW" | "PENDING" | "COMPLETED" | "FAILED" | "FAKE";
  metaData: {
    index: number;
    total: number;
  };
}

export interface CampaignEmailRequest {
  id: number | string;
  campaignId: string;
  subject: string;
  content: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface EmailParameters {
  campaignId?: string;
  emailId?: string;
  campaignTypeId: number;
  parameters: ModelAnswer;
  uiParameters: any;
}

export interface SimplifiedCampaignResponse {
  id: string;
  name: string;
  createdBy: string;
  reviewStatus: string;
  updatedAt: Date;
  createdAt: Date;
  userName: string;
  userImageUrl: string;
  emails?: CampaignEmailResponse[];
}

export interface CampaignCommentRequest {
  campaignId: string;
  text: string;
}

export interface CampaignCommentResponse {
  id: string;
  campaignId: string;
  ownerId: string;
  comment: UserComment;
  status: string;
  createdAt: Date;
  metadata: CommentMetadata;
}

export interface UserComment {
  userId: string;
  text: string;
  replies: CommentReply;
}

export interface CommentReply {
  ownerId: string;
  text: string;
  createdAt: Date;
  metadata: CommentMetadata;
}

export interface CommentMetadata {
  userId: string;
  userName: string;
  userImageUrl: string;
}

export interface CreateCampaignRequest {
  body: {
    name: string;
    description: string;
  };
  campaignId: string;
}

export interface CommentActionRequest {
  body: {
    text?: string;
    status?: string;
  };
  commentId?: string;
  campaignId?: string;
}

export interface TemplateSection {
  key?: string;
  id: number;
  name: string;
  numberOfSubsections: number;
  totalNumberOfQuestions: number;
  attributes: TemplateAttribute[];
  subSections: TemplateSubSection[];
}

export interface TemplateSubSection {
  id: number;
  title: string;
  numberOfElement: number;
  attributes: TemplateAttribute[];
  elements: TemplateElement[];
}

export interface TemplateElement {
  id: number;
  attributes: TemplateAttribute[];
  hidden: boolean;
  type: TemplateElementTypes;
  options?: string[];
  sliders?: {
    defaultValue: string;
    options: Record<number, string>;
  };
  provideOnSelected?: Record<string, string>[];
  groups?: {
    label: string;
    options: string[];
  }[];
}

export interface TemplateAttribute {
  key?: string;
  necessaryOptionsNumber?: number;
  requiredOptionsNumber?: number;
  value?: string | number;
  option?: string;
  optionTooltipMap?: Record<string, string>;
  optionInfoMap?: Record<string, string>;
  elementReference?: {
    elementId: number;
    sectionId: number;
    subsectionId: number;
    subElementId?: number;
  };
  newOptions?: string[];
  label?: string;
  text?: string;
  question?: {
    id: number;
    elements: TemplateElement[];
  };
  answer?: string;
  element?: TemplateElement;
  type: TemplateAttributeTypes;
}

export enum LibraryTabs {
  ALL = "all",
  APPROVED = "approved",
  IN_PROGRESS = "in_progress"
}

export enum CampaignStatuses {
  INCOMPLETE = "INCOMPLETE",
  COMPLETED = "COMPLETED"
}

export enum CampaignCategories {
  MARKETING = "MARKETING",
  SALES = "SALES",
  PRODUCT = "PRODUCT"
}

export enum CampaignTypes {
  CUSTOM_MARKETING_EMAIL = "CUSTOM_MARKETING_EMAIL",
  MASS_MARKETING_EMAIL = "MASS_MARKETING_EMAIL",
  RETARGETING_EMAIL = "RETARGETING_EMAIL",
  ABANDONED_CART_EMAIL = "ABANDONED_CART_EMAIL",
  NEWSLETTER_EMAIL = "NEWSLETTER_EMAIL",
  CUSTOM_MARKETING_CAMPAIGN = "CUSTOM_MARKETING_CAMPAIGN",
  EMOTIONAL_TRIGGERS_CAMPAIGN = "EMOTIONAL_TRIGGERS_CAMPAIGN",
  QUAD_LINK_CAMPAIGN = "QUAD_LINK_CAMPAIGN",
  POSITIONING_CAMPAIGN = "POSITIONING_CAMPAIGN",
  MARKETING_INFLUENCE_CAMPAIGN = "MARKETING_INFLUENCE_CAMPAIGN",
  CUSTOM_SALES_EMAIL_NON_PAYING = "CUSTOM_SALES_EMAIL_NON_PAYING",
  DEMO_EMAIL = "DEMO_EMAIL",
  RE_ENGAGEMENT_EMAIL = "RE_ENGAGEMENT_EMAIL",
  POST_EVENT_EMAIL_NON_PAYING = "POST_EVENT_EMAIL_NON_PAYING",
  CUSTOM_SALES_EMAIL_PAYING = "CUSTOM_SALES_EMAIL_PAYING",
  UPSELL_EMAIL = "UPSELL_EMAIL",
  CROSS_SELL_EMAIL = "CROSS_SELL_EMAIL",
  RENEWAL_DEAL_EMAIL = "RENEWAL_DEAL_EMAIL",
  POST_EVENT_EMAIL_PAYING = "POST_EVENT_EMAIL_PAYING",
  CUSTOM_PRODUCT_EMAIL = "CUSTOM_PRODUCT_EMAIL",
  WELCOME_EMAIL = "WELCOME_EMAIL",
  GETTING_STARTED_EMAIL = "GETTING_STARTED_EMAIL",
  ONBOARDING_STEPS_EMAIL = "ONBOARDING_STEPS_EMAIL",
  TIPS_AND_TRICKS_EMAIL = "TIPS_AND_TRICKS_EMAIL",
  GATHER_FEEDBACK_EMAIL = "GATHER_FEEDBACK_EMAIL"
}

export enum CampaignSubCategory {
  MULTI_TOUCH_CAMPAIGNS = "MULTI_TOUCH_CAMPAIGNS",
  ONE_TOUCH_OUTREACH = "ONE_TOUCH_OUTREACH",
  PAYING_CUSTOMERS = "PAYING_CUSTOMERS",
  NON_PAYING_CUSTOMERS = "NON_PAYING_CUSTOMERS",
  USERS_AND_CUSTOMERS = "USERS_AND_CUSTOMERS"
}

export enum TemplateElementTypes {
  MULTI_SELECTOR = "MULTI_SELECTOR",
  OPT_SLIDER_SELECTOR = "OPT_SLIDER_SELECTOR",
  SWITCH_SELECTOR = "SWITCH_SELECTOR",
  BOX_SWITCH_SELECTOR = "BOX_SWITCH_SELECTOR",
  GROUPED_SWITCH_SELECTOR = "GROUPED_SWITCH_SELECTOR",
  INT_NUMBER_INPUT = "INT_NUMBER_INPUT",
  TEXT_INPUT = "TEXT_INPUT",
  URL_INPUT = "URL_INPUT",
  TEXT_AREA = "TEXT_AREA",
  FILE_UPLOAD = "FILE_UPLOAD",
  DROP_DOWN = "DROP_DOWN"
}

export enum TemplateAttributeTypes {
  OPTIONAL_ATTRIBUTE = "OPTIONAL_ATTRIBUTE",
  LABEL_UNDER_CONDITION_ATTRIBUTE = "LABEL_UNDER_CONDITION_ATTRIBUTE",
  PLACEHOLDER_ATTRIBUTE = "PLACEHOLDER_ATTRIBUTE",
  ON_OPTION_CHILD_ATTRIBUTE = "ON_OPTION_CHILD_ATTRIBUTE",
  HIDE_UNDER_CONDITION_ATTRIBUTE = "HIDE_UNDER_CONDITION_ATTRIBUTE",
  RESET_OPTIONS_UNDER_CONDITION_ATTRIBUTE = "RESET_OPTIONS_UNDER_CONDITION_ATTRIBUTE",
  RESET_PLACEHOLDER_UNDER_CONDITION_ATTRIBUTE = "RESET_PLACEHOLDER_UNDER_CONDITION_ATTRIBUTE",
  TITLE_ATTRIBUTE = "TITLE_ATTRIBUTE",
  SECTION_CONDITION_ATTRIBUTE = "SECTION_CONDITION_ATTRIBUTE",
  MAX_LENGTH_ATTRIBUTE = "MAX_LENGTH_ATTRIBUTE",
  MIN_LENGTH_ATTRIBUTE = "MIN_LENGTH_ATTRIBUTE",
  MAX_OPTIONS_ATTRIBUTE = "MAX_OPTIONS_ATTRIBUTE",
  ON_OPTION_TOOLTIP_ATTRIBUTE = "ON_OPTION_TOOLTIP_ATTRIBUTE",
  ON_OPTION_INFO_ATTRIBUTE = "ON_OPTION_INFO_ATTRIBUTE",
  TOOLTIP_ATTRIBUTE = "TOOLTIP_ATTRIBUTE",
  MIN_VALUE_ATTRIBUTE = "MIN_VALUE_ATTRIBUTE",
  MAX_VALUE_ATTRIBUTE = "MAX_VALUE_ATTRIBUTE",
  DEFINED_OPTION_ATTRIBUTE = "DEFINED_OPTION_ATTRIBUTE",
  DEFINED_ANSWER_ATTRIBUTE = "DEFINED_ANSWER_ATTRIBUTE",
  DEFAULT_VALUE_ATTRIBUTE = "DEFAULT_VALUE_ATTRIBUTE",
  SEPARATED_QUESTION_ATTRIBUTE = "SEPARATED_QUESTION_ATTRIBUTE"
}

export interface RecentFiles {
  id: string;
  name: string;
  description: string;
  createdBy: string;
  updatedAt: string;
  owner: UserWithDetail;
  type: string;
}

export interface UserWithDetail {
  id: string;
  name: string;
  profileImageUrl: string;
}

export interface LibraryParams {
  pageNumber?: number;
  pageSize?: number;
  searchQuery?: string;
  types?: string[];
  updatedAtStart?: string;
  updatedAtEnd?: string;
  createdBy?: string[];
}

export interface CampaignMetadata {
  userId: string;
  userName: string;
  userImageUrl: string;
}

export interface FolderMetadata {
  count: number;
  updatedAt: Date;
  type: string;
}

export interface CampaignWithSingleUser {
  id: string;
  name: string;
  description: string;
  accountId: string;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  category: string;
  status: string;
  createdBy: string;
  reviewStatus: string;
  metadata: CampaignMetadata;
  emails: CampaignEmailResponse;
}

export interface CampaignWithOwners extends CampaignWithSingleUser {
  owner: UserWithDetail;
}

export interface LibraryCampaignResponse {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: CampaignWithSingleUser[] | CampaignWithOwners[];
}

export interface LibraryCopilotResponse {
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  content: any[];
}
